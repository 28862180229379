import { OrganizationTypes } from '@swiftctrl/api-client'

const LOCAL_STORAGE_KEYS = {
  FAVORITE_ORGANIZATIONS: 'favoriteOrganizations',
  EXPANDED_STATE: 'favoriteOrganizationsExpanded',
}

export type FavoriteOrganization = Pick<
  OrganizationTypes,
  'organization_id' | 'entity_name'
>

export const getFavoriteOrganizations = () => {
  const value = localStorage.getItem(LOCAL_STORAGE_KEYS.FAVORITE_ORGANIZATIONS)

  if (!value) {
    return []
  }

  const json = JSON.parse(value)

  if (!Array.isArray(json)) {
    return []
  }

  const favorites = new Array<FavoriteOrganization>()

  for (let i = 0; i < json.length; i++) {
    const entry = json[i]

    if (
      'organization_id' in entry === false ||
      'entity_name' in entry === false
    ) {
      continue
    }

    const { organization_id, entity_name } = entry

    if (
      typeof organization_id !== 'string' ||
      typeof entity_name !== 'string'
    ) {
      continue
    }

    favorites.push({ organization_id, entity_name })
  }

  return favorites
}

export const isFavoriteOrganization = (organizationId: string) => {
  const favorites = getFavoriteOrganizations()

  for (let i = 0; i < favorites.length; i++) {
    if (favorites[i].organization_id === organizationId) {
      return true
    }
  }

  return false
}

export const addFavoriteOrganization = (organization: FavoriteOrganization) => {
  const favorites = getFavoriteOrganizations()

  const existingEntry = favorites.find(
    (entry) => entry.organization_id === organization.organization_id,
  )

  if (existingEntry) {
    return
  }

  favorites.push(organization)

  localStorage.setItem(
    LOCAL_STORAGE_KEYS.FAVORITE_ORGANIZATIONS,
    JSON.stringify(favorites),
  )
}

export const removeFavoriteOrganization = (organizationId: string) => {
  const favorites = getFavoriteOrganizations()

  const update = favorites.filter(
    (entry) => entry.organization_id !== organizationId,
  )

  if (update.length === favorites.length) {
    return
  }

  localStorage.setItem(
    LOCAL_STORAGE_KEYS.FAVORITE_ORGANIZATIONS,
    JSON.stringify(update),
  )
}

export const getFavoriteOrganizationsExpandedState = () => {
  const value = localStorage.getItem(LOCAL_STORAGE_KEYS.EXPANDED_STATE)

  if (!value) {
    return { favoriteOrganizationsExpanded: true }
  }

  if (!['0', '1'].includes(value)) {
    return { favoriteOrganizationsExpanded: false }
  }

  return { favoriteOrganizationsExpanded: value === '1' }
}

export const setFavoriteOrganizationsExpandedState = (value: boolean) => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.EXPANDED_STATE, value ? '1' : '0')
}
