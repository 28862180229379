import { AxiosResponse, CredentialTemplateTypes } from '@swiftctrl/api-client'
import { useMutation } from '@tanstack/react-query'
import { swiftClientWithoutErrorHandling } from '../../../../data/swiftClient'
import { trimValues } from '../../../../utils-hooks'
import { AddCredentialTemplateFormData } from './utils'

type Params = {
  onSuccess: (response: AxiosResponse<CredentialTemplateTypes, any>) => void
  onError: (error: Error) => void
}

export const useAddCredentialTemplate = ({ onSuccess, onError }: Params) =>
  useMutation({
    mutationFn: (values: AddCredentialTemplateFormData) => {
      const {
        organization,
        credentialFormatId,
        templateName,
        templateDescription,
      } = trimValues(values)

      return swiftClientWithoutErrorHandling.credentialTemplate.addFrom(
        credentialFormatId!,
        {
          organization_id: organization!.entity_id,
          template_name: templateName!,
          template_description: templateDescription || '',
        },
      )
    },
    onSuccess,
    onError,
  })
