import { AxiosResponse, FieldMapping } from '@swiftctrl/api-client'
import { useQuery } from '@tanstack/react-query'
import { Entry } from '../components/editable-table'
import { queryClient } from '../data/queryClient'
import { swiftClient } from '../data/swiftClient'
import { cacheKeys, longCacheOptions } from './cacheKeysUtils'

export const INDEX_REGEX_TOKEN = '\\[[0-9]*\\]'

const INDEX_REGEX = /\[.*?\]/g

const NO_VALUE_ERROR_MESSAGE = 'Local field is required'

const NO_OPTION_MATCH_ERROR_MESSAGE =
  'Local field must be from the list of options'

const INVALID_INDEX_ERROR_MESSAGE =
  'Local field must have numbers inside the brackets, e.g. [0]'

const DUPLICATE_LOCAL_FIELD_ERROR_MESSAGE =
  'One instance of remote/local_field per EPI allowed'

let localFieldValidationCollection:
  | {
      noIndex: Set<string>
      withIndex: RegExp[]
    }
  | undefined = undefined

export const useGetLocalFieldDefinitions = () =>
  useQuery({
    queryKey: [cacheKeys.epi_field_mappings_local_field_definitions],
    queryFn: async ({ signal }) => {
      const response = await swiftClient.epiLocalFieldDefinition.browse(
        {},
        { signal },
      )

      response.data = response.data.reduce((definitions, definition) => {
        if (definition === 'core.profile.custom_fields') {
          definitions.push(
            'core.profile.custom_fields[division]',
            'core.profile.custom_fields[organization]',
            'core.profile.custom_fields[department]',
            'core.profile.custom_fields[user_type]',
            'core.profile.custom_fields[business_unit]',
          )
        } else {
          definitions.push(definition)
        }

        return definitions
      }, new Array<string>())

      return response
    },
    ...longCacheOptions,
  })

export const validateLocalField = (
  value: string,
  entries: Entry<FieldMapping>[],
) => {
  const trimmedValue = value.trim()

  if (!trimmedValue) {
    return NO_VALUE_ERROR_MESSAGE
  }

  if (hasAnyDuplicates(trimmedValue, entries)) {
    return DUPLICATE_LOCAL_FIELD_ERROR_MESSAGE
  }

  const { noIndex, withIndex } = getLocalFieldValidationCollection()!

  const result = noIndex.has(trimmedValue)

  if (result) {
    return true
  }

  const indexValues = trimmedValue.match(INDEX_REGEX)

  if (indexValues) {
    const hasInvalidIndexError = checkForInvalidIndexError(indexValues)

    if (hasInvalidIndexError) {
      return INVALID_INDEX_ERROR_MESSAGE
    }
  }

  const hasMatch = checkForMatch(trimmedValue, withIndex)

  if (hasMatch) {
    return true
  }

  return NO_OPTION_MATCH_ERROR_MESSAGE
}

const getLocalFieldValidationCollection = () => {
  if (localFieldValidationCollection) {
    return localFieldValidationCollection
  }

  const response = queryClient.getQueryData<AxiosResponse<string[], any>>([
    cacheKeys.epi_field_mappings_local_field_definitions,
  ])!

  const noIndex = new Set<string>()

  const withIndex = new Array<RegExp>()

  response.data.forEach((definition) => {
    if (definition.includes(INDEX_REGEX_TOKEN)) {
      withIndex.push(new RegExp(definition))
    } else {
      noIndex.add(definition)
    }
  })

  localFieldValidationCollection = { noIndex, withIndex }

  return localFieldValidationCollection
}

const checkForInvalidIndexError = (indexValues: RegExpMatchArray) => {
  for (let i = 0; i < indexValues.length; i++) {
    const indexValue = indexValues[i]

    const bracketedIndex = indexValue.substring(1, indexValue.length - 1)

    if (bracketedIndex === '') {
      return true
    }

    const castIndex = Number(bracketedIndex)

    if (isNaN(castIndex)) {
      return true
    }
  }

  return false
}

const checkForMatch = (value: string, regexCollection: RegExp[]) => {
  for (let i = 0; i < regexCollection.length; i++) {
    const regex = regexCollection[i]

    if (regex.test(value)) {
      return true
    }
  }

  return false
}

const hasAnyDuplicates = (value: string, entries: Entry<FieldMapping>[]) => {
  const matchingEntries = entries.filter(
    (entry) => entry.local_field.trim() === value,
  )

  return matchingEntries.length > 1
}

export const useGetRemoteFieldDefinitions = () =>
  useQuery({
    queryKey: [cacheKeys.epi_field_mappings_remote_field_definitions],
    queryFn: ({ signal }) =>
      swiftClient.epiRemoteFieldDefinition.browse(
        {
          select: ['remote_field', 'product_name'],
        },
        {
          signal,
        },
      ),
    ...longCacheOptions,
  })
