import { ProviderTypes } from '@swiftctrl/api-client'
import { provider as providerConfig } from '../../../config/add-entity-types/provider'
import {
  trimValues,
  cacheKeys,
  invalidateReadProviderQuery,
  useEditProvider,
} from '../../../utils-hooks'
import { invalidateReadScreenQuery } from '../../read-screen'
import { buildInitialValues } from '../buildInitialValues'
import { useEditEntityCommonLogic } from '../useEditEntityCommonLogic'

type Params = {
  provider: ProviderTypes
  onEdited: () => void
  onClose: () => void
  baseIdIsPreset: boolean
}

export const useEditProviderLogic = ({
  provider,
  onEdited,
  onClose,
  baseIdIsPreset,
}: Params) => {
  const { provider_id } = provider

  const { fieldGroups, buildData } = providerConfig

  const { initialValues } = buildInitialValues(
    fieldGroups,
    provider,
    'provider',
  )

  const { mutate, isPending: isSaving } = useEditProvider()

  const {
    values,
    setValues,
    fields,
    fieldsAreValid,
    isDirty,
    checkBeforeClose,
    reset,
  } = useEditEntityCommonLogic(
    initialValues,
    fieldGroups,
    isSaving,
    onClose,
    baseIdIsPreset,
  )

  const save = () => {
    const data = buildData!(trimValues(values))

    mutate(
      { providerId: provider_id, data },
      {
        onSuccess,
      },
    )
  }

  const onSuccess = () => {
    reset()

    invalidateReadProviderQuery(provider_id)

    invalidateReadScreenQuery(cacheKeys.provider, provider_id)

    onEdited()
  }

  return {
    values,
    setValues,
    fields,
    fieldsAreValid,
    save,
    isSaving,
    isDirty,
    checkBeforeClose,
  }
}
