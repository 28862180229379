import {
  CredentialFormatsTypes,
  CredentialTemplateFieldTypes,
  CredentialTemplateTypes,
} from '@swiftctrl/api-client'
import { Layout, PreLoader } from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'
import { CredentialTemplatePreviewView, ErrorAlert } from '../../components'
import { DuplicateCredentialTemplateView } from '../../components/browse-entities/entity-types/credential-template/DuplicateCredentialTemplateView'
import { useEventContext } from '../../states'
import { useBoolean, useReadCredentialFormat } from '../../utils-hooks'
import { CredentialTemplateFieldsTable } from './CredentialTemplateFieldsTable'
import { CredentialTemplateSaveButton } from './CredentialTemplateSaveButton'
import { useCredentialTemplateFieldsLogic } from './useCredentialTemplateFieldsLogic'

export const CredentialTemplateScreenContent = ({
  credentialTemplate,
}: {
  credentialTemplate: CredentialTemplateTypes
}) => {
  const { isPending, error, data } = useReadCredentialFormat(
    credentialTemplate.credential_format_id,
  )

  if (isPending) {
    return <PreLoader centered />
  }

  if (error) {
    return (
      <Layout padding="xMedium" gap="xMedium" grow>
        <ErrorAlert error={error} />
      </Layout>
    )
  }

  return (
    <Content credentialTemplate={credentialTemplate} credentialFormat={data!} />
  )
}

const Content = ({
  credentialTemplate,
  credentialFormat,
}: {
  credentialTemplate: CredentialTemplateTypes
  credentialFormat: CredentialFormatsTypes
}) => {
  const { fields, editField, setFields, isDirty, isValid, save, isSaving } =
    useCredentialTemplateFieldsLogic(credentialTemplate, credentialFormat)

  const [isPreviewOpen, openPreview, closePreview] = useBoolean()

  const { useEvent } = useEventContext()

  useEvent('CredentialTemplateScreenHeader.PreviewButton.click', openPreview)

  const [isDuplicateViewOpen, openDuplicateView, closeDuplicateView] =
    useBoolean()

  useEvent(
    'CredentialTemplateScreenHeader.DuplicateButton.click',
    openDuplicateView,
  )

  return (
    <>
      <Layout padding="xMedium" gap="xMedium">
        <StyledSaveButton
          isDirty={isDirty}
          isValid={isValid}
          save={save}
          isSaving={isSaving}
        />
        <CredentialTemplateFieldsTable
          fields={fields}
          credentialFormat={credentialFormat}
          editField={editField}
          setFields={setFields}
        />
      </Layout>
      <CredentialTemplatePreviewView
        open={isPreviewOpen}
        credentialTemplate={{
          ...credentialTemplate,
          credential_field_configs: fields as CredentialTemplateFieldTypes[],
        }}
        onClose={closePreview}
      />
      <DuplicateCredentialTemplateView
        open={isDuplicateViewOpen}
        credentialTemplate={{
          ...credentialTemplate,
          credential_field_configs: fields as CredentialTemplateFieldTypes[],
        }}
        hasUnsavedChanges={isDirty}
        onClose={closeDuplicateView}
      />
    </>
  )
}

const StyledSaveButton = styled(CredentialTemplateSaveButton)`
  align-self: flex-end;
`
