import { OperationTypes } from '@swiftctrl/api-client'
import {
  Alert,
  Drawer,
  Form,
  Layout,
  Select,
  Switch,
} from '@swiftctrl/swift-component-library'
import { Radio } from 'antd'
import { AddFormButtonGroup, EntitySelect } from '../../../../components'
import {
  buildCommonDrawerProps,
  buildCommonFormProps,
  commonFilterOption,
} from '../../../../utils-hooks'
import { buildErrorMessage } from './buildErrorMessage'
import { buildFormOptions } from './buildFormOptions'
import { useAddRolePermissionsLogic } from './useAddRolePermissionsLogic'

type AddRolePermissionsDrawerProps = {
  open: boolean
  operations: OperationTypes[]
  roleId: string
  onClose: () => void
}

export const AddRolePermissionsView = ({
  open,
  operations,
  roleId,
  onClose,
}: AddRolePermissionsDrawerProps) => {
  const {
    values,
    actionErrors,
    isValid,
    isAccessCloudOperationPrefix,
    isPending,
    setValues,
    submit,
    checkBeforeClose,
  } = useAddRolePermissionsLogic({ roleId, onClose })

  const { prefix, actions } = values

  const {
    prefixOptions,
    actionOptions,
    fieldNameOptions,
    relationNameOptions,
    scopeOptions,
    modifierOptions,
  } = buildFormOptions(operations, prefix)

  return (
    <Drawer
      {...buildCommonDrawerProps({
        title: 'Add Permission(s)',
        width: 496,
        open,
        onClose: checkBeforeClose,
        isPending,
      })}
    >
      <Form
        {...buildCommonFormProps({
          values,
          setValues,
          isPending,
          onFinish: submit,
        })}
      >
        <Form.Item
          name="prefix"
          label="Object"
          rules={[
            {
              required: true,
              message: 'Object is required',
            },
          ]}
        >
          <Select
            showSearch
            options={prefixOptions}
            showLabel={false}
            filterOption={commonFilterOption}
          />
        </Form.Item>
        {prefix && (
          <>
            <Form.Item
              required
              name="actions"
              label="Operation(s)"
              rules={[
                {
                  validator: (_: any, values: string[] | undefined) =>
                    values && values.length > 0
                      ? Promise.resolve()
                      : Promise.reject('Operation(s) are required'),
                },
              ]}
            >
              <Select
                mode="multiple"
                options={actionOptions}
                showLabel={false}
                showArrow
                filterOption={commonFilterOption}
              />
            </Form.Item>
            {!isAccessCloudOperationPrefix && (
              <Form.Item name="fieldNames" label="Field Permissions">
                <Select
                  mode="multiple"
                  showSearch
                  options={fieldNameOptions}
                  showLabel={false}
                  showArrow
                  filterOption={commonFilterOption}
                />
              </Form.Item>
            )}
            {actions?.includes('LIST') && (
              <Form.Item
                name="relationNames"
                label="Relation Names"
                tooltip="For List Operations Only"
              >
                <Select
                  mode="multiple"
                  options={relationNameOptions}
                  showLabel={false}
                  showArrow
                  filterOption={commonFilterOption}
                />
              </Form.Item>
            )}
            <Form.Item required name="scope" label="Scope">
              <Radio.Group
                size="large"
                options={scopeOptions}
                optionType="button"
              />
            </Form.Item>
            <Form.Item required name="modifier" label="Modifier">
              <Radio.Group
                size="large"
                options={modifierOptions}
                optionType="button"
              />
            </Form.Item>
            {!isAccessCloudOperationPrefix && (
              <Form.Item name="grantsOn" label="Grants On">
                <EntitySelect
                  size="large"
                  placeholder="Grants on"
                  displayEntityType
                />
              </Form.Item>
            )}
            <Layout horizontal gap="medium">
              Grantable
              <Form.Item
                required
                name="grantable"
                label="Grantable"
                noStyle
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Layout>
          </>
        )}
        {actionErrors.map((actionError) => (
          <Form.Item>
            <Alert message={buildErrorMessage(actionError)} type="error" />
          </Form.Item>
        ))}
        <AddFormButtonGroup
          onClose={checkBeforeClose}
          isValid={isValid}
          isPending={isPending}
        />
      </Form>
    </Drawer>
  )
}
