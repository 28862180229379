import {
  CredentialFieldDateRange,
  CredentialFieldIntegerRange,
  EntityTypes,
} from '@swiftctrl/api-client'
import { Modal } from '@swiftctrl/swift-component-library'
import { addDays, parseISO, subDays } from 'date-fns'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CREDENTIAL_TEMPLATES_SCREEN_PATH } from '../../../../config/paths'
import { validateCredentialTemplate } from '../../../../screen-components/credential-template-screen/validate'
import {
  buildISOTimestampFromLocalDate,
  useReadCredentialFormat,
  useReadCredentialTemplate,
} from '../../../../utils-hooks'

export type AddCredentialTemplateFormData = {
  organization?: Partial<EntityTypes>
  credentialFormatId?: string
  templateName?: string
  templateDescription?: string
}

export const getDateRangeStartEnd = (dateRange: CredentialFieldDateRange) => {
  const { start: dateRangeStart, end: dateRangeEnd, open, close } = dateRange

  const dateRangeStartDate = parseISO(dateRangeStart)

  const startDate =
    open === 'EXCLUSIVE' ? addDays(dateRangeStartDate, 1) : dateRangeStartDate

  const start = buildISOTimestampFromLocalDate(startDate)

  const dateRangeEndDate = parseISO(dateRangeEnd)

  const endDate =
    close === 'EXCLUSIVE' ? subDays(dateRangeEndDate, 1) : dateRangeEndDate

  endDate.setHours(23, 59, 59, 0)

  const end = buildISOTimestampFromLocalDate(endDate)

  return { start, end }
}

export const getIntegerRangeStartEnd = (
  integerRange: CredentialFieldIntegerRange,
) => {
  const {
    start: integerRangeStart,
    end: integerRangeEnd,
    open,
    close,
  } = integerRange

  const start = open === 'EXCLUSIVE' ? integerRangeStart + 1 : integerRangeStart

  const end = close === 'EXCLUSIVE' ? integerRangeEnd - 1 : integerRangeEnd

  return { start, end }
}

export const useValidateCredentialTemplateBeforeCallback = (
  credentialTemplateId: string,
  callback: () => void,
) => {
  const navigate = useNavigate()

  const [clicked, setClicked] = useState(false)

  const { data: credentialTemplate } = useReadCredentialTemplate({
    id: credentialTemplateId,
    enabled: clicked,
    showErrorNotification: true,
  })

  const { data: credentialFormat } = useReadCredentialFormat(
    credentialTemplate?.credential_format_id || '',
    Boolean(credentialTemplate),
  )

  const validateCredentialTemplateBeforeCallback = async () => {
    setClicked(true)
  }

  useEffect(() => {
    if (!clicked || !credentialTemplate || !credentialFormat) {
      return
    }

    const {
      credential_field_configs,
      credential_format_config_id,
      template_name,
    } = credentialTemplate

    const isValid = validateCredentialTemplate(
      credential_field_configs,
      credentialFormat,
    )

    if (isValid) {
      callback()
    } else {
      const path = `/${CREDENTIAL_TEMPLATES_SCREEN_PATH}/${credential_format_config_id}`

      Modal.confirm({
        title: 'Invalid credential template',
        content: `"${template_name}" is invalid. Please fix errors before proceeding.`,
        okText: 'Open template',
        onOk: () => navigate(path),
        okButtonProps: {
          size: 'middle',
        },
        cancelButtonProps: { size: 'middle' },
        centered: true,
      })
    }

    setClicked(false)
  }, [clicked, credentialTemplate, credentialFormat, callback, navigate])

  return { validateCredentialTemplateBeforeCallback }
}
