import { OrganizationTypes } from '@swiftctrl/api-client'

export type AddProfileFormData = {
  organization?: OrganizationTypes
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
}

export type ContactOptions = {
  email: {
    required: boolean
  }
  phone: {
    visible: boolean
    required: boolean
  }
  requireEmailOrPhone: boolean
}

export type AdvancedSearchParams = {
  'first-name': string
  'last-name': string
  'employee-number': string
  email: string
}

export const EmptyAdvancedSearchParams: AdvancedSearchParams = {
  'first-name': '',
  'last-name': '',
  'employee-number': '',
  email: '',
}

export const validateEmailOrPhone = (
  requireEmailOrPhone: boolean,
  email: string | undefined,
  phone: string | undefined,
) => {
  if (requireEmailOrPhone && !email && !phone) {
    return Promise.reject('Email or phone is required')
  }

  return Promise.resolve()
}
