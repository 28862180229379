import { Form, Typography } from '@swiftctrl/swift-component-library'
import { AddEntityFormContext } from '../../states'
import {
  ConfiguredEntityTypes,
  useAddEntity,
  useBoolean,
} from '../../utils-hooks'
import { AddFormButtonGroup } from '../AddFormButtonGroup'
import { AddEntityFieldInput } from './AddEntityFieldInput'
import { areCurrentFieldsValid } from './areCurrentFieldsValid'

type AddEntityFormProps = {
  entityType: ConfiguredEntityTypes
  addDataTemplate?: object
  /**
   * If omitted, a searchable select for will be added that the user can use
   * to find and select the overseer.
   */
  overseerId?: string
  onDirty: () => void
  onAddingEntityStarted: () => void
  onAddingEntityStopped: () => void
  onEntityAdded: () => void
  onCancel: () => void
}

export const AddEntityForm = ({
  entityType,
  addDataTemplate,
  overseerId,
  onDirty,
  onAddingEntityStarted: onAddingEntityStartedProp,
  onAddingEntityStopped: onAddingEntityStoppedProp,
  onEntityAdded,
  onCancel,
}: AddEntityFormProps) => {
  const [addingEntity, setAddingEntityTrue, setAddingEntityFalse] = useBoolean()

  const onAddingEntityStarted = () => {
    setAddingEntityTrue()

    onAddingEntityStartedProp()
  }

  const onAddingEntityStopped = () => {
    setAddingEntityFalse()

    onAddingEntityStoppedProp()
  }

  const { values, updateValue, addEntity, currentFields, infoLabel } =
    useAddEntity({
      entityType,
      addDataTemplate,
      overseerId,
      onDirty,
      onAddingEntityStarted,
      onAddingEntityStopped,
      onEntityAdded,
    })

  const currentFieldsAreValid = areCurrentFieldsValid(
    currentFields,
    values,
    'add',
  )

  return (
    <AddEntityFormContext.Provider
      value={{
        values,
        updateValue,
        addingEntity,
      }}
    >
      <Form
        layout="vertical"
        onFinish={addEntity}
        preserve={false}
        validateTrigger="onBlur"
      >
        {currentFields.map((field) => (
          <AddEntityFieldInput key={String(field.key)} field={field} />
        ))}
        {infoLabel && (
          <Typography.Paragraph type="secondary">
            {infoLabel}
          </Typography.Paragraph>
        )}
        <AddFormButtonGroup
          isPending={addingEntity}
          isValid={currentFieldsAreValid}
          onClose={onCancel}
        />
      </Form>
    </AddEntityFormContext.Provider>
  )
}
