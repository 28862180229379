import {
  CredentialFormatsTypes,
  CredentialTemplateAddTypes,
  CredentialTemplateEditTypes,
  CredentialTemplateFieldTypes,
  CredentialTemplateTypes,
} from '@swiftctrl/api-client'
import { Model, Response } from 'miragejs'
import { v4 } from 'uuid'
import {
  getEnvironmentLocalStorageSwitch,
  getUTCTimestamp,
} from '../../utils-hooks'
import { MockFeature, MockModel, ServerWithMockModels } from './utils'

type ServerWithMockCredentialTemplate = ServerWithMockModels<{
  credentialTemplate: MockModel<CredentialTemplateTypes>
}>

const CREDENTIAL_TEMPLATES_ENDPOINT = '/credential-format-configs'

const CREDENTIAL_FORMATS_ENDPOINT = '/credential-formats'

export const credentialTemplateMock: MockFeature = {
  enabled: getEnvironmentLocalStorageSwitch({
    environments: ['local', 'dev'],
    localStorageKey: 'credentialTemplateMockEnabled',
  }),
  model: () => ({
    credentialTemplate: Model.extend<Partial<CredentialTemplateTypes>>({}),
  }),
  seed: (server: ServerWithMockCredentialTemplate) => {
    mockCredentialTemplates.forEach((credentialTemplate) =>
      server.create('credentialTemplate', credentialTemplate),
    )
  },
  route: (server: ServerWithMockCredentialTemplate) => {
    routeBrowse(server)

    routeRead(server)

    routeEdit(server)

    routeAdd(server)

    routeDelete(server)

    routeReadCredentialFormat(server, mockTimeCredentialFormat)

    routeReadCredentialFormat(server, mockIntegerCredentialFormat)

    routeReadCredentialFormat(server, mockStringCredentialFormat)

    routeReadCredentialFormat(server, mockUuidCredentialFormat)

    routeReadCredentialFormat(server, mockByteArrayCredentialFormat)
  },
}

const mockTimeCredentialFormat: CredentialFormatsTypes = {
  created_at: '2022-03-22T20:54:14Z',
  credential_format_id: '7db438f7-78cc-4532-852f-03fedfb8ff78',
  entity_name: 'Time credential format',
  entity_description: '',
  entity_status: 'ACTIVE',
  entity_type_id: 'credential_format',
  extra_ints: null,
  extra_json: {},
  extra_map: null,
  extra_strings: null,
  overseer_id: '1fd095c1-73e8-47b6-a83f-87321cb1280f',
  overseer_name: 'Time credential format overseer',
  updated_at: '',
  credential_field_formats: [
    {
      credential_field_format_id: '27a237d3-32c7-484b-ae5c-f2cbe54ca44d',
      field_name: 'No Constraint',
      field_type: 'TIME',
      constraint: undefined,
    },
    {
      credential_field_format_id: 'e9c63252-f439-45e6-90dd-5e0d9ab014ba',
      field_name: 'Date range from format',
      field_type: 'TIME',
      constraint: {
        type: 'date_range',
        value: {
          start: '2024-05-20',
          end: '2024-05-24',
          open: 'INCLUSIVE',
          close: 'INCLUSIVE',
        },
      },
    },
    {
      credential_field_format_id: 'c9e45e65-dc3b-46c2-925f-21c9326dff92',
      field_name: 'Date range from template',
      field_type: 'TIME',
      constraint: undefined,
    },
  ],
}

const mockIntegerCredentialFormat: CredentialFormatsTypes = {
  created_at: '2022-03-22T20:54:14Z',
  credential_format_id: 'e3a73cd8-af71-4a22-84bb-f4d8db025c47',
  entity_name: 'Integer credential format',
  entity_description: '',
  entity_status: 'ACTIVE',
  entity_type_id: 'credential_format',
  extra_ints: null,
  extra_json: {},
  extra_map: null,
  extra_strings: null,
  overseer_id: 'c4426257-1446-421c-86cb-7472936ee013',
  overseer_name: 'Integer credential format overseer',
  updated_at: '',
  credential_field_formats: [
    {
      credential_field_format_id: '80402f32-4319-4663-a854-899f387af431',
      field_name: 'No Constraint',
      field_type: 'INTEGER',
      constraint: undefined,
    },
    {
      credential_field_format_id: '636692f3-fc28-4269-9c13-3468754499e0',
      field_name: 'Integer range from format',
      field_type: 'INTEGER',
      constraint: {
        type: 'integer_range',
        value: {
          start: 10,
          end: 50,
          open: 'INCLUSIVE',
          close: 'INCLUSIVE',
        },
      },
    },
    {
      credential_field_format_id: '3a527739-3937-44b2-9c1d-771f38ca3e17',
      field_name: 'Integer range from template',
      field_type: 'INTEGER',
      constraint: undefined,
    },
    {
      credential_field_format_id: '0b8f91df-2c7a-4cd5-b22a-e43462230430',
      field_name: 'Bitfield value from format',
      field_type: 'INTEGER',
      constraint: {
        type: 'bitfield_value',
        value: {
          start_bit: 9,
          end_bit: 16,
        },
      },
    },
    {
      credential_field_format_id: 'b188905d-45f4-4536-a8ba-d45f402d9cf5',
      field_name: 'Bitfield parity from format',
      field_type: 'INTEGER',
      constraint: {
        type: 'bitfield_parity',
        value: {
          parity_even: true,
          parity_bit_location: 0,
          parity_mask: [116, 101, 115, 116],
        },
      },
    },
    {
      credential_field_format_id: 'ee53813c-69cf-4316-b591-38c25379affc',
      field_name: 'Bitfield value from template',
      field_type: 'INTEGER',
      constraint: undefined,
    },
    {
      credential_field_format_id: '349db6f5-95ec-4252-ac9c-c5e2ea0e8270',
      field_name: 'Valid values from format',
      field_type: 'INTEGER',
      constraint: {
        type: 'valid_values',
        value: {
          valid_values: [
            {
              type: 'integer',
              value: 50,
            },
            {
              type: 'integer',
              value: 100,
            },
            {
              type: 'integer',
              value: 200,
            },
          ],
        },
      },
    },
    {
      credential_field_format_id: '665d11d8-9b58-446d-88a6-be041840c240',
      field_name: 'Valid values from template',
      field_type: 'INTEGER',
      constraint: undefined,
    },
  ],
}

const mockStringCredentialFormat: CredentialFormatsTypes = {
  created_at: '2022-03-22T20:54:14Z',
  credential_format_id: 'c6ca6a44-c34f-4635-aeec-fe03df730cac',
  entity_name: 'String credential format',
  entity_description: '',
  entity_status: 'ACTIVE',
  entity_type_id: 'credential_format',
  extra_ints: null,
  extra_json: {},
  extra_map: null,
  extra_strings: null,
  overseer_id: 'bc0c3c71-c242-4d89-af11-c7250ca4143d',
  overseer_name: 'String credential format overseer',
  updated_at: '',
  credential_field_formats: [
    {
      credential_field_format_id: '6d3d5cc0-9978-4006-8507-bd96f550b42d',
      field_name: 'No Constraint',
      field_type: 'STRING',
      constraint: undefined,
    },
    {
      credential_field_format_id: '37c6b487-57a6-49c0-a881-37a193daddb2',
      field_name: 'Valid values from format',
      field_type: 'STRING',
      constraint: {
        type: 'valid_values',
        value: {
          valid_values: [
            {
              type: 'string',
              value: 'Option A',
            },
            {
              type: 'string',
              value: 'Option B',
            },
            {
              type: 'string',
              value: 'Option C',
            },
          ],
        },
      },
    },
    {
      credential_field_format_id: '22799b32-6897-4cd9-9551-ec9d99c83377',
      field_name: 'Valid values from template',
      field_type: 'STRING',
      constraint: undefined,
    },
  ],
}

const mockUuidCredentialFormat: CredentialFormatsTypes = {
  created_at: '2022-03-22T20:54:14Z',
  credential_format_id: 'e635ed32-a29a-41b8-975d-a4f0264e7abd',
  entity_name: 'UUID credential format',
  entity_description: '',
  entity_status: 'ACTIVE',
  entity_type_id: 'credential_format',
  extra_ints: null,
  extra_json: {},
  extra_map: null,
  extra_strings: null,
  overseer_id: 'd6e5efd0-fed6-4008-a456-dd5fc56b4ebc',
  overseer_name: 'UUID credential format overseer',
  updated_at: '',
  credential_field_formats: [
    {
      credential_field_format_id: '6a6ed36a-1f78-4718-8e13-4a2971a16e13',
      field_name: 'No Constraint',
      field_type: 'UUID',
      constraint: undefined,
    },
    {
      credential_field_format_id: '90edc24f-4276-43cd-98cc-bedfdd82a3da',
      field_name: 'Valid values from format',
      field_type: 'UUID',
      constraint: {
        type: 'valid_values',
        value: {
          valid_values: [
            {
              type: 'uuid',
              value: 'b1b3de41-006e-4c31-a5f9-0c598a86ce8d',
            },
            {
              type: 'uuid',
              value: '820b717a-72e7-4298-a0f8-e4b33d9f366f',
            },
            {
              type: 'uuid',
              value: '64ea082f-caae-4a78-bde4-fe078e87783a',
            },
          ],
        },
      },
    },
    {
      credential_field_format_id: 'be5e3d19-2e65-4f0f-b8ff-d0eaa0e7457b',
      field_name: 'Valid values from template',
      field_type: 'UUID',
      constraint: undefined,
    },
  ],
}

const mockByteArrayCredentialFormat: CredentialFormatsTypes = {
  created_at: '2022-03-22T20:54:14Z',
  credential_format_id: '9dfc2fd2-49e9-4a0a-9dd3-08f85737c423',
  entity_name: 'Byte array credential format',
  entity_description: '',
  entity_status: 'ACTIVE',
  entity_type_id: 'credential_format',
  extra_ints: null,
  extra_json: {},
  extra_map: null,
  extra_strings: null,
  overseer_id: '9587c261-b3e0-420b-96e1-5b5ff94b46d6',
  overseer_name: 'Byte array credential format overseer',
  updated_at: '',
  credential_field_formats: [
    {
      credential_field_format_id: '125e9903-bcb0-49d7-9358-832e93ee8478',
      field_name: 'No Constraint',
      field_type: 'BYTE_ARRAY',
      constraint: undefined,
    },
  ],
}

const mockCredentialTemplates: CredentialTemplateTypes[] = [
  {
    created_at: '2022-03-22T20:54:14Z',
    updated_at: '2022-03-22T20:54:14Z',
    credential_format_config_id: 'faa33e0a-5635-43c7-ab9e-dcb254eef5a3',
    template_name: 'Credential template A',
    template_description: 'Some credential format description',
    organization_id: '',
    credential_format_id: 'b31408e2-174a-4ad2-a94e-c32529e1fb83',
    credential_format_name:
      'Access Cloud Credential Format 81cc9259-ab8b-4842-b57c-b2dee37b7785',
    overseer_id: 'f62bc65d-32c8-4f28-9527-4d12cbf58362',
    overseer_name: 'Test Epi 1 - Access Cloud - Building 1',
    credential_field_configs: [
      {
        rank: 0,
        field_type: 'INTEGER',
        field_name: 'facilitycode',
        display_name: 'facilitycode',
        placeholder: 'Enter facility code',
        tooltip: 'value is between 0 and 65535',
        is_optional: false,
        is_hidden: false,
        read_only: false,
        default_value: '',
        credential_field_format_id: '37134983-b90f-48cb-851b-f092e8be32c3',
        credential_format_config_id: 'faa33e0a-5635-43c7-ab9e-dcb254eef5a3',
        created_at: '2022-03-22T20:54:14Z',
        updated_at: '2022-03-22T20:54:14Z',
        bitfield_value: {
          start_bit: 9,
          end_bit: 24,
        },
        bitfield_parity: null,
        constraint: {
          integer_range: {
            start: 0,
            end: 65535,
          },
          date_range: null,
          valid_values: null,
        },
      },
      {
        rank: 1,
        field_type: 'STRING',
        field_name: 'badgelayout',
        display_name: 'badgelayout',
        placeholder: 'Enter badge layout',
        tooltip: '',
        is_optional: false,
        is_hidden: false,
        read_only: false,
        default_value: '',
        credential_field_format_id: '7f8f6257-607a-4ceb-aff2-50602998e699',
        credential_format_config_id: 'faa33e0a-5635-43c7-ab9e-dcb254eef5a3',
        created_at: '2022-03-22T20:54:14Z',
        updated_at: '2022-03-22T20:54:14Z',
        bitfield_value: null,
        bitfield_parity: null,
        constraint: null,
      },
      {
        rank: 2,
        field_type: 'BOOL',
        field_name: 'temporary',
        display_name: 'temporary',
        placeholder: '',
        tooltip: '',
        is_optional: true,
        is_hidden: false,
        read_only: false,
        default_value: 'true',
        credential_field_format_id: 'dbb8f952-495b-47cf-9703-c855e922ac33',
        credential_format_config_id: 'faa33e0a-5635-43c7-ab9e-dcb254eef5a3',
        created_at: '2022-03-22T20:54:14Z',
        updated_at: '2022-03-22T20:54:14Z',
        bitfield_value: null,
        bitfield_parity: null,
        constraint: null,
      },
      {
        rank: 3,
        field_type: 'INTEGER',
        field_name: 'issuecode',
        display_name: 'issuecode',
        credential_field_format_id: 'd3014eb7-1998-40ee-a0f4-7ccd5452e88c',
        credential_format_config_id: 'faa33e0a-5635-43c7-ab9e-dcb254eef5a3',
        bitfield_parity: null,
        bitfield_value: null,
        constraint: null,
        created_at: '2022-03-22T20:54:14Z',
        default_value: '',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        tooltip: '',
        updated_at: '2022-03-22T20:54:14Z',
      },
      {
        rank: 4,
        credential_field_format_id: 'fc2107ca-b8c3-46ce-9528-10c2d02f2184',
        field_name: 'cardnumber',
        display_name: 'cardnumber',
        field_type: 'INTEGER',
        credential_format_config_id: 'faa33e0a-5635-43c7-ab9e-dcb254eef5a3',
        bitfield_parity: null,
        bitfield_value: null,
        constraint: null,
        created_at: '2022-03-22T20:54:14Z',
        default_value: '',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        tooltip: '',
        updated_at: '2022-03-22T20:54:14Z',
      },
      {
        rank: 5,
        credential_field_format_id: 'd5ff07f6-d64c-484a-b78b-6e466a03dd77',
        field_name: 'lost',
        display_name: 'lost',
        field_type: 'BOOL',
        credential_format_config_id: 'faa33e0a-5635-43c7-ab9e-dcb254eef5a3',
        bitfield_parity: null,
        bitfield_value: null,
        constraint: null,
        created_at: '2022-03-22T20:54:14Z',
        default_value: '',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        tooltip: '',
        updated_at: '2022-03-22T20:54:14Z',
      },
      {
        rank: 6,
        credential_field_format_id: '268d2c72-6ddf-4452-9da1-e1017cbacd05',
        field_name: 'stolen',
        display_name: 'stolen',
        field_type: 'BOOL',
        credential_format_config_id: 'faa33e0a-5635-43c7-ab9e-dcb254eef5a3',
        bitfield_parity: null,
        bitfield_value: null,
        constraint: null,
        created_at: '2022-03-22T20:54:14Z',
        default_value: '',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        tooltip: '',
        updated_at: '2022-03-22T20:54:14Z',
      },
      {
        rank: 7,
        credential_field_format_id: '327d38a7-c3be-4c39-a1bc-efadeb9c137e',
        field_name: 'disabledbyinactivity',
        display_name: 'disabledbyinactivity',
        field_type: 'BOOL',
        credential_format_config_id: 'faa33e0a-5635-43c7-ab9e-dcb254eef5a3',
        bitfield_parity: null,
        bitfield_value: null,
        constraint: null,
        created_at: '2022-03-22T20:54:14Z',
        default_value: '',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        tooltip: '',
        updated_at: '2022-03-22T20:54:14Z',
      },
      {
        rank: 8,
        credential_field_format_id: '6841dbf8-f006-4bc9-9d20-c79babc0f648',
        field_name: 'chuidformat',
        display_name: 'chuidformat',
        field_type: 'STRING',
        constraint: {
          valid_values: [
            { type: 'string', value: 'Card Only' },
            { type: 'string', value: 'Facility Code and Card Number' },
          ],
          date_range: null,
          integer_range: null,
        },
        credential_format_config_id: 'faa33e0a-5635-43c7-ab9e-dcb254eef5a3',
        bitfield_parity: null,
        bitfield_value: null,
        created_at: '2022-03-22T20:54:14Z',
        default_value: '',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        tooltip: '',
        updated_at: '2022-03-22T20:54:14Z',
      },
      {
        rank: 9,
        credential_field_format_id: '4b8c4e52-e08c-4854-a7c7-d6ec91eab9b0',
        field_name: 'expired',
        display_name: 'expired',
        field_type: 'BOOL',
        credential_format_config_id: 'faa33e0a-5635-43c7-ab9e-dcb254eef5a3',
        bitfield_parity: null,
        bitfield_value: null,
        constraint: null,
        created_at: '2022-03-22T20:54:14Z',
        default_value: '',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        tooltip: '',
        updated_at: '2022-03-22T20:54:14Z',
      },
      {
        rank: 10,
        credential_field_format_id: '5bc531e4-52da-4f99-88fd-4266613b55c2',
        field_name: 'disabled',
        display_name: 'disabled',
        field_type: 'BOOL',
        credential_format_config_id: 'faa33e0a-5635-43c7-ab9e-dcb254eef5a3',
        bitfield_parity: null,
        bitfield_value: null,
        constraint: null,
        created_at: '2022-03-22T20:54:14Z',
        default_value: '',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        tooltip: '',
        updated_at: '2022-03-22T20:54:14Z',
      },
    ],
  },
  {
    created_at: '2022-03-22T20:54:14Z',
    updated_at: '2022-03-22T20:54:14Z',
    credential_format_config_id: '186c910e-a50c-47bb-a3c0-ddddf5adb640',
    template_name: 'Credential template B',
    template_description: 'Some credential format description',
    organization_id: '',
    credential_format_id: '28892ab8-fc1b-4230-a0b3-f7acf6fcb95d',
    credential_format_name:
      'CCure Credential Format 7f87d999-ad64-463a-aad4-44d5fc7db1c5',
    overseer_id: '7b2a54c5-72c9-42c9-b40d-6f618464b11f',
    overseer_name: '601 Lexington',
    credential_field_configs: [
      {
        rank: 0,
        credential_field_format_id: '345c149b-e8e4-4b8d-9ac6-58b93c3549c8',
        field_name: 'disabledbyinactivity',
        display_name: 'disabledbyinactivity',
        field_type: 'BOOL',
        credential_format_config_id: '186c910e-a50c-47bb-a3c0-ddddf5adb640',
        bitfield_parity: null,
        bitfield_value: null,
        constraint: null,
        created_at: '2022-03-22T20:54:14Z',
        default_value: '',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        tooltip: '',
        updated_at: '2022-03-22T20:54:14Z',
      },
      {
        rank: 1,
        credential_field_format_id: 'f59bf53e-8ebc-44d2-86c4-80fe1fcef69c',
        field_name: 'temporary',
        display_name: 'temporary',
        field_type: 'BOOL',
        credential_format_config_id: '186c910e-a50c-47bb-a3c0-ddddf5adb640',
        bitfield_parity: null,
        bitfield_value: null,
        constraint: null,
        created_at: '2022-03-22T20:54:14Z',
        default_value: '',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        tooltip: '',
        updated_at: '2022-03-22T20:54:14Z',
      },
      {
        rank: 2,
        credential_field_format_id: '43d75fea-f470-4271-9ad4-4df48152a87b',
        field_name: 'facilitycode',
        display_name: 'facilitycode',
        field_type: 'INTEGER',
        credential_format_config_id: '186c910e-a50c-47bb-a3c0-ddddf5adb640',
        bitfield_parity: null,
        bitfield_value: null,
        constraint: null,
        created_at: '2022-03-22T20:54:14Z',
        default_value: '',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        tooltip: '',
        updated_at: '2022-03-22T20:54:14Z',
      },
      {
        rank: 3,
        credential_field_format_id: 'ebceb5b8-dd16-4354-adf1-6ded64f3a560',
        field_name: 'issuecode',
        display_name: 'issuecode',
        field_type: 'INTEGER',
        credential_format_config_id: '186c910e-a50c-47bb-a3c0-ddddf5adb640',
        bitfield_parity: null,
        bitfield_value: null,
        constraint: null,
        created_at: '2022-03-22T20:54:14Z',
        default_value: '',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        tooltip: '',
        updated_at: '2022-03-22T20:54:14Z',
      },
      {
        rank: 4,
        credential_field_format_id: 'dab55b5d-b4fc-4618-8021-672fe3033af0',
        field_name: 'cardnumber',
        display_name: 'cardnumber',
        field_type: 'INTEGER',
        credential_format_config_id: '186c910e-a50c-47bb-a3c0-ddddf5adb640',
        bitfield_parity: null,
        bitfield_value: null,
        constraint: null,
        created_at: '2022-03-22T20:54:14Z',
        default_value: '',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        tooltip: '',
        updated_at: '2022-03-22T20:54:14Z',
      },
      {
        rank: 5,
        credential_field_format_id: 'c536bb72-97d5-4252-9f5f-c80bd728a834',
        field_name: 'expired',
        display_name: 'expired',
        field_type: 'BOOL',
        credential_format_config_id: '186c910e-a50c-47bb-a3c0-ddddf5adb640',
        bitfield_parity: null,
        bitfield_value: null,
        constraint: null,
        created_at: '2022-03-22T20:54:14Z',
        default_value: '',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        tooltip: '',
        updated_at: '2022-03-22T20:54:14Z',
      },
      {
        rank: 6,
        credential_field_format_id: 'f6b51054-4bc8-46e1-aecb-f56af98eef4f',
        field_name: 'lost',
        display_name: 'lost',
        field_type: 'BOOL',
        credential_format_config_id: '186c910e-a50c-47bb-a3c0-ddddf5adb640',
        bitfield_parity: null,
        bitfield_value: null,
        constraint: null,
        created_at: '2022-03-22T20:54:14Z',
        default_value: '',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        tooltip: '',
        updated_at: '2022-03-22T20:54:14Z',
      },
      {
        rank: 7,
        credential_field_format_id: '61b41b97-3442-4f47-a681-142bae42b5b4',
        field_name: 'stolen',
        display_name: 'stolen',
        field_type: 'BOOL',
        credential_format_config_id: '186c910e-a50c-47bb-a3c0-ddddf5adb640',
        bitfield_parity: null,
        bitfield_value: null,
        constraint: null,
        created_at: '2022-03-22T20:54:14Z',
        default_value: '',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        tooltip: '',
        updated_at: '2022-03-22T20:54:14Z',
      },
      {
        rank: 8,
        credential_field_format_id: '583a74cc-2591-45a4-bd50-6bb0bd20002a',
        field_name: 'disabled',
        display_name: 'disabled',
        field_type: 'BOOL',
        credential_format_config_id: '186c910e-a50c-47bb-a3c0-ddddf5adb640',
        bitfield_parity: null,
        bitfield_value: null,
        constraint: null,
        created_at: '2022-03-22T20:54:14Z',
        default_value: '',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        tooltip: '',
        updated_at: '2022-03-22T20:54:14Z',
      },
      {
        rank: 9,
        credential_field_format_id: 'c32f8a84-22a1-45a3-a892-e9faa916c5ff',
        field_name: 'chuidformat',
        display_name: 'chuidformat',
        field_type: 'STRING',
        credential_format_config_id: '186c910e-a50c-47bb-a3c0-ddddf5adb640',
        constraint: {
          valid_values: [
            { type: 'string', value: 'Card Only' },
            { type: 'string', value: 'Facility Code and Card Number' },
          ],
          date_range: null,
          integer_range: null,
        },
        bitfield_parity: null,
        bitfield_value: null,
        created_at: '2022-03-22T20:54:14Z',
        default_value: '',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        tooltip: '',
        updated_at: '2022-03-22T20:54:14Z',
      },
      {
        rank: 10,
        credential_field_format_id: '2456ac7c-7b06-48d7-8322-15f2a348f219',
        field_name: 'badgelayout',
        display_name: 'badgelayout',
        field_type: 'STRING',
        credential_format_config_id: '186c910e-a50c-47bb-a3c0-ddddf5adb640',
        bitfield_parity: null,
        bitfield_value: null,
        constraint: null,
        created_at: '2022-03-22T20:54:14Z',
        default_value: '',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        tooltip: '',
        updated_at: '2022-03-22T20:54:14Z',
      },
    ],
  },
  {
    created_at: '2022-03-22T20:54:14Z',
    updated_at: '',
    credential_format_config_id: 'd192ccca-6f9e-4061-a2af-0bb747e488de',
    template_name: 'Time credential template',
    template_description: '',
    organization_id: '',
    credential_format_id: mockTimeCredentialFormat.credential_format_id,
    credential_format_name: mockTimeCredentialFormat.entity_name,
    overseer_id: mockTimeCredentialFormat.overseer_id,
    overseer_name: mockTimeCredentialFormat.overseer_name,
    credential_field_configs: [
      {
        bitfield_parity: null,
        bitfield_value: null,
        constraint: null,
        created_at: '',
        credential_field_format_id: '27a237d3-32c7-484b-ae5c-f2cbe54ca44d',
        credential_format_config_id: 'd192ccca-6f9e-4061-a2af-0bb747e488de',
        default_value: '',
        field_name: 'No Constraint',
        display_name: 'No Constraint',
        field_type: 'TIME',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        rank: 0,
        tooltip: '',
        updated_at: '',
      },
      {
        bitfield_parity: null,
        bitfield_value: null,
        constraint: {
          date_range: {
            start: '2024-05-20T00:00:00Z',
            end: '2024-05-24T23:59:59Z',
          },
          integer_range: null,
          valid_values: null,
        },
        created_at: '',
        credential_field_format_id: 'e9c63252-f439-45e6-90dd-5e0d9ab014ba',
        credential_format_config_id: 'd192ccca-6f9e-4061-a2af-0bb747e488de',
        default_value: '',
        field_name: 'Date range from format',
        display_name: 'Date range from format',
        field_type: 'TIME',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        rank: 1,
        tooltip: '',
        updated_at: '',
      },
      {
        bitfield_parity: null,
        bitfield_value: null,
        constraint: {
          date_range: {
            start: '2024-05-20T00:00:00Z',
            end: '2024-05-24T23:59:59Z',
          },
          integer_range: null,
          valid_values: null,
        },
        created_at: '',
        credential_field_format_id: 'c9e45e65-dc3b-46c2-925f-21c9326dff92',
        credential_format_config_id: 'd192ccca-6f9e-4061-a2af-0bb747e488de',
        default_value: '',
        field_name: 'Date range from template',
        display_name: 'Date range from template',
        field_type: 'TIME',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        rank: 2,
        tooltip: '',
        updated_at: '',
      },
    ],
  },
  {
    created_at: '2022-03-22T20:54:14Z',
    updated_at: '',
    credential_format_config_id: 'f35ddbbb-aff7-4bac-9cf9-417b206f7c52',
    template_name: 'Integer credential template',
    template_description: '',
    organization_id: '',
    credential_format_id: mockIntegerCredentialFormat.credential_format_id,
    credential_format_name: mockIntegerCredentialFormat.entity_name,
    overseer_id: mockIntegerCredentialFormat.overseer_id,
    overseer_name: mockIntegerCredentialFormat.overseer_name,
    credential_field_configs: [
      {
        bitfield_parity: null,
        bitfield_value: null,
        constraint: null,
        created_at: '',
        credential_field_format_id: '80402f32-4319-4663-a854-899f387af431',
        credential_format_config_id: 'f35ddbbb-aff7-4bac-9cf9-417b206f7c52',
        default_value: '',
        field_name: 'No Constraint',
        display_name: 'No Constraint',
        field_type: 'INTEGER',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        rank: 0,
        tooltip: '',
        updated_at: '',
      },
      {
        bitfield_parity: null,
        bitfield_value: null,
        constraint: {
          integer_range: {
            start: 10,
            end: 50,
          },
          date_range: null,
          valid_values: null,
        },
        created_at: '',
        credential_field_format_id: '636692f3-fc28-4269-9c13-3468754499e0',
        credential_format_config_id: 'f35ddbbb-aff7-4bac-9cf9-417b206f7c52',
        default_value: '',
        field_name: 'Integer range from format',
        display_name: 'Integer range from format',
        field_type: 'INTEGER',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        rank: 1,
        tooltip: '',
        updated_at: '',
      },
      {
        bitfield_parity: null,
        bitfield_value: null,
        constraint: {
          integer_range: {
            start: 10,
            end: 50,
          },
          date_range: null,
          valid_values: null,
        },
        created_at: '',
        credential_field_format_id: '3a527739-3937-44b2-9c1d-771f38ca3e17',
        credential_format_config_id: 'f35ddbbb-aff7-4bac-9cf9-417b206f7c52',
        default_value: '',
        field_name: 'Integer range from template',
        display_name: 'Integer range from template',
        field_type: 'INTEGER',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        rank: 2,
        tooltip: '',
        updated_at: '',
      },
      {
        bitfield_parity: null,
        bitfield_value: {
          start_bit: 9,
          end_bit: 16,
        },
        constraint: null,
        created_at: '',
        credential_field_format_id: '0b8f91df-2c7a-4cd5-b22a-e43462230430',
        credential_format_config_id: 'f35ddbbb-aff7-4bac-9cf9-417b206f7c52',
        default_value: '',
        field_name: 'Bitfield value from format',
        display_name: 'Bitfield value from format',
        field_type: 'INTEGER',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        rank: 3,
        tooltip: '',
        updated_at: '',
      },
      {
        bitfield_parity: {
          parity_bit_location: 0,
          parity_even: true,
          parity_mask: 'dGVzdA==',
        },
        bitfield_value: null,
        constraint: null,
        created_at: '',
        credential_field_format_id: 'b188905d-45f4-4536-a8ba-d45f402d9cf5',
        credential_format_config_id: 'f35ddbbb-aff7-4bac-9cf9-417b206f7c52',
        default_value: '',
        field_name: 'Bitfield parity from format',
        display_name: 'Bitfield parity from format',
        field_type: 'INTEGER',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        rank: 4,
        tooltip: '',
        updated_at: '',
      },
      {
        bitfield_parity: null,
        bitfield_value: {
          start_bit: 2,
          end_bit: 8,
        },
        constraint: null,
        created_at: '',
        credential_field_format_id: 'ee53813c-69cf-4316-b591-38c25379affc',
        credential_format_config_id: 'f35ddbbb-aff7-4bac-9cf9-417b206f7c52',
        default_value: '',
        field_name: 'Bitfield value from template',
        display_name: 'Bitfield value from template',
        field_type: 'INTEGER',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        rank: 5,
        tooltip: '',
        updated_at: '',
      },
      {
        bitfield_parity: null,
        bitfield_value: null,
        constraint: {
          valid_values: [
            {
              type: 'integer',
              value: '50',
            },
            {
              type: 'integer',
              value: '100',
            },
            {
              type: 'integer',
              value: '200',
            },
          ],
          date_range: null,
          integer_range: null,
        },
        created_at: '',
        credential_field_format_id: '349db6f5-95ec-4252-ac9c-c5e2ea0e8270',
        credential_format_config_id: 'f35ddbbb-aff7-4bac-9cf9-417b206f7c52',
        default_value: '',
        field_name: 'Valid values from format',
        display_name: 'Valid values from format',
        field_type: 'INTEGER',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        rank: 6,
        tooltip: '',
        updated_at: '',
      },
      {
        bitfield_parity: null,
        bitfield_value: null,
        constraint: {
          valid_values: [
            {
              type: 'integer',
              value: '1',
            },
            {
              type: 'integer',
              value: '2',
            },
            {
              type: 'integer',
              value: '3',
            },
          ],
          date_range: null,
          integer_range: null,
        },
        created_at: '',
        credential_field_format_id: '665d11d8-9b58-446d-88a6-be041840c240',
        credential_format_config_id: 'f35ddbbb-aff7-4bac-9cf9-417b206f7c52',
        default_value: '',
        field_name: 'Valid values from template',
        display_name: 'Valid values from template',
        field_type: 'INTEGER',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        rank: 7,
        tooltip: '',
        updated_at: '',
      },
    ],
  },
  {
    created_at: '2022-03-22T20:54:14Z',
    updated_at: '',
    credential_format_config_id: 'e4aaccd7-3191-4a19-a3fb-8407e8db0f8f',
    template_name: 'String credential template',
    template_description: '',
    organization_id: '',
    credential_format_id: mockStringCredentialFormat.credential_format_id,
    credential_format_name: mockStringCredentialFormat.entity_name,
    overseer_id: mockStringCredentialFormat.overseer_id,
    overseer_name: mockStringCredentialFormat.overseer_name,
    credential_field_configs: [
      {
        bitfield_parity: null,
        bitfield_value: null,
        constraint: null,
        created_at: '',
        credential_field_format_id: '6d3d5cc0-9978-4006-8507-bd96f550b42d',
        credential_format_config_id: 'e4aaccd7-3191-4a19-a3fb-8407e8db0f8f',
        default_value: '',
        field_name: 'No Constraint',
        display_name: 'No Constraint',
        field_type: 'STRING',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        rank: 0,
        tooltip: '',
        updated_at: '',
      },
      {
        bitfield_parity: null,
        bitfield_value: null,
        constraint: {
          valid_values: [
            {
              type: 'string',
              value: 'Option A',
            },
            {
              type: 'string',
              value: 'Option B',
            },
            {
              type: 'string',
              value: 'Option C',
            },
          ],
          date_range: null,
          integer_range: null,
        },
        created_at: '',
        credential_field_format_id: '37c6b487-57a6-49c0-a881-37a193daddb2',
        credential_format_config_id: 'e4aaccd7-3191-4a19-a3fb-8407e8db0f8f',
        default_value: '',
        field_name: 'Valid values from format',
        display_name: 'Valid values from format',
        field_type: 'STRING',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        rank: 1,
        tooltip: '',
        updated_at: '',
      },
      {
        bitfield_parity: null,
        bitfield_value: null,
        constraint: {
          valid_values: [
            {
              type: 'string',
              value: 'Option A',
            },
            {
              type: 'string',
              value: 'Option B',
            },
            {
              type: 'string',
              value: 'Option C',
            },
          ],
          date_range: null,
          integer_range: null,
        },
        created_at: '',
        credential_field_format_id: '22799b32-6897-4cd9-9551-ec9d99c83377',
        credential_format_config_id: 'e4aaccd7-3191-4a19-a3fb-8407e8db0f8f',
        default_value: '',
        field_name: 'Valid values from template',
        display_name: 'Valid values from template',
        field_type: 'STRING',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        rank: 2,
        tooltip: '',
        updated_at: '',
      },
    ],
  },
  {
    created_at: '2022-03-22T20:54:14Z',
    updated_at: '2022-03-22T20:54:14Z',
    credential_format_config_id: 'dac4dd71-5f3f-4298-990f-c560d61cc1c7',
    template_name: 'UUID credential template',
    template_description: '',
    organization_id: '',
    credential_format_id: mockUuidCredentialFormat.credential_format_id,
    credential_format_name: mockUuidCredentialFormat.entity_name,
    overseer_id: mockUuidCredentialFormat.overseer_id,
    overseer_name: mockUuidCredentialFormat.overseer_name,
    credential_field_configs: [
      {
        created_at: '2022-03-22T20:54:14Z',
        updated_at: '',
        credential_field_format_id: '6a6ed36a-1f78-4718-8e13-4a2971a16e13',
        field_name: 'No Constraint',
        display_name: 'No Constraint',
        field_type: 'UUID',
        credential_format_config_id: 'dac4dd71-5f3f-4298-990f-c560d61cc1c7',
        bitfield_parity: null,
        bitfield_value: null,
        constraint: null,
        default_value: '',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        rank: 0,
        tooltip: '',
      },
      {
        created_at: '2022-03-22T20:54:14Z',
        updated_at: '',
        credential_field_format_id: '90edc24f-4276-43cd-98cc-bedfdd82a3da',
        field_name: 'Valid values from format',
        display_name: 'Valid values from format',
        field_type: 'UUID',
        credential_format_config_id: 'dac4dd71-5f3f-4298-990f-c560d61cc1c7',
        bitfield_parity: null,
        bitfield_value: null,
        constraint: {
          valid_values: [
            {
              type: 'uuid',
              value: 'b1b3de41-006e-4c31-a5f9-0c598a86ce8d',
            },
            {
              type: 'uuid',
              value: '820b717a-72e7-4298-a0f8-e4b33d9f366f',
            },
            {
              type: 'uuid',
              value: '64ea082f-caae-4a78-bde4-fe078e87783a',
            },
          ],
          date_range: null,
          integer_range: null,
        },
        default_value: '',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        rank: 1,
        tooltip: '',
      },
      {
        created_at: '2022-03-22T20:54:14Z',
        updated_at: '',
        credential_field_format_id: 'be5e3d19-2e65-4f0f-b8ff-d0eaa0e7457b',
        field_name: 'Valid values from format',
        display_name: 'Valid values from format',
        field_type: 'UUID',
        credential_format_config_id: 'dac4dd71-5f3f-4298-990f-c560d61cc1c7',
        bitfield_parity: null,
        bitfield_value: null,
        constraint: {
          valid_values: [
            {
              type: 'uuid',
              value: '58abfc1e-2ee9-4cd6-b819-49bfb5a433fb',
            },
            {
              type: 'uuid',
              value: 'a3d8e142-5336-4cdb-bcd7-6005ef756b5b',
            },
          ],
          date_range: null,
          integer_range: null,
        },
        default_value: '',
        is_hidden: false,
        is_optional: false,
        read_only: false,
        placeholder: '',
        rank: 2,
        tooltip: '',
      },
    ],
  },
  {
    created_at: '2022-03-22T20:54:14Z',
    updated_at: '2022-03-22T20:54:14Z',
    credential_format_config_id: '3f25f1bf-b01a-453e-835a-61a20b76b103',
    template_name: 'Byte array credential template',
    template_description: '',
    organization_id: '',
    credential_format_id: mockByteArrayCredentialFormat.credential_format_id,
    credential_format_name: mockByteArrayCredentialFormat.entity_name,
    overseer_id: mockByteArrayCredentialFormat.overseer_id,
    overseer_name: mockByteArrayCredentialFormat.overseer_name,
    credential_field_configs: [
      {
        created_at: '2022-03-22T20:54:14Z',
        updated_at: '',
        credential_field_format_id: '125e9903-bcb0-49d7-9358-832e93ee8478',
        field_name: 'No Constraint',
        display_name: 'No Constraint',
        field_type: 'BYTE_ARRAY',
        credential_format_config_id: '3f25f1bf-b01a-453e-835a-61a20b76b103',
        bitfield_parity: null,
        bitfield_value: null,
        constraint: null,
        default_value: '',
        is_hidden: true,
        is_optional: false,
        read_only: false,
        placeholder: '',
        rank: 0,
        tooltip: '',
      },
    ],
  },
]

const routeBrowse = (server: ServerWithMockCredentialTemplate) => {
  server.get(
    CREDENTIAL_TEMPLATES_ENDPOINT,
    (schema) =>
      new Response(200, undefined, schema.all('credentialTemplate').models),
  )
}

const routeRead = (server: ServerWithMockCredentialTemplate) => {
  server.get(
    `${CREDENTIAL_TEMPLATES_ENDPOINT}/:id`,
    (schema, request) =>
      schema.findBy('credentialTemplate', {
        credential_format_config_id: request.params.id,
      })?.attrs || new Response(404),
  )
}

const routeEdit = (server: ServerWithMockCredentialTemplate) => {
  server.put(
    `${CREDENTIAL_TEMPLATES_ENDPOINT}/:id`,
    async (schema, request) => {
      const update = JSON.parse(
        request.requestBody,
      ) as Partial<CredentialTemplateEditTypes>

      const credential_format_config_id = request.params.id

      const credentialTemplate = schema.findBy('credentialTemplate', {
        credential_format_config_id,
      })

      if (!credentialTemplate) {
        return new Response(404)
      }

      credentialTemplate.update({
        ...update,
        template_description: undefined,
        updated_at: getUTCTimestamp(),
        credential_field_configs: update.credential_field_configs?.map(
          (field): CredentialTemplateFieldTypes => ({
            ...field,
            credential_format_config_id,
            created_at: '',
            updated_at: '',
            is_optional: field.is_optional || false,
            is_hidden: field.is_hidden || false,
            read_only: field.read_only || false,
          }),
        ),
      })

      return credentialTemplate.attrs
    },
  )
}

const routeAdd = (server: ServerWithMockCredentialTemplate) => {
  server.post(CREDENTIAL_TEMPLATES_ENDPOINT, (schema, request) => {
    const payload = JSON.parse(
      request.requestBody,
    ) as CredentialTemplateAddTypes

    const credential_format_config_id = v4()

    const credentialTemplate: CredentialTemplateTypes = {
      ...payload,
      template_description: '',
      organization_id: '',
      created_at: getUTCTimestamp(),
      overseer_id: v4(),
      overseer_name: 'Random overseer',
      credential_format_name: 'Random credential',
      updated_at: '',
      credential_format_config_id,
      credential_field_configs: payload.credential_field_configs.map(
        (field): CredentialTemplateFieldTypes => ({
          ...field,
          credential_format_config_id,
          created_at: '',
          updated_at: '',
          is_optional: field.is_optional || false,
          is_hidden: field.is_hidden || false,
          read_only: field.read_only || false,
        }),
      ),
    }

    schema.create('credentialTemplate', { ...credentialTemplate })

    return credentialTemplate
  })
}

const routeDelete = (server: ServerWithMockCredentialTemplate) => {
  server.delete(`${CREDENTIAL_TEMPLATES_ENDPOINT}/:id`, (schema, request) => {
    const credentialTemplate = schema.findBy('credentialTemplate', {
      credential_format_config_id: request.params.id,
    })

    if (!credentialTemplate) {
      return new Response(404)
    }

    credentialTemplate.destroy()

    return new Response(204)
  })
}

const routeReadCredentialFormat = (
  server: ServerWithMockCredentialTemplate,
  credentialFormat: CredentialFormatsTypes,
) => {
  server.get(
    `${CREDENTIAL_FORMATS_ENDPOINT}/${credentialFormat.credential_format_id}`,
    () => credentialFormat,
  )
}
