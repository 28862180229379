import {
  FilterOutlined,
  Input,
  Layout,
} from '@swiftctrl/swift-component-library'
import { debounce } from 'lodash'
import { useRef } from 'react'
import styled from 'styled-components'
import { validateBrowseSearch } from '../../../utils-hooks'

interface SearchBarProps {
  onSearch: (value: string) => void
  isLocalSearch?: boolean
  extraFilter?: React.ReactNode
}

export const FiltersBar = ({
  onSearch,
  isLocalSearch = false,
  extraFilter,
}: SearchBarProps) => {
  const debouncedSearch = useRef(debounce(onSearch, 500))

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    if (isLocalSearch) {
      onSearch(value)

      return
    }

    const { isValidSearch, validatedSearch } = validateBrowseSearch(value)

    if (isValidSearch) {
      debouncedSearch.current.cancel()

      debouncedSearch.current(validatedSearch)
    }
  }

  return (
    <Layout horizontal gap="medium">
      <StyledSearch
        onChange={changeHandler}
        placeholder="Search"
        size="large"
      />
      {extraFilter && (
        <Layout horizontal alignItems="center" gap="small">
          <FilterOutlined />
          Filter:
          {extraFilter}
        </Layout>
      )}
    </Layout>
  )
}

const StyledSearch = styled(Input.Search)`
  max-width: 320px;
`
