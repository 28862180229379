import { ProfilesTypes } from '@swiftctrl/api-client'
import { FetchQueryOptions, useQuery } from '@tanstack/react-query'
import { queryClient } from '../../data/queryClient'
import {
  swiftClient,
  swiftClientWithoutErrorHandling,
} from '../../data/swiftClient'
import { adaptSDKReadRequest } from '../adaptSDKReadRequest'
import { cacheKeys } from '../cacheKeysUtils'

export const useReadProfile = (profileId: string, enabled = true) =>
  useQuery({
    ...buildOptions(profileId, true),
    enabled,
  })

const buildOptions = (
  profileId: string,
  showErrorNotification: boolean,
): FetchQueryOptions<ProfilesTypes> => {
  const client = showErrorNotification
    ? swiftClient
    : swiftClientWithoutErrorHandling

  return {
    queryKey: [cacheKeys.profile, profileId],
    queryFn: adaptSDKReadRequest(client.profile, profileId),
  }
}

export const readProfile = (profileId: string) =>
  queryClient.fetchQuery(buildOptions(profileId, false))
