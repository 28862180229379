import { RolesTypes } from '@swiftctrl/api-client'
import { useRef } from 'react'
import { swiftClientWithoutErrorHandling } from '../../../../data/swiftClient'
import { createRequestQueue, useBoolean } from '../../../../utils-hooks'

type Params = {
  onSuccess: () => void
  onError: (errors: RoleError[]) => void
}

export type RoleError = {
  role: Partial<RolesTypes>
  error: unknown
}

export const useAssignRoles = ({ onSuccess, onError }: Params) => {
  const [isPending, setPendingTrue, setPendingFalse] = useBoolean()

  const failedRequestsRoles = useRef(new Array<RoleError>())

  const { enqueueRequest } = createRequestQueue(() => {
    setPendingFalse()

    if (failedRequestsRoles.current.length === 0) {
      onSuccess()
    } else {
      onError(failedRequestsRoles.current)
    }

    failedRequestsRoles.current = []
  })

  const mutate = async (personId: string, roles: Partial<RolesTypes>[]) => {
    setPendingTrue()

    roles.forEach((role) => {
      enqueueRequest(async () => {
        try {
          await swiftClientWithoutErrorHandling.person
            .at(personId)
            .role.at(role.role_id!)
            .assign()
        } catch (error) {
          failedRequestsRoles.current.push({
            role,
            error,
          })
        }
      })
    })
  }

  return { mutate, isPending }
}
