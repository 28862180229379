import { CredentialTemplateFieldValidValue } from '@swiftctrl/api-client'
import {
  Button,
  ColumnsType,
  DeleteOutlined,
  Empty,
  Form,
  InputNumber,
  Layout,
  Modal,
  PlusOutlined,
  Table,
  Typography,
} from '@swiftctrl/swift-component-library'
import { useState } from 'react'
import { styled } from 'styled-components'
import { buildUniqueIdGenerator } from '../../../utils-hooks'

type Entry = CredentialTemplateFieldValidValue & {
  id: string
}

type CustomValidValuesEditorModalProps = {
  open: boolean
  validValues: CredentialTemplateFieldValidValue[]
  min: number | undefined
  max: number | bigint | undefined
  onClose: () => void
  onSave: (values: CredentialTemplateFieldValidValue[]) => void
}

export const CustomValidValuesEditorModal = ({
  open,
  validValues,
  min,
  max,
  onClose,
  onSave,
}: CustomValidValuesEditorModalProps) => {
  const { generateId } = buildUniqueIdGenerator()

  const initialEntries = validValues.map((value) => ({
    ...value,
    id: generateId(),
  }))

  const [entries, setEntries] = useState<Entry[]>(initialEntries)

  const handleSave = () => {
    const validValues = entries.map(
      (entry): CredentialTemplateFieldValidValue => ({
        type: entry.type,
        value: entry.value,
      }),
    )

    onSave(validValues)
  }

  const addEntry = () => {
    setEntries((previous) => {
      const update = structuredClone(previous) as Entry[]

      update.push({
        id: generateId(),
        type: 'integer',
        value: '',
      })

      return update
    })
  }

  const editEntry = (id: string, value: string) => {
    setEntries((previous) => {
      const update = structuredClone(previous) as Entry[]

      const entry = update.find((entry) => entry.id === id)!

      entry.value = Number(value)

      return update
    })
  }

  const deleteEntry = (id: string) => {
    setEntries((previous) => {
      const update = previous.filter((entry) => entry.id !== id)

      return update
    })
  }

  const columns = buildColumns(editEntry, deleteEntry, min, max, entries)

  const resetAndClose = () => {
    setEntries(initialEntries)

    onClose()
  }

  const { isValid, validationMessage } = validate(entries)

  return (
    <Modal
      title="Values"
      open={open}
      onCancel={resetAndClose}
      okText="Save"
      onOk={handleSave}
      okButtonProps={{ disabled: !isValid }}
      centered
      destroyOnClose
    >
      <Layout gap="medium">
        <StyledTable
          showHeader={false}
          dataSource={entries}
          columns={columns}
          bordered
          pagination={false}
          locale={{
            emptyText: <Empty description="No valid values" />,
          }}
          rowKey="id"
        />
        <Button
          icon={<PlusOutlined />}
          size="middle"
          type="primary"
          onClick={addEntry}
        >
          Add new value
        </Button>
        {validationMessage && (
          <Typography.Text type="danger">{validationMessage}</Typography.Text>
        )}
      </Layout>
    </Modal>
  )
}

const buildColumns = (
  editEntry: (id: string, value: string) => void,
  deleteEntry: (id: string) => void,
  min: number | undefined,
  max: number | bigint | undefined,
  entries: Entry[],
): ColumnsType<Entry> => [
  {
    render: (_, entry, index) => {
      const { id, value } = entry

      const validationMessage = validateEntry(entry, min, max, entries)

      return (
        <Layout horizontal gap="small">
          <StyledFormItem
            help={validationMessage}
            validateStatus={validationMessage ? 'error' : undefined}
          >
            <StyledInputNumber
              key={id}
              size="middle"
              value={value}
              controls={false}
              type="number"
              min={min}
              max={String(max)}
              onChange={(value) => {
                if (value === null) {
                  editEntry(id, '')
                } else {
                  editEntry(id, String(value))
                }
              }}
              autoFocus={index === entries.length - 1 && value === ''}
              stringMode
            />
          </StyledFormItem>
          <Button
            icon={<DeleteOutlined />}
            size="middle"
            danger
            type="text"
            onClick={() => deleteEntry(id)}
          />
        </Layout>
      )
    },
  },
]

const validateEntry = (
  entry: Entry,
  min: number | undefined,
  max: number | bigint | undefined,
  entries: Entry[],
) => {
  const { value } = entry

  if (!hasValue(value)) {
    return 'Value must not be empty'
  }

  if (min !== undefined && max !== undefined) {
    if (Number(value) < min || BigInt(value) > BigInt(max)) {
      return `Value must be between ${min} and ${max}`
    }
  }

  const matchingEntries = entries.filter((entry) => entry.value === value)

  if (matchingEntries.length > 1) {
    return 'Value must not be duplicated'
  }
}

const hasValue = (value: string | number) => {
  return value || value === 0
}

const StyledFormItem = styled(Form.Item)`
  width: 100%;

  margin: 0px;
`

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`

const validate = (
  entries: Entry[],
): { isValid: boolean; validationMessage: string } => {
  for (let i = 0; i < entries.length; i++) {
    const { value } = entries[i]

    if (!hasValue(value)) {
      return { isValid: false, validationMessage: '' }
    }
  }

  const uniqueValues = new Set(entries.map((entry) => entry.value))

  if (uniqueValues.size !== entries.length) {
    return { isValid: false, validationMessage: '' }
  }

  return { isValid: true, validationMessage: '' }
}

const StyledTable: typeof Table = styled(Table)`
  width: 100%;
`
