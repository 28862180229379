import { AssignedSpacesTypes, OrganizationTypes } from '@swiftctrl/api-client'
import {
  Icon,
  Layout,
  WarningOutlined,
} from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'
import {
  ManageAssignedDataModal,
  RemoteFilterBar,
} from '../../../../components'
import { FILTER_OR_INFO_BAR_HEIGHT } from '../../../../components/manage-assigned-data-modal'
import { useCheckBeforeClose } from '../../../../utils-hooks'
import { AssignedAccessLevelsTable } from './AssignedAccessLevelsTable'
import { CandidateAccessLevelsTable } from './CandidateAccessLevelsTable'
import { useConfigureAccessLevelsLogic } from './useConfigureAccessLevelsLogic'

type ConfigureAccessLevelsModalProps = {
  open: boolean
  onClose: () => void
  landlordId: string
  tenant: OrganizationTypes
  space: AssignedSpacesTypes
}

export const ConfigureAccessLevelsModal = ({
  open,
  onClose,
  landlordId,
  tenant,
  space,
}: ConfigureAccessLevelsModalProps) => {
  const {
    candidateAccessLevels,
    fetchMoreCandidateAccessLevels,
    isFetchingCandidateAccessLevels,
    isFetchingCandidateAccessLevelsFirstTime,
    assignedAccessLevels,
    fetchMoreAssignedAccessLevels,
    isFetchingAssignedAccessLevels,
    isFetchingAssignedAccessLevelsFirstTime,
    setSearch,
    addAccessLevel,
    addedAccessLevelIds,
    removeAccessLevel,
    removedAccessLevelIds,
    updateDefault,
    addedDefaults,
    removedDefaults,
    isDirty,
    reset,
    save,
    isSaving,
  } = useConfigureAccessLevelsLogic({
    open,
    landlordId,
    tenantId: tenant.organization_id,
    buildingId: space.space_id,
    close: onClose,
  })

  const { checkBeforeClose } = useCheckBeforeClose({
    isPending: isSaving,
    isDirty,
    reset,
    close: onClose,
  })

  return (
    <ManageAssignedDataModal
      open={open}
      onClose={checkBeforeClose}
      title="Configure Access Levels"
      description={`${tenant.entity_name} will be able to assign these access levels to their employees`}
      filtersBar={
        <RemoteFilterBar onDebouncedSearch={setSearch} isSaving={isSaving} />
      }
      candidatesDataTable={
        <CandidateAccessLevelsTable
          buildingName={space.name}
          onAddAccessLevel={addAccessLevel}
          removedAccessLevelIds={removedAccessLevelIds}
          data={candidateAccessLevels}
          fetchNextPage={fetchMoreCandidateAccessLevels}
          isFetching={isFetchingCandidateAccessLevels}
          isFetchingFirstTime={isFetchingCandidateAccessLevelsFirstTime}
          isSaving={isSaving}
        />
      }
      infoBar={<DummyInfoBar />}
      assignedDataTable={
        <AssignedAccessLevelsTable
          data={assignedAccessLevels}
          fetchNextPage={fetchMoreAssignedAccessLevels}
          isFetching={isFetchingAssignedAccessLevels}
          isFetchingFirstTime={isFetchingAssignedAccessLevelsFirstTime}
          addedAccessLevelIds={addedAccessLevelIds}
          onRemoveAccessLevel={removeAccessLevel}
          onDefaultChange={updateDefault}
          isSaving={isSaving}
        />
      }
      footerLeftContent={buildFooterLeftContent({
        addedCount: addedAccessLevelIds.length,
        removedCount: removedAccessLevelIds.length,
        addedDefaultCount: addedDefaults.size,
        removedDefaultCount: removedDefaults.size,
      })}
      hasUnsavedChanges={isDirty}
      onSave={save}
      isSaving={isSaving}
    />
  )
}

const DummyInfoBar = styled.div`
  height: ${FILTER_OR_INFO_BAR_HEIGHT};
`

const buildFooterLeftContent = ({
  addedCount,
  removedCount,
  addedDefaultCount,
  removedDefaultCount,
}: {
  addedCount: number
  removedCount: number
  addedDefaultCount: number
  removedDefaultCount: number
}) => {
  const tokens = new Array<string>()

  if (addedCount) {
    tokens.push(`${addedCount} added`)
  }

  if (removedCount) {
    tokens.push(`${removedCount} removed`)
  }

  if (addedDefaultCount) {
    tokens.push(`${addedDefaultCount} marked as default`)
  }

  if (removedDefaultCount) {
    tokens.push(`${removedDefaultCount} removed as default`)
  }

  if (tokens.length === 0) {
    return undefined
  }

  const label = `Unsaved changes: ${tokens.join(', ')}`

  return (
    <Layout horizontal alignItems="center" grow gap="xSmall">
      <Icon color="warning" component={() => <WarningOutlined />} />
      {label}
    </Layout>
  )
}
