import { PersonTypes, ProfilesTypes } from '@swiftctrl/api-client'
import { PreLoader, TabsProps } from '@swiftctrl/swift-component-library'
import { useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import {
  CopyableLink,
  CredentialsBrowser,
  EntityAncestryView,
  EntityMirrorsBrowser,
  EntityTimestampsDisplay,
  HeaderContent,
  HeaderContentProps,
  ReadScreen,
} from '../components'
import { getEntityLabel } from '../config/labels'
import {
  ORGANIZATIONS_SCREEN_PATH,
  PEOPLE_SCREEN_PATH,
  PERSON_SCREEN_PROFILES_TAB,
  PROFILE_SCREEN_ANCESTRY_TAB,
  PROFILE_SCREEN_CREDENTIALS_TAB,
  PROFILE_SCREEN_MIRRORS_TAB,
  PROFILE_SCREEN_PROVIDER_PROFILES_TAB,
} from '../config/paths'
import { EMPTY_ID } from '../data/models'
import { swiftClientWithoutErrorHandling } from '../data/swiftClient'
import { ProfileProviderProfilesBrowser } from '../screen-components/profile-screen'
import { adaptSDKRead, cacheKeys, useReadPerson } from '../utils-hooks'

export const profileScreenTabs = [
  PROFILE_SCREEN_MIRRORS_TAB,
  PROFILE_SCREEN_CREDENTIALS_TAB,
  PROFILE_SCREEN_PROVIDER_PROFILES_TAB,
  PROFILE_SCREEN_ANCESTRY_TAB,
] as const

type ProfileScreenTab = (typeof profileScreenTabs)[number]

type ProfileScreenProps = {
  tab?: ProfileScreenTab
}

export const ProfileScreen = ({ tab }: ProfileScreenProps) => {
  const { personId } = useParams()

  return (
    <ReadScreen<ProfilesTypes>
      paramUuidProps={[
        {
          paramKey: 'personId',
          entityType: 'person',
          redirectPath: `/${PEOPLE_SCREEN_PATH}`,
        },
        {
          paramKey: 'profileId',
          entityType: 'profile',
          redirectPath: `/${PEOPLE_SCREEN_PATH}/${personId}/${PERSON_SCREEN_PROFILES_TAB}`,
          redirectButtonLabel: 'Go to person screen',
        },
      ]}
      readRequest={adaptSDKRead(swiftClientWithoutErrorHandling.profile)}
      cacheKey={cacheKeys.profile}
      entityType="profile"
      headerContentFactory={(profile) => <ProfileHeader profile={profile} />}
      contentFactory={{
        tab,
        tabs: profileScreenTabs,
        tabPanesFactory,
        tabBarExtraContent: (profile) => (
          <EntityTimestampsDisplay
            created_at={profile.created_at!}
            updated_at={profile.updated_at!}
          />
        ),
      }}
    />
  )
}

const ProfileHeader = ({ profile }: { profile: ProfilesTypes }) => {
  const {
    display_name,
    profile_id,
    person_id,
    overseer_name,
    overseer_id,
    employee_number,
    email,
    extra_json,
  } = profile

  const { data: person } = useReadPerson(person_id)

  const headerContentProps: HeaderContentProps = {
    entityName: display_name,
    entityId: profile_id,
    entityType: 'profile',
    isBreadcrumbLoading: !person,
    breadCrumbPath: [
      {
        label: getEntityLabel({ entityType: 'person', singular: false }),
        url: `/${PEOPLE_SCREEN_PATH}`,
      },
      {
        label: person?.primary_profile.display_name || '',
        url: `/${PEOPLE_SCREEN_PATH}/${person_id}`,
      },
    ],
    infoItemsLeft: [
      {
        title: 'Organization',
        value: <OrganizationView person={person} />,
      },
      {
        title: 'Overseer',
        value: overseer_name,
        copyableText: overseer_id,
      },
      {
        title: 'Employee Number',
        value: employee_number,
        copyableText: employee_number,
      },
      {
        title: 'Email',
        value: email,
        copyableText: email,
      },
    ],
    extraJson: extra_json,
  }

  return <HeaderContent {...headerContentProps} />
}

const tabPanesFactory = (profile: ProfilesTypes) => {
  const {
    profile_id,
    display_name,
    overseer_id,
    overseer_name,
    organization_id,
  } = profile

  const tabs: TabsProps['items'] = [
    {
      key: PROFILE_SCREEN_MIRRORS_TAB,
      label: 'Mirrors',
      children: (
        <EntityMirrorsBrowser
          entity={{
            entity_id: profile_id,
            overseer_id,
            entity_type_id: 'profile',
          }}
          organizationId={organization_id}
          idToNameMap={
            new Map([
              [profile_id, display_name],
              [overseer_id, overseer_name],
            ])
          }
        />
      ),
    },
    {
      key: PROFILE_SCREEN_CREDENTIALS_TAB,
      label: 'Credentials',
      children: (
        <CredentialsBrowser
          baseId={overseer_id}
          profile={profile}
          organizationId={organization_id}
        />
      ),
    },
    {
      key: PROFILE_SCREEN_PROVIDER_PROFILES_TAB,
      label: 'Provider Profiles',
      children: (
        <ProfileProviderProfilesBrowser
          organizationId={organization_id}
          profile={profile}
        />
      ),
    },
    {
      key: PROFILE_SCREEN_ANCESTRY_TAB,
      label: 'Ancestry',
      children: (
        <EntityAncestryView
          entity={{
            ...profile,
            entity_id: profile_id,
            extra_ints: null,
            extra_strings: null,
            extra_map: null,
          }}
        />
      ),
    },
  ]

  return tabs
}

const OrganizationView = ({ person }: { person: PersonTypes | undefined }) => {
  if (!person) {
    return (
      <StyledContainer>
        <PreLoader spinProps={{ size: 'small' }} />
      </StyledContainer>
    )
  }

  const { organization_id, organization_name } = person.primary_profile

  if (organization_id === EMPTY_ID) {
    return <>-</>
  }

  const url = `/${ORGANIZATIONS_SCREEN_PATH}/${organization_id}`

  return (
    <CopyableLink
      label={organization_name}
      copyableText={organization_id}
      to={url}
    />
  )
}

const StyledContainer = styled.div`
  display: inline-flex;
`
