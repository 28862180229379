import { AccessLevelTypes } from '@swiftctrl/api-client'
import {
  Button,
  Checkbox,
  Layout,
  Typography,
} from '@swiftctrl/swift-component-library'
import { List } from 'antd'
import { styled } from 'styled-components'
import {
  ManageAssignedDataInfiniteList,
  ManageAssignedDataInfiniteListProps,
} from '../../../../components'
import { TooltipIcon } from '../../../credential-template-screen/TooltipIcon'

type AssignedAccessLevelsTableProps = Omit<
  ManageAssignedDataInfiniteListProps<Partial<AccessLevelTypes>>,
  'header' | 'renderItem'
> & {
  addedAccessLevelIds: string[]
  onRemoveAccessLevel: (accessLevel: Partial<AccessLevelTypes>) => void
  onDefaultChange: (accessLevelId: string, defaultValue: boolean) => void
  isSaving: boolean
}

const ADDED_BACKGROUND = '#00800010'

export const AssignedAccessLevelsTable = ({
  data,
  isFetching,
  isFetchingFirstTime,
  fetchNextPage,
  addedAccessLevelIds,
  onRemoveAccessLevel,
  onDefaultChange,
  isSaving,
}: AssignedAccessLevelsTableProps) => {
  return (
    <ManageAssignedDataInfiniteList
      header={
        <Layout horizontal justifyContent="space-between">
          <Typography.Text>Allocated Access Levels</Typography.Text>
          <DefaultContainer horizontal alignItems="center" gap="small">
            Default
            <TooltipIcon message="Employees who are granted access to this space will automatically receive default access levels" />
          </DefaultContainer>
        </Layout>
      }
      data={data}
      isFetching={isFetching}
      isFetchingFirstTime={isFetchingFirstTime}
      fetchNextPage={fetchNextPage}
      renderItem={(accessLevel) => (
        <StyledListItem
          key={accessLevel.access_level_id}
          $added={addedAccessLevelIds.includes(accessLevel.access_level_id!)}
        >
          <ContentContainer
            horizontal
            justifyContent="space-between"
            alignItems="center"
          >
            <Layout grow>
              {accessLevel.entity_name}
              <Typography.Text type="secondary">
                {accessLevel.entity_description}
              </Typography.Text>
            </Layout>
            <Layout horizontal gap="medium">
              <StyledCheckbox
                defaultChecked={accessLevel.is_default}
                onChange={(event) =>
                  onDefaultChange(
                    accessLevel.access_level_id!,
                    event.target.checked,
                  )
                }
                disabled={isSaving}
              />
              <Button
                type="link"
                size="small"
                danger
                onClick={() => onRemoveAccessLevel(accessLevel)}
                disabled={isSaving}
              >
                Remove
              </Button>
            </Layout>
          </ContentContainer>
        </StyledListItem>
      )}
    />
  )
}

const DefaultContainer = styled(Layout)`
  padding-right: 80px;
`

const StyledListItem = styled(List.Item)<{ $added: boolean }>`
  background: ${({ $added }) => ($added ? ADDED_BACKGROUND : undefined)};
`

const ContentContainer = styled(Layout)`
  flex: 1;
`

const StyledCheckbox = styled(Checkbox)`
  padding-right: 30px;
`
