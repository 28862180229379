import {
  CREDENTIAL_TEMPLATES_SCREEN_PATH,
  EPIS_SCREEN_PATH,
  ORGANIZATIONS_SCREEN_PATH,
  ORGANIZATION_SCREEN_SINKS_PATH,
  PEOPLE_SCREEN_PATH,
  PERSON_SCREEN_PROFILES_TAB,
  PROVIDERS_SCREEN_PATH,
  ROLES_SCREEN_PATH,
} from '../config/paths'
import { EntityType } from '../data/models'
import { getOverseerOrganization } from './useGetOverseerOrganization'
import { readProfile } from './web'

type EntityTypeToBuildPathConfig = {
  [entityType in EntityType]?: (entityId: string) => string | Promise<string>
}

const entityTypeToBuildPathConfig: EntityTypeToBuildPathConfig = {
  credential_template: (credentialTemplateId) =>
    `/${CREDENTIAL_TEMPLATES_SCREEN_PATH}/${credentialTemplateId}`,
  epi: (epiId) => `/${EPIS_SCREEN_PATH}/${epiId}`,
  organization: (organizationId) =>
    `/${ORGANIZATIONS_SCREEN_PATH}/${organizationId}`,
  person: (personId) => `${PEOPLE_SCREEN_PATH}/${personId}`,
  profile: async (profileId) => {
    const profile = await readProfile(profileId)

    const path = `/${PEOPLE_SCREEN_PATH}/${profile.person_id}/${PERSON_SCREEN_PROFILES_TAB}/${profileId}`

    return path
  },
  provider: (providerId) => `/${PROVIDERS_SCREEN_PATH}/${providerId}`,
  role: (roleId) => `/${ROLES_SCREEN_PATH}/${roleId}`,
  sink: async (sinkId) => {
    const overseerOrganization = await getOverseerOrganization(sinkId)

    const path = `/${ORGANIZATIONS_SCREEN_PATH}/${overseerOrganization.entity_id}/${ORGANIZATION_SCREEN_SINKS_PATH}/${sinkId}`

    return path
  },
}

export const hasReadEntityScreenPath = (entityType: EntityType) =>
  entityType in entityTypeToBuildPathConfig

export const buildReadEntityScreenPath = async (
  entityId: string,
  entityType: EntityType,
) => {
  const buildPath = entityTypeToBuildPathConfig[entityType]

  if (!buildPath) {
    throw new Error(
      `buildReadEntityScreenPath called for ${entityType} ${entityId} before checking hasReadEntityScreenPath`,
    )
  }

  const path = await buildPath(entityId)

  return path
}
