import { RoleArchetype, RolesTypes } from '@swiftctrl/api-client'
import {
  ColumnsType,
  Layout,
  Table,
  Tooltip,
} from '@swiftctrl/swift-component-library'
import {
  archetypePluralLabelConfig,
  getPluralLabelByCount,
  useBoolean,
} from '../../../../utils-hooks'
import { ManageSubdataButton } from '../../ManageSubdataButton'
import { ManageArchetypesModal } from '../manage'
import { RoleArchetypeView } from './RoleArchetypeView'
import { useGetRoleArchetypeCandidates } from './useGetRoleArchetypeCandidates'

interface RoleArchetypesViewProps {
  role: RolesTypes
}

export const RoleArchetypesView = ({ role }: RoleArchetypesViewProps) => {
  const [isDrawerOpen, openDrawer, closeDrawer] = useBoolean()

  const { archetypes = [] } = role

  const total = `Total: ${archetypes.length} ${getPluralLabelByCount({
    labels: archetypePluralLabelConfig,
    count: archetypes.length,
    capitalize: true,
  })}`

  const {
    data: roleArchetypeCandidates,
    error: getRoleArchetypeCandidatesError,
  } = useGetRoleArchetypeCandidates(isDrawerOpen)

  const dataSource = archetypes.map((archetype) => ({ archetype }))

  return (
    <Layout gap="medium" grow>
      <Layout horizontal justifyContent="flex-end">
        <Tooltip
          title={
            getRoleArchetypeCandidatesError
              ? `Error while fetching role archetype candidates: ${getRoleArchetypeCandidatesError}`
              : null
          }
        >
          <ManageSubdataButton
            label="Manage Archetypes"
            onClick={openDrawer}
            loading={isDrawerOpen && !roleArchetypeCandidates}
            enabled={!getRoleArchetypeCandidatesError}
          />
        </Tooltip>
        {roleArchetypeCandidates && (
          <ManageArchetypesModal
            open={isDrawerOpen}
            onClose={closeDrawer}
            role={role}
            roleArchetypeCandidates={roleArchetypeCandidates}
          />
        )}
      </Layout>
      <Table
        columns={getColumns(role)}
        dataSource={dataSource}
        size="small"
        pagination={false}
        bordered
        rowKey="archetype"
      />
      <Layout alignItems="end">{total}</Layout>
    </Layout>
  )
}

const getColumns = (role: RolesTypes) => {
  const columns: ColumnsType<{ archetype: RoleArchetype }> = [
    {
      title: 'Archetype',
      render: (_, { archetype }) => (
        <RoleArchetypeView roleArchetype={archetype} role={role} />
      ),
    },
  ]

  return columns
}
