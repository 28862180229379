import { ProfilesTypes, ProviderType } from '@swiftctrl/api-client'
import {
  DatePicker,
  Drawer,
  Form,
  Input,
} from '@swiftctrl/swift-component-library'
import { AutoComplete } from 'antd'
import {
  INVALID_EMAIL_FORMAT_MESSAGE,
  INVALID_PASSWORD_MESSAGES,
  VALID_EMAIL_FORMAT,
  VALID_PASSWORD,
} from '../../../../data/models'
import {
  buildCommonDrawerProps,
  buildCommonFormItemProps,
  buildCommonFormProps,
} from '../../../../utils-hooks'
import { AddFormButtonGroup } from '../../../AddFormButtonGroup'
import { BackendErrorAlert } from '../../../BackendErrorAlert'
import { OrganizationProviderSelect } from './OrganizationProviderSelect'
import { useAddProviderProfileLogic } from './useAddProviderProfileLogic'

type AddProviderProfileViewProps = {
  organizationId: string
  profile: ProfilesTypes
  title?: string
  open: boolean
  onClose: () => void
}

export const AddProviderProfileView = ({
  organizationId,
  profile,
  title = 'Add provider profile',
  open,
  onClose,
}: AddProviderProfileViewProps) => {
  const {
    values,
    setValues,
    error,
    checkBeforeClose,
    handleFinish,
    isPending,
    isValid,
    providerType,
    profileEmail,
  } = useAddProviderProfileLogic(profile, onClose)

  const loginDataAutocompleteOptions = profileEmail
    ? [{ value: profileEmail, label: profileEmail }]
    : undefined

  return (
    <Drawer
      {...buildCommonDrawerProps({
        title,
        open,
        onClose: checkBeforeClose,
        isPending,
      })}
    >
      <Form
        {...buildCommonFormProps({
          values,
          setValues,
          isPending,
          onFinish: handleFinish,
        })}
      >
        <Form.Item
          {...buildCommonFormItemProps({
            name: 'provider',
            label: 'Provider',
            required: true,
          })}
        >
          <OrganizationProviderSelect organizationId={organizationId} />
        </Form.Item>
        {isProviderOfType(providerType, 'email_password') && (
          <>
            <Form.Item
              {...buildCommonFormItemProps({
                name: 'login_data.email_password.email',
                label: 'Email',
                required: true,
                rules: [
                  {
                    pattern: VALID_EMAIL_FORMAT,
                    message: INVALID_EMAIL_FORMAT_MESSAGE,
                  },
                ],
              })}
            >
              <AutoComplete
                options={loginDataAutocompleteOptions}
                size="large"
              />
            </Form.Item>
            <Form.Item
              {...buildCommonFormItemProps({
                name: 'login_data.email_password.password',
                label: 'Password',
                required: true,
                rules: [
                  {
                    validator: (_, value) => {
                      if (
                        value &&
                        value.trim().length < VALID_PASSWORD.MIN_LENGTH
                      ) {
                        return Promise.reject(
                          INVALID_PASSWORD_MESSAGES.MIN_LENGTH,
                        )
                      }

                      return Promise.resolve()
                    },
                  },
                  {
                    max: VALID_PASSWORD.MAX_LENGTH,
                    message: INVALID_PASSWORD_MESSAGES.MAX_LENGTH,
                  },
                  {
                    pattern: VALID_PASSWORD.UPPERCASE,
                    message: INVALID_PASSWORD_MESSAGES.UPPERCASE,
                  },
                  {
                    pattern: VALID_PASSWORD.LOWERCASE,
                    message: INVALID_PASSWORD_MESSAGES.LOWERCASE,
                  },
                  {
                    pattern: VALID_PASSWORD.NUMBER_OR_SPECIAL_CHARACTER,
                    message:
                      INVALID_PASSWORD_MESSAGES.NUMBER_OR_SPECIAL_CHARACTER,
                  },
                ],
              })}
            >
              <Input />
            </Form.Item>
          </>
        )}
        {isProviderOfType(providerType, 'openid', 'openid_okta') && (
          <Form.Item
            {...buildCommonFormItemProps({
              name: 'login_data.openid.sub',
              label: 'Sub',
              required: true,
            })}
          >
            <AutoComplete options={loginDataAutocompleteOptions} size="large" />
          </Form.Item>
        )}
        {isProviderOfType(providerType, 'single_use_token') && (
          <>
            <Form.Item
              {...buildCommonFormItemProps({
                name: 'login_data.single_use_token.token',
                label: 'Token',
                required: true,
              })}
            >
              <Input />
            </Form.Item>
            <Form.Item
              {...buildCommonFormItemProps({
                name: 'login_data.single_use_token.expiry',
                label: 'Expiry',
                required: true,
              })}
            >
              <DatePicker showTime />
            </Form.Item>
          </>
        )}
        {isProviderOfType(providerType, 'proxy_login') && (
          <Form.Item
            {...buildCommonFormItemProps({
              name: 'login_data.proxy_login.identifier',
              label: 'Identifier',
              required: true,
            })}
          >
            <AutoComplete options={loginDataAutocompleteOptions} size="large" />
          </Form.Item>
        )}
        {error && (
          <Form.Item>
            <BackendErrorAlert error={error} />
          </Form.Item>
        )}
        <AddFormButtonGroup
          onClose={checkBeforeClose}
          isValid={isValid}
          isPending={isPending}
        />
      </Form>
    </Drawer>
  )
}

const isProviderOfType = (
  providerType: ProviderType | undefined,
  ...types: ProviderType[]
) => {
  if (!providerType) {
    return false
  }

  return types.includes(providerType)
}
