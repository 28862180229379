import { CredentialTemplateTypes } from '@swiftctrl/api-client'
import { FetchQueryOptions, useQuery } from '@tanstack/react-query'
import { queryClient } from '../../data/queryClient'
import {
  swiftClient,
  swiftClientWithoutErrorHandling,
} from '../../data/swiftClient'
import { adaptSDKReadRequest } from '../adaptSDKReadRequest'
import { cacheKeys } from '../cacheKeysUtils'

type Params = {
  id: string
  enabled?: boolean
  showErrorNotification: boolean
}

export const useReadCredentialTemplate = ({
  id,
  enabled,
  showErrorNotification,
}: Params) =>
  useQuery({
    ...buildOptions(id, showErrorNotification),
    enabled,
  })

const buildOptions = (
  id: string,
  showErrorNotification: boolean,
): FetchQueryOptions<CredentialTemplateTypes> => {
  const client = showErrorNotification
    ? swiftClient
    : swiftClientWithoutErrorHandling

  return {
    queryKey: [cacheKeys.credential_template, id],
    queryFn: adaptSDKReadRequest(client.credentialTemplate, id),
  }
}

export const readCredentialTemplate = (id: string) =>
  queryClient.fetchQuery(buildOptions(id, false))
