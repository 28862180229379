import { PersonTypes, RolesTypes } from '@swiftctrl/api-client'
import { useState } from 'react'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../utils-hooks'
import { invalidatePersonRoleQueries } from '../invalidatePersonRoleQueries'
import { RoleError, useAssignRoles } from './useAssignRoles'
import { useInfiniteBrowseCandidateRoles } from './useInfiniteBrowseCandidateRoles'

type Params = {
  person: PersonTypes
  open: boolean
  onClose: () => void
}

export const useAssignPersonRolesLogic = ({
  person,
  open,
  onClose,
}: Params) => {
  const [search, setSearch] = useState('')

  const {
    candidateRoles,
    fetchMoreCandidateRoles,
    isFetchingCandidateRoles,
    isFetchingCandidateRolesFirstTime,
  } = useInfiniteBrowseCandidateRoles({
    organizationId: person.primary_profile.organization_id,
    search,
    enabled: open,
  })

  const [selectedRoles, setSelectedRoles] = useState(
    new Array<Partial<RolesTypes>>(),
  )

  const addRole = (role: Partial<RolesTypes>) => {
    setSelectedRoles((previous) => {
      const existingEntry = previous.find(
        (entry) => entry.role_id === role.role_id,
      )

      if (existingEntry) {
        return previous
      }

      const update = [role, ...previous]

      return update
    })
  }

  const removeRole = (role: Partial<RolesTypes>) => {
    setSelectedRoles((previous) => {
      const existingEntryIndex = previous.findIndex(
        (entry) => entry.role_id === role.role_id,
      )

      if (existingEntryIndex === -1) {
        return previous
      }

      const update = [...previous]

      update.splice(existingEntryIndex, 1)

      return update
    })
  }

  const removeAllRoles = () => {
    setSelectedRoles([])
  }

  const isDirty = selectedRoles.length > 0

  const reset = () => {
    removeAllRoles()

    setSearch('')
  }

  const { mutate, isPending } = useAssignRoles({
    onSuccess: () => {
      showSuccessNotification('Roles assigned')

      invalidatePersonRoleQueries()

      removeAllRoles()

      onClose()
    },
    onError: (errors: RoleError[]) => {
      errors.forEach((entry) => {
        const { role, error } = entry

        showErrorNotification(
          `Error while assigning ${role.entity_name}`,
          error,
        )
      })
    },
  })

  const save = () => {
    mutate(person.person_id, selectedRoles)
  }

  return {
    candidateRoles,
    fetchMoreCandidateRoles,
    isFetchingCandidateRoles,
    isFetchingCandidateRolesFirstTime,
    selectedRoles,
    setSearch,
    addRole,
    removeRole,
    removeAllRoles,
    isDirty,
    reset,
    save,
    isSaving: isPending,
  }
}
