import { RolesTypes } from '@swiftctrl/api-client'
import { Layout, PreLoader, Table } from '@swiftctrl/swift-component-library'
import {
  getPluralLabelByCount,
  targetPluralLabelConfig,
  useBoolean,
} from '../../../../utils-hooks'
import { ManageSubdataButton } from '../../ManageSubdataButton'
import { ManageTargetsModal } from '../manage'
import { useGetRoleTargets } from '../useGetRoleTargets'
import { buildColumns } from './buildColumns'

type RoleTargetsViewProps = {
  role: RolesTypes
}

export const RoleTargetsView = ({ role }: RoleTargetsViewProps) => {
  const { role_id, granted_on } = role

  const { error, data: targets } = useGetRoleTargets(role)

  const columns = buildColumns(role_id)

  const total = `Total: ${granted_on?.length || 0} ${getPluralLabelByCount({
    labels: targetPluralLabelConfig,
    count: granted_on?.length || 0,
    capitalize: true,
  })}`

  const [isManagerOpen, openManager, closeManager] = useBoolean()

  return (
    <Layout gap="medium" grow>
      <Layout horizontal justifyContent="end">
        <ManageSubdataButton
          label="Manage Targets"
          onClick={openManager}
          enabled={Boolean(targets)}
        />
        {targets && (
          <ManageTargetsModal
            role={role}
            targets={targets}
            open={isManagerOpen}
            onClose={closeManager}
            key={targets.join('|')}
          />
        )}
      </Layout>
      {error ? (
        <p>{String(error)}</p>
      ) : !targets ? (
        <PreLoader centered />
      ) : (
        <>
          <Table
            columns={columns}
            dataSource={targets}
            size="small"
            pagination={false}
            rowKey="entity_id"
            bordered
          />
          <Layout alignItems="end">{total}</Layout>
        </>
      )}
    </Layout>
  )
}
