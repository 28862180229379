import {
  CredentialFieldConstraint,
  CredentialFieldIntegerRange,
} from '@swiftctrl/api-client'
import { Layout } from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'
import {
  getBitfieldValueDerivedMax,
  getIntegerRangeStartEnd,
} from '../../../components'
import { BitfieldValue, Constraint, IntegerRange } from '../utils'

export const IntegerConstraintContainer = styled(Layout)``

IntegerConstraintContainer.defaultProps = {
  horizontal: true,
  alignItems: 'center',
  gap: 'medium',
}

export const getCredentialTemplateFieldNumberInputMin = (
  constraint: Constraint | null,
  bitfieldValue: BitfieldValue | null,
) => {
  if (constraint?.integer_range) {
    return constraint.integer_range.start
  }

  if (bitfieldValue) {
    return getBitfieldValueMin(bitfieldValue)
  }

  return undefined
}

export const getBitfieldValueMin = (bitfieldValue: BitfieldValue) => {
  const { start_bit, end_bit } = bitfieldValue

  if (start_bit === undefined || end_bit === undefined) {
    return undefined
  }

  return 0
}

export const getCredentialTemplateFieldNumberInputMax = (
  constraint: Constraint | null,
  bitfieldValue: BitfieldValue | null,
) => {
  if (constraint?.integer_range) {
    return constraint.integer_range.end
  }

  if (bitfieldValue) {
    return getBitfieldValueMax(bitfieldValue)
  }

  return undefined
}

export const getBitfieldValueMax = (bitfieldValue: BitfieldValue) => {
  const { start_bit, end_bit } = bitfieldValue

  if (start_bit === undefined || end_bit === undefined) {
    return undefined
  }

  return getBitfieldValueDerivedMax(start_bit, end_bit)
}

export const getMinMaxFromFormatIntegerRangeWithTemplateBitfieldValue = (
  formatIntegerRange: CredentialFieldIntegerRange,
  templateBitfieldValue: BitfieldValue,
) => {
  const bitfieldValueMin = getBitfieldValueMin(templateBitfieldValue)

  const bitfieldValueMax = getBitfieldValueMax(templateBitfieldValue)

  const { start, end } = getIntegerRangeStartEnd(formatIntegerRange)

  const min =
    bitfieldValueMin === undefined ? start : Math.max(bitfieldValueMin, start)

  const max = bitfieldValueMax === undefined ? end : bitfieldValueMax

  const constraintFromFormat = min === start || max === end

  const constraintFromTemplate =
    min === bitfieldValueMin || max === bitfieldValueMax

  return {
    min,
    max,
    constraintFromFormat,
    constraintFromTemplate,
  }
}

export const buildInitialIntegerRange = (
  templateConstraint: Constraint | null,
  templateBitfieldValue: BitfieldValue | null | undefined,
  formatConstraint: CredentialFieldConstraint | undefined,
): IntegerRange => {
  if (templateConstraint?.integer_range) {
    return {
      start: templateConstraint.integer_range.start,
      end: templateConstraint.integer_range.end,
    }
  }

  const bitfieldValueMin = templateBitfieldValue
    ? getBitfieldValueMin(templateBitfieldValue)
    : undefined

  const bitfieldValueMax = templateBitfieldValue
    ? getBitfieldValueMax(templateBitfieldValue)
    : undefined

  if (!formatConstraint || formatConstraint.type !== 'integer_range') {
    return { start: bitfieldValueMin, end: bitfieldValueMax }
  }

  const { start, end } = getIntegerRangeStartEnd(
    formatConstraint.value as CredentialFieldIntegerRange,
  )

  const min =
    bitfieldValueMin === undefined ? start : Math.max(bitfieldValueMin, start)

  const max = bitfieldValueMax === undefined ? end : bitfieldValueMax

  return { start: min, end: max }
}
