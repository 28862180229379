import { FilterOutlined } from '@ant-design/icons'
import { Layout } from '@swiftctrl/swift-component-library'

export const EntitiesBrowserFilterLabel = () => {
  return (
    <Layout horizontal alignItems="center" gap="xSmall">
      <FilterOutlined />
      Filters:
    </Layout>
  )
}
