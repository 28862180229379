import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  InputProps,
  Select,
  Text,
  Typography,
} from '@swiftctrl/swift-component-library'
import { RuleObject } from 'antd/lib/form'
import {
  buildCommonFormItemProps,
  commonFilterOption,
  FieldConfig,
  FieldInputType,
  ValidationRule,
} from '../../utils-hooks'
import { buildFieldInputErrorProps } from '../../utils-hooks/add-entity-subtree-utils'
import { PhoneInput } from '../phone-input'
import { DurationPicker } from './DurationPicker'
import { EntitySelect } from './EntitySelect'
import { EpiConfigButton } from './EpiConfigButton'
import { EpiSubtypeSelect } from './EpiSubtypeSelect'
import { EpiTypeSelect } from './EpiTypeSelect'
import { FieldMappingsButton } from './FieldMappingsButton'
import { OrganizationSelect } from './OrganizationSelect'
import { ProfileSelect } from './ProfileSelect'
import { ProviderProfileLoginDataInput } from './ProviderProfileLoginDataInput'
import { SourceOwnerSelect } from './SourceOwnerSelect'

type FieldInputProps<T> = {
  field: FieldConfig<T>
  selectBaseId: string
  disabled?: boolean
  value?: any
  onChange: (value: any) => void
  size?: InputProps['size']
  errorMessages?: string[]
  operation: 'add' | 'edit'
}

export const FieldInput = <T,>(props: FieldInputProps<T>) => {
  const {
    field: { title },
  } = props

  return (
    <>
      {title && (
        <Typography.Paragraph>
          <Text size="20px">{title}</Text>
        </Typography.Paragraph>
      )}
      <Content {...props} />
    </>
  )
}

const Content = <T,>({
  field: {
    label,
    labelExtra,
    inputType,
    entitySelectBaseIdKey,
    placeholder,
    rules,
    title,
    ...fieldConfig
  },
  selectBaseId,
  disabled,
  value,
  onChange,
  size = 'middle',
  errorMessages,
  operation,
}: FieldInputProps<T>) => {
  const key = String(fieldConfig.key)

  const errorProps = buildFieldInputErrorProps(errorMessages)

  const formItemProps = buildCommonFormItemProps({
    name: key,
    label: labelExtra ? `${label} (${labelExtra})` : label,
    requiredMark: 'optional',
    required: !fieldConfig.optional,
    ...errorProps,
  })

  formItemProps.rules = buildRules(
    rules,
    inputType,
    fieldConfig.editable,
    operation,
  )

  if (!inputType || inputType === 'text') {
    return (
      <Form.Item {...formItemProps}>
        <Input
          onChange={(e) => onChange(e.target.value)}
          disabled={disabled}
          type={inputType}
          size={size}
          placeholder={placeholder}
        />
      </Form.Item>
    )
  }

  if (inputType === 'textArea') {
    return (
      <Form.Item {...formItemProps}>
        <Input.TextArea
          onChange={(e) => onChange(e.target.value)}
          disabled={disabled}
          rows={4}
          size={size}
          placeholder={placeholder}
        />
      </Form.Item>
    )
  }

  if (inputType === 'number') {
    return (
      <Form.Item {...formItemProps}>
        <InputNumber
          onChange={(value) => onChange(value)}
          disabled={disabled}
          type="number"
          size={size}
          placeholder={placeholder}
          style={{ width: '100%' }}
          controls={false}
        />
      </Form.Item>
    )
  }

  if (inputType === 'select') {
    return (
      <Form.Item {...formItemProps}>
        <Select
          onChange={onChange}
          disabled={disabled}
          style={{ width: '100%' }}
          mode={fieldConfig.selectMultiple ? 'multiple' : undefined}
          showSearch
          size={size}
          placeholder={placeholder}
          showLabel={false}
          options={fieldConfig.selectOptions?.map(({ key, label }) => ({
            value: key,
            label,
          }))}
          filterOption={commonFilterOption}
        />
      </Form.Item>
    )
  }

  if (inputType === 'boolean') {
    return (
      <Form.Item name={key} valuePropName="checked" {...errorProps}>
        <Checkbox
          onChange={(e) => onChange(e.target.checked)}
          disabled={disabled}
        >
          <Text size="16px">{label}</Text>
        </Checkbox>
      </Form.Item>
    )
  }

  if (inputType === 'datetime') {
    return (
      <Form.Item {...formItemProps}>
        <DatePicker
          showTime
          defaultValue={value ? new Date(value) : undefined}
          onOk={onChange}
          disabled={disabled}
          size={size}
          placeholder={placeholder}
        />
      </Form.Item>
    )
  }

  if (inputType === 'duration') {
    return (
      <Form.Item {...formItemProps}>
        <DurationPicker
          onOk={onChange}
          disabled={disabled}
          size={size}
          placeholder={placeholder}
        />
      </Form.Item>
    )
  }

  if (inputType === 'phone') {
    return (
      <Form.Item {...formItemProps}>
        <PhoneInput
          international
          value={value}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
        />
      </Form.Item>
    )
  }

  if (inputType === 'entitySelect') {
    return (
      <Form.Item {...formItemProps}>
        <EntitySelect
          baseId={selectBaseId}
          entityType={fieldConfig.entitySelectType}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
          size={size}
          displayEntityType={fieldConfig.entitySelectDisplayEntityType}
        />
      </Form.Item>
    )
  }

  if (inputType === 'organizationSelect') {
    return (
      <Form.Item {...formItemProps}>
        <OrganizationSelect
          baseId={selectBaseId}
          onChange={onChange}
          disabled={disabled}
          size={size}
        />
      </Form.Item>
    )
  }

  if (inputType === 'profileSelect') {
    return (
      <Form.Item {...formItemProps}>
        <ProfileSelect
          baseId={selectBaseId}
          onProfileIdSelected={onChange}
          disabled={disabled}
          size={size}
        />
      </Form.Item>
    )
  }

  if (inputType === 'epiTypeSelect') {
    return (
      <Form.Item {...formItemProps}>
        <EpiTypeSelect
          value={value}
          onChange={onChange}
          disabled={disabled}
          size={size}
        />
      </Form.Item>
    )
  }

  if (inputType === 'epiSubtypeSelect') {
    return (
      <Form.Item {...formItemProps}>
        <EpiSubtypeSelect
          value={value}
          onChange={onChange}
          disabled={disabled}
          size={size}
        />
      </Form.Item>
    )
  }

  if (inputType === 'fieldMappings') {
    return (
      <Form.Item {...formItemProps}>
        <FieldMappingsButton
          value={value}
          onChange={onChange}
          disabled={disabled}
          size={size}
        />
      </Form.Item>
    )
  }

  if (inputType === 'epiConfig') {
    return (
      <Form.Item {...formItemProps}>
        <EpiConfigButton
          value={value}
          onChange={onChange}
          disabled={disabled}
          size={size}
        />
      </Form.Item>
    )
  }

  if (inputType === 'sourceOwnerSelect') {
    return (
      <Form.Item {...formItemProps}>
        <SourceOwnerSelect
          onChange={onChange}
          disabled={disabled}
          size={size}
        />
      </Form.Item>
    )
  }

  if (inputType === 'providerProfileLoginDataInput') {
    return (
      <Form.Item {...formItemProps}>
        <ProviderProfileLoginDataInput
          value={value}
          onChange={onChange}
          disabled={disabled}
          size={size}
          placeholder={placeholder}
        />
      </Form.Item>
    )
  }

  return (
    <>
      <p>Field input type {inputType} isn't supported</p>
      <p>
        This is a developer error - please let your friendly ConfigCloud team
        know about this 🧑‍💻
      </p>
    </>
  )
}

const buildRules = (
  rules: ValidationRule[] | undefined,
  inputType: FieldInputType | undefined,
  editable = true,
  operation: 'add' | 'edit',
) => {
  if (operation === 'edit' && !editable) {
    return undefined
  }

  if (!rules) {
    return undefined
  }

  const transformedRules = rules.map((rule) => transformRule(rule, inputType))

  return transformedRules
}

const transformRule = (
  validationRule: ValidationRule,
  inputType: FieldInputType | undefined,
) => {
  const { message, max, min, minLength, maxLength, pattern, validator } =
    validationRule

  const rule: RuleObject = {
    message,
    max: max || maxLength,
    min: min || minLength,
    pattern,
    type: getRuleType(inputType),
  }

  if (validator) {
    rule.validator = (_, value) => {
      const valid = validator(value)

      if (valid) {
        return Promise.resolve()
      }

      return Promise.reject()
    }
  }

  return rule
}

const getRuleType = (
  inputType: FieldInputType | undefined,
): RuleObject['type'] => {
  switch (inputType) {
    case 'number':
      return 'number'

    default:
      return 'string'
  }
}
