import { Drawer, Form, PreLoader } from '@swiftctrl/swift-component-library'
import {
  buildCommonDrawerProps,
  buildCommonFormItemProps,
  buildCommonFormProps,
} from '../../../../../utils-hooks'
import { AddFormButtonGroup } from '../../../../AddFormButtonGroup'
import { ErrorAlert } from '../../../../ErrorAlert'
import { SINK_TEMPLATE_ID_FIELD_NAME } from './addSinkUtils'
import { SinkTemplateSelect } from './SinkTemplateSelect'
import { SinkTemplateVariableField } from './SinkTemplateVariableField'
import { useAddSinkLogic } from './useAddSinkLogic'

type AddSinkViewProps = {
  open: boolean
  sourceId: string
  onClose: () => void
}

export const AddSinkView = ({ open, sourceId, onClose }: AddSinkViewProps) => {
  const {
    values,
    setValues,
    isValid,
    checkBeforeClose,
    isReadingSinkTemplate,
    sinkTemplateVariables,
    readSinkTemplateError,
    addSink,
    isAddingSink,
    addSinkError,
  } = useAddSinkLogic({
    sourceId,
    onClose,
  })

  return (
    <Drawer
      {...buildCommonDrawerProps({
        title: 'Add sink from template',
        open,
        onClose: checkBeforeClose,
        isPending: isAddingSink,
        width: 500,
      })}
    >
      <Form
        {...buildCommonFormProps({
          values,
          setValues,
          isPending: isAddingSink,
          onFinish: addSink,
        })}
      >
        <Form.Item
          {...buildCommonFormItemProps({
            name: SINK_TEMPLATE_ID_FIELD_NAME,
            label: 'Sink template',
            required: true,
          })}
        >
          <SinkTemplateSelect />
        </Form.Item>
        {isReadingSinkTemplate ? (
          <PreLoader spinProps={{ size: 'small' }} />
        ) : readSinkTemplateError ? (
          <Form.Item>
            <ErrorAlert error={readSinkTemplateError} />
          </Form.Item>
        ) : (
          <>
            {sinkTemplateVariables?.map((variable) => (
              <SinkTemplateVariableField
                key={variable.name}
                variable={variable}
              />
            ))}
          </>
        )}
        {addSinkError && (
          <Form.Item>
            <ErrorAlert error={addSinkError} />
          </Form.Item>
        )}
        <AddFormButtonGroup
          onClose={checkBeforeClose}
          isValid={isValid}
          isPending={isAddingSink}
        />
      </Form>
    </Drawer>
  )
}
