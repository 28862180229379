import { OrganizationTypes, ProfilesTypes } from '@swiftctrl/api-client'
import { Layout, Typography } from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'
import {
  EntityCard,
  FavoriteOrganizationButton,
  LabelDisplay,
  ProfileDisplay,
} from '../../components'
import { ORGANIZATIONS_SCREEN_PATH } from '../../config/paths'
import { EMPTY_ID } from '../../data/models'

type OrganizationCardProps = {
  organization: OrganizationTypes
  profile: Partial<ProfilesTypes>
}

export const OrganizationCard = ({
  organization,
  profile,
}: OrganizationCardProps) => {
  const path = `/${ORGANIZATIONS_SCREEN_PATH}/${organization.organization_id}`

  return (
    <EntityCard
      detailsNavigatePath={path}
      entity={organization}
      extraTopRightButton={
        <StyledFavoriteOrganizationButton organization={organization} />
      }
    >
      {profile.profile_id !== EMPTY_ID && (
        <Typography.Paragraph>
          <Layout horizontal gap="xSmall" grow paddingTop="xSmall">
            <LabelDisplay label="Profile" />
            <Layout horizontal grow>
              <ProfileDisplay profile={profile} />
            </Layout>
          </Layout>
        </Typography.Paragraph>
      )}
    </EntityCard>
  )
}

const StyledFavoriteOrganizationButton = styled(FavoriteOrganizationButton)`
  margin: auto;
`
