import { Drawer, Form, Input } from '@swiftctrl/swift-component-library'
import { Radio } from 'antd'
import { useState } from 'react'
import { getEntityLabel } from '../../../../config/labels'
import { queryClient } from '../../../../data/queryClient'
import { BackendError } from '../../../../data/swiftClient'
import {
  buildCommonDrawerProps,
  buildCommonFormItemProps,
  buildCommonFormProps,
  cacheKeys,
  hasSomeValue,
  useCheckBeforeClose,
} from '../../../../utils-hooks'
import { EntitySelect } from '../../../add-entities'
import { AddFormButtonGroup } from '../../../AddFormButtonGroup'
import { BackendErrorAlert } from '../../../BackendErrorAlert'
import { AddEntityMirrorEntity, AddEntityMirrorFormData } from './models'
import { useAddEntityMirror } from './useAddEntityMirror'
import { validateAddEntityMirrorFormData } from './validateAddEntityMirrorFormData'

type AddEntityMirrorViewProps = {
  open: boolean
  onClose: () => void
  entity: AddEntityMirrorEntity
  organizationId: string
}

export const AddEntityMirrorView = ({
  open,
  onClose,
  entity,
  organizationId,
}: AddEntityMirrorViewProps) => {
  const [values, setValues] = useState<AddEntityMirrorFormData>({})

  const [addError, setAddError] = useState<BackendError | undefined>()

  const { mutate, isPending } = useAddEntityMirror({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [cacheKeys.entity_mirrors] })

      reset()

      onClose()
    },
    onError: setAddError,
  })

  const reset = () => {
    setValues({})

    setAddError(undefined)
  }

  const isDirty = hasSomeValue(values)

  const { checkBeforeClose } = useCheckBeforeClose({
    isPending,
    isDirty,
    close: onClose,
    reset,
  })

  const isValid = validateAddEntityMirrorFormData(values)

  const handleFinish = (values: AddEntityMirrorFormData) => {
    setAddError(undefined)

    mutate({ values, entity })
  }

  return (
    <Drawer
      {...buildCommonDrawerProps({
        title: 'Add entity mirror',
        open,
        onClose: checkBeforeClose,
        isPending,
      })}
    >
      <Form
        {...buildCommonFormProps({
          values,
          setValues,
          isPending,
          onFinish: handleFinish,
        })}
      >
        <Form.Item
          {...buildCommonFormItemProps({
            name: 'sink',
            label: 'Sink',
            required: true,
          })}
        >
          <EntitySelect
            baseId={organizationId}
            entityType="sink"
            size="large"
          />
        </Form.Item>
        <Form.Item
          {...buildCommonFormItemProps({
            name: 'setEntityAs',
            label: `Set current ${getEntityLabel({
              entityType: entity.entity_type_id!,
              capitalize: false,
            })} as`,
            required: true,
          })}
        >
          <Radio.Group>
            <Radio value="entity">Entity</Radio>
            <Radio value="mirror">Mirror</Radio>
          </Radio.Group>
        </Form.Item>
        {values.setEntityAs === 'entity' && (
          <>
            <Form.Item
              {...buildCommonFormItemProps({
                name: 'mirror_id',
                label: 'Mirror ID',
                required: true,
              })}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              {...buildCommonFormItemProps({
                name: 'mirror_overseer_id',
                label: 'Mirror overseer ID',
                required: true,
              })}
            >
              <Input size="large" />
            </Form.Item>
          </>
        )}
        {values.setEntityAs === 'mirror' && (
          <>
            <Form.Item
              {...buildCommonFormItemProps({
                name: 'entity_id',
                label: 'Entity ID',
                required: true,
              })}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              {...buildCommonFormItemProps({
                name: 'entity_overseer_id',
                label: 'Entity overseer ID',
                required: true,
              })}
            >
              <Input size="large" />
            </Form.Item>
          </>
        )}
        {addError && (
          <Form.Item>
            <BackendErrorAlert error={addError} />
          </Form.Item>
        )}
        <AddFormButtonGroup
          onClose={checkBeforeClose}
          isValid={isValid}
          isPending={isPending}
        />
      </Form>
    </Drawer>
  )
}
