import { SinkPlaceholder, SinkRule } from '@swiftctrl/api-client'
import {
  Empty,
  EyeOutlined,
  Layout,
  Tooltip,
} from '@swiftctrl/swift-component-library'
import { Collapse } from 'antd'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  CollapseContainer,
  CopyableCodeDisplay,
  CopyableDisplay,
  EnabledDisplay,
  LabelDisplay,
} from '../../../components'
import { normalizeFilterConfiguration } from '../../../utils-hooks'
import { FilterGroupsView } from '../FilterGroupsView'
import { getRuleFilterOptions } from './getRuleFilterOptions'
import { RuleIdFilters } from './RuleIdFilters'
import { usePathToRule } from './usePathToRule'

type PlaceholdersViewProps = {
  placeholders: SinkPlaceholder[] | null
  rules: SinkRule[]
}

export const PlaceholdersView = ({
  placeholders,
  rules,
}: PlaceholdersViewProps) => {
  if (!placeholders || placeholders.length === 0) {
    return <Empty description="No placeholders" />
  }

  return <Content placeholders={placeholders} rules={rules} />
}

const Content = ({
  placeholders,
  rules,
}: {
  placeholders: SinkPlaceholder[]
  rules: SinkRule[]
}) => {
  const ruleFilterOptions = getRuleFilterOptions(placeholders, rules)

  const [rule, setRule] = useState(ruleFilterOptions[0])

  const { ruleId, ruleName } = rule

  const filteredPlaceholders = placeholders.filter(
    (placeholder) => placeholder.sink_rule_id === ruleId,
  )

  const pathToRule = usePathToRule()

  return (
    <CollapseContainer>
      <RuleIdFilters
        options={ruleFilterOptions}
        onChange={setRule}
        selectedId={ruleId}
      />
      <Layout horizontal gap="xSmall" paddingBottom="xMedium">
        <LabelDisplay label="Rule" />
        <CopyableDisplay label={ruleName} copyableText={ruleId} />
        <Tooltip title="Open rule">
          <Link to={pathToRule} state={{ ruleId }}>
            <EyeOutlined />
          </Link>
        </Tooltip>
      </Layout>
      <Collapse accordion bordered={false}>
        {filteredPlaceholders.map(panelFactory)}
      </Collapse>
    </CollapseContainer>
  )
}

const panelFactory = (placeholder: SinkPlaceholder) => {
  const {
    placeholder_id,
    donor_field,
    sink_field,
    completed,
    filter_configuration,
  } = placeholder

  const filterConfiguration = normalizeFilterConfiguration(filter_configuration)

  return (
    <Collapse.Panel
      key={placeholder_id}
      header={
        <Layout horizontal>
          <LabelDisplay label="Placeholder Id" />
          <CopyableCodeDisplay label={placeholder_id} />
        </Layout>
      }
    >
      <Layout gap="small" paddingVertical="small">
        <div>
          <LabelDisplay label="donorField" />
          <CopyableCodeDisplay label={donor_field} />
        </div>
        <div>
          <LabelDisplay label="sinkField" />
          <CopyableCodeDisplay label={sink_field} />
        </div>
        <div>
          <LabelDisplay label="Completed" />
          <EnabledDisplay enabled={Boolean(completed)} />
        </div>
      </Layout>
      <FilterGroupsView filterConfiguration={filterConfiguration} />
    </Collapse.Panel>
  )
}
