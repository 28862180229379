import { PersonFilterTypes, PersonTypes } from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { Avatar, ColumnsType, Layout } from '@swiftctrl/swift-component-library'
import {
  ContactInfo,
  CopyableLink,
  CreatedUpdatedDateDisplay,
  EntitiesTableBrowser,
  HTMLTitle,
  StyledEntitiesBrowserScreen,
  TABLE_DATA_PER_FETCH,
} from '../components'
import { ORGANIZATIONS_SCREEN_PATH, PEOPLE_SCREEN_PATH } from '../config/paths'
import { EMPTY_ID } from '../data/models'
import { swiftClient } from '../data/swiftClient'
import { BrowseEntitiesConfig, BrowseEntitiesContextProvider } from '../states'
import { adaptSDKBrowseRequest, cacheKeys, isValidUuid } from '../utils-hooks'

export const PeopleScreen = () => (
  <StyledEntitiesBrowserScreen>
    <HTMLTitle value="People" />
    <BrowseEntitiesContextProvider
      dataPerFetch={TABLE_DATA_PER_FETCH}
      browse={browse}
      dataKey="person_id"
      searchable
      entityType="person"
      filters={{ baseIdType: 'organization' }}
    >
      <EntitiesTableBrowser columns={columns} />
    </BrowseEntitiesContextProvider>
  </StyledEntitiesBrowserScreen>
)

const browse: BrowseEntitiesConfig<PersonTypes> = {
  request: adaptSDKBrowseRequest({
    request: swiftClient.person.browse,
    select: ['person_id', 'primary_profile'],
    getFilters: (search: string) => {
      if (!isValidUuid(search)) {
        return undefined
      }

      const q = getQueryHandler<PersonFilterTypes>()

      return [q('WHERE', 'person_id', 'EQ', search)]
    },
    getSearch: (search: string) => {
      if (isValidUuid(search)) {
        return undefined
      }

      return search
    },
    sort: 'first_name:asc,last_name:asc',
  }),
  cacheKey: cacheKeys.persons,
}

const columns: ColumnsType<PersonTypes> = [
  {
    title: 'Name',
    render: (_, person) => {
      const {
        person_id,
        primary_profile: { display_name, avatar, profile_id },
      } = person

      const url = `/${PEOPLE_SCREEN_PATH}/${person_id}`

      return (
        <Layout horizontal alignItems="center" gap="small">
          <Avatar name={display_name || '-'} src={avatar} userId={profile_id} />
          <CopyableLink
            label={display_name}
            copyableText={person_id}
            to={url}
          />
        </Layout>
      )
    },
  },
  {
    title: 'Organization',
    render: (_, person) => {
      const { organization_id, organization_name } = person.primary_profile

      if (organization_id === EMPTY_ID) {
        return '-'
      }

      const url = `/${ORGANIZATIONS_SCREEN_PATH}/${organization_id}`

      return (
        <CopyableLink
          label={organization_name}
          copyableText={organization_id}
          to={url}
        />
      )
    },
  },
  {
    title: 'Contact Info',
    render: (_, person) => {
      const { email, phone_primary } = person.primary_profile

      return <ContactInfo email={email} phone={phone_primary} />
    },
  },
  {
    title: 'Created / Updated Date',
    render: (_, person) => {
      const { created_at, updated_at } = person.primary_profile

      return (
        <CreatedUpdatedDateDisplay
          createdAt={created_at}
          updatedAt={updated_at}
        />
      )
    },
  },
]
