import { RolesTypes } from '@swiftctrl/api-client'
import {
  Button,
  Layout,
  PlusOutlined,
  PreLoader,
  Tooltip,
} from '@swiftctrl/swift-component-library'
import { useBoolean } from '../../../../utils-hooks'
import { AddRolePermissionsView } from '../add'
import { buildGroupedData } from './buildGroupedData'
import { RoleOperationsGroupTable } from './RoleOperationsGroupTable'
import { useGetOperations } from './useGetOperations'
import { useGetRoleOperations } from './useGetRoleOperations'
import { useRemoveRoleOperation } from './useRemoveRoleOperation'

type RolePermissionsViewProps = {
  role: RolesTypes
}

export const RolePermissionsView = ({ role }: RolePermissionsViewProps) => {
  const { role_id } = role

  const { isLoading, error, data } = useGetRoleOperations(role_id)

  const { mutate: removeRoleOperation } = useRemoveRoleOperation(role_id)

  const [isAddDrawerOpen, openAddDrawer, closeAddDrawer] = useBoolean()

  const { data: getOperationsResponse, error: getOperationsError } =
    useGetOperations(isAddDrawerOpen)

  if (isLoading) {
    return <PreLoader centered spinProps={{ size: 'small' }} />
  }

  if (error) {
    return <p>Error while fetching role operations: {String(error)}</p>
  }

  const { groupedData } = buildGroupedData(data!)

  return (
    <>
      <Layout horizontal justifyContent="end" paddingBottom="xMedium">
        <Tooltip
          title={
            getOperationsError
              ? `Error while fetching operations: ${String(getOperationsError)}`
              : null
          }
        >
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={openAddDrawer}
            size="middle"
            loading={isAddDrawerOpen && !getOperationsResponse}
            disabled={Boolean(getOperationsError)}
          >
            Add Permission(s)
          </Button>
        </Tooltip>
      </Layout>
      <RoleOperationsGroupTable
        dataSource={groupedData}
        onRemoveRoleOperation={removeRoleOperation}
      />
      {getOperationsResponse && (
        <AddRolePermissionsView
          open={isAddDrawerOpen}
          operations={getOperationsResponse}
          roleId={role_id}
          onClose={closeAddDrawer}
        />
      )}
    </>
  )
}
