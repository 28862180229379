import { EpiTypes } from '@swiftctrl/api-client'
import { Layout, Typography } from '@swiftctrl/swift-component-library'
import { EPIS_SCREEN_PATH } from '../../../../config/paths'
import { EntityCard } from '../../../browse-entities'
import { EpiSubtypeDisplay } from './EpiSubtypeDisplay'
import { EpiTypeDisplay } from './EpiTypeDisplay'
import { OnPremisesDisplay } from './OnPremisesDisplay'

type EpiCardProps = {
  epi: EpiTypes
}

export const EpiCard = ({ epi }: EpiCardProps) => {
  const { epi_id } = epi

  const path = `/${EPIS_SCREEN_PATH}/${epi_id}`

  return (
    <EntityCard entity={epi} detailsNavigatePath={path}>
      <Layout paddingTop="xSmall" gap="xSmall">
        <Typography.Paragraph>
          <EpiTypeDisplay epi={epi} />
        </Typography.Paragraph>
        <Typography.Paragraph>
          <EpiSubtypeDisplay epi={epi} />
        </Typography.Paragraph>
        <Typography.Paragraph>
          <OnPremisesDisplay epi={epi} />
        </Typography.Paragraph>
      </Layout>
    </EntityCard>
  )
}
