import {
  Button,
  Drawer,
  Form,
  Input,
  Layout,
  Modal,
} from '@swiftctrl/swift-component-library'
import { Rule } from 'antd/lib/form'
import { isEqual } from 'lodash'
import { useState } from 'react'
import { getEntityLabel } from '../config/labels'
import { EntityType } from '../data/models'
import {
  buildCommonDrawerProps,
  buildCommonFormProps,
  getEntityDescription,
  getEntityName,
  trimValues,
} from '../utils-hooks'
import { EntityDescriptionInput } from './EntityDescriptionInput'

interface EditEntityDrawerProps<T> {
  open: boolean
  entity: T
  onClose: () => void
  onSave: (name: string, description: string) => void
  processingEditRequest: boolean
  entityType: EntityType
  requiredFields?: ('name' | 'description')[]
  nameValidator?: NameValidator
}

interface FormType {
  name: string
  description: string
}

/**
 * If the name is invalid, this should return an error message.
 *
 * Otherwise, the name is valid.
 */
type NameValidator = (value: string) => string | undefined

export const EditEntityDrawer = <T extends object>({
  open,
  entity,
  onClose,
  entityType,
  requiredFields,
  onSave,
  processingEditRequest,
  nameValidator,
}: EditEntityDrawerProps<T>) => {
  const initialValues = {
    name: getEntityName(entity, entityType),
    description: getEntityDescription(entity, entityType),
  }

  const [values, setValues] = useState<FormType>(initialValues)

  const isValid = validate(values, requiredFields, nameValidator)

  const isDirty = !isEqual(values, initialValues)

  const title = `Edit ${getEntityLabel({ entityType, capitalize: false })}`

  const onFinish = (values: FormType) => {
    const { name, description } = trimValues(values)

    onSave(name, description)
  }

  const resetAndClose = () => {
    setValues(initialValues)

    onClose()
  }

  const checkBeforeClose = () => {
    if (!isDirty) {
      resetAndClose()

      return
    }
    Modal.confirm({
      title: 'Are you sure you want to discard your changes?',
      okText: 'Discard',
      onOk: resetAndClose,
    })
  }

  const buttonDisable = !isDirty || !isValid

  return (
    <Drawer
      {...buildCommonDrawerProps({
        title,
        open,
        onClose: checkBeforeClose,
        isPending: processingEditRequest,
      })}
    >
      <Form
        {...buildCommonFormProps({
          values,
          setValues,
          isPending: processingEditRequest,
          onFinish,
        })}
      >
        <Form.Item
          name="name"
          label="Name"
          required={requiredFields?.includes('name')}
          rules={transformNameValidator(nameValidator)}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          required={requiredFields?.includes('description')}
        >
          <EntityDescriptionInput noStyle />
        </Form.Item>
        <Layout horizontal justifyContent="flex-end" gap="medium">
          <Form.Item>
            <Button disabled={processingEditRequest} onClick={checkBeforeClose}>
              Cancel
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              loading={processingEditRequest}
              type="primary"
              htmlType="submit"
              disabled={buttonDisable}
            >
              Save
            </Button>
          </Form.Item>
        </Layout>
      </Form>
    </Drawer>
  )
}

const validate = (
  values: FormType,
  requiredFields: ('name' | 'description')[] | undefined,
  nameValidator: NameValidator | undefined,
) => {
  const { name, description } = trimValues(values)

  if (requiredFields) {
    if (requiredFields.includes('name') && name === '') {
      return false
    }

    if (requiredFields.includes('description') && description === '') {
      return false
    }
  }

  if (nameValidator) {
    const errorMessage = nameValidator(name)

    if (errorMessage) {
      return false
    }
  }

  return true
}

const transformNameValidator = (nameValidator: NameValidator | undefined) => {
  if (!nameValidator) {
    return undefined
  }

  const rule: Rule = {
    validator: (_: any, value: string) => {
      const errorMessage = nameValidator(value)

      if (errorMessage) {
        return Promise.reject(errorMessage)
      }

      return Promise.resolve()
    },
  }

  return [rule]
}
