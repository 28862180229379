import {
  CredentialFieldConstraint,
  CredentialTemplateFieldValidValue,
} from '@swiftctrl/api-client'
import { Button, Layout } from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'
import { useBoolean } from '../../../utils-hooks'
import { RemoveConstraintButton } from '../RemoveConstraintButton'
import { TooltipIcon } from '../TooltipIcon'
import { BitfieldValue, Constraint } from '../utils'
import { ValidatedFormItem } from '../ValidatedFormItem'
import { CustomValidValuesEditorModal } from './CustomValidValuesEditorModal'
import { IntegerConstraintTypeView } from './IntegerConstraintTypeView'

type CustomValidValuesViewProps = {
  templateConstraint: Constraint
  templateBitfieldValue: BitfieldValue | null | undefined
  formatConstraint: CredentialFieldConstraint | undefined
  min?: number
  max?: number | bigint
  constraintFromFormat?: boolean
  constraintFromTemplate?: boolean
  onChange: (constraint: Constraint | null) => void
}

export const CustomValidValuesView = ({
  templateConstraint,
  templateBitfieldValue,
  formatConstraint,
  min,
  max,
  constraintFromFormat = false,
  constraintFromTemplate = false,
  onChange,
}: CustomValidValuesViewProps) => {
  const templateValidValues = templateConstraint.valid_values!

  const [isModalOpen, openModal, closeModal] = useBoolean()

  const handleSave = (values: CredentialTemplateFieldValidValue[]) => {
    onChange({ valid_values: values, date_range: null, integer_range: null })

    closeModal()
  }

  const handleRemoveConstraint = () => {
    onChange(null)
  }

  const tooltip = getTooltip(
    min,
    max,
    constraintFromFormat,
    constraintFromTemplate,
  )

  const { isValid, validationMessage } = validate(templateValidValues, min, max)

  return (
    <Layout horizontal alignItems="center" gap="medium">
      <IntegerConstraintTypeView
        value="valid_values"
        templateConstraint={templateConstraint}
        templateBitfieldValue={templateBitfieldValue}
        formatConstraint={formatConstraint}
        onChange={onChange}
      />
      {tooltip && <TooltipIcon message={tooltip} />}
      <ValidatedFormItem
        isValid={isValid}
        validationMessage={validationMessage}
      >
        <StyledButton type="link" size="small" onClick={openModal}>
          {getValidValuesLabel(templateValidValues.length)}
        </StyledButton>
      </ValidatedFormItem>
      <RemoveConstraintButton onClick={handleRemoveConstraint} />
      <CustomValidValuesEditorModal
        key={JSON.stringify(templateValidValues)}
        open={isModalOpen}
        validValues={templateValidValues}
        min={min}
        max={max}
        onClose={closeModal}
        onSave={handleSave}
      />
    </Layout>
  )
}

const getTooltip = (
  min: number | undefined,
  max: number | bigint | undefined,
  constraintFromFormat: boolean,
  constraintFromTemplate: boolean,
) => {
  if (min === undefined || max === undefined) {
    return undefined
  }

  const originTokens = []

  if (constraintFromFormat) {
    originTokens.push('format')
  }

  if (constraintFromTemplate) {
    originTokens.push('template')
  }

  const origin = originTokens.join(' and ')

  return `Due to the ${origin} field constraints, the valid values min and max are ${min} and ${max}`
}

const validate = (
  templateValidValues: CredentialTemplateFieldValidValue[],
  min: number | undefined,
  max: number | bigint | undefined,
): { isValid: boolean; validationMessage: string } => {
  if (min === undefined || max === undefined) {
    return { isValid: true, validationMessage: '' }
  }

  for (let i = 0; i < templateValidValues.length; i++) {
    const value = Number(templateValidValues[i].value)

    if (value < min || BigInt(value) > BigInt(max)) {
      return {
        isValid: false,
        validationMessage: `valid_values must be between ${min} and ${max}`,
      }
    }
  }

  return { isValid: true, validationMessage: '' }
}

const StyledButton = styled(Button)`
  padding: 0px;

  > span {
    text-decoration: underline;

    text-underline-offset: 3px;
  }
`

const getValidValuesLabel = (validValuesCount: number) => {
  if (validValuesCount === 0) {
    return 'Add valid values'
  }

  const label = validValuesCount === 1 ? 'value' : 'values'

  const validValuesLabel = `${validValuesCount} ${label}`

  return validValuesLabel
}
