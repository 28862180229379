import { CredentialTemplateTypes } from '@swiftctrl/api-client'
import {
  Button,
  PreviewOutlined,
  Tooltip,
} from '@swiftctrl/swift-component-library'
import { useNavigate } from 'react-router-dom'
import {
  CopyableCodeDisplay,
  GenericEditEntityDrawer,
  HeaderContent,
  invalidateReadScreenQuery,
} from '../../components'
import { CREDENTIAL_TEMPLATES_SCREEN_PATH } from '../../config/paths'
import { queryClient } from '../../data/queryClient'
import { useBooleanEventState, useEventContext } from '../../states'
import {
  cacheKeys,
  showSuccessNotification,
  useBoolean,
  useDeleteEntityFlow,
} from '../../utils-hooks'
import { CREDENTIAL_TEMPLATE_INVALID_MESSAGE } from './utils'

export const CredentialTemplateScreenHeader = ({
  credentialTemplate,
}: {
  credentialTemplate: CredentialTemplateTypes
}) => {
  const {
    template_name,
    credential_format_config_id,
    template_description,
    credential_format_id,
    credential_format_name,
    overseer_id,
    overseer_name,
    created_at,
    updated_at,
  } = credentialTemplate

  const isCredentialTemplateValid = useBooleanEventState(
    'credentialTemplate.valid',
    true,
  )

  const { emitEvent } = useEventContext()

  const handlePreviewClick = () => {
    emitEvent('CredentialTemplateScreenHeader.PreviewButton.click')
  }

  const handleDuplicateClick = () => {
    emitEvent('CredentialTemplateScreenHeader.DuplicateButton.click')
  }

  const [isEditViewOpen, openEditView, closeEditView] = useBoolean()

  const handleEdited = () => {
    invalidateReadScreenQuery(
      cacheKeys.credential_template,
      credential_format_config_id,
    )

    queryClient.resetQueries({
      queryKey: [cacheKeys.credential_templates],
    })

    closeEditView()
  }

  const navigate = useNavigate()

  const { handleDeleteClick } = useDeleteEntityFlow({
    entityType: 'credential_template',
    entityName: template_name,
    entityId: credential_format_config_id,
    onSuccess: () => {
      showSuccessNotification('Credential template deleted')

      navigate(`/${CREDENTIAL_TEMPLATES_SCREEN_PATH}`)
    },
  })

  const previewButtonTooltip = isCredentialTemplateValid
    ? undefined
    : CREDENTIAL_TEMPLATE_INVALID_MESSAGE

  return (
    <>
      <HeaderContent
        entityName={template_name}
        entityId={credential_format_config_id}
        entityDescription={template_description}
        infoItemsLeft={[
          {
            title: 'Core Credential Format',
            value: credential_format_name,
            copyableText: credential_format_id,
          },
          {
            title: 'Overseer',
            value: overseer_name || <CopyableCodeDisplay label={overseer_id} />,
            copyableText: overseer_id,
          },
        ]}
        createdAt={created_at}
        updatedAt={updated_at}
        breadCrumbPath={`/${CREDENTIAL_TEMPLATES_SCREEN_PATH}`}
        entityType="credential_template"
        preActionsDropdownButton={
          <Tooltip title={previewButtonTooltip}>
            <Button
              icon={<PreviewOutlined />}
              onClick={handlePreviewClick}
              disabled={!isCredentialTemplateValid}
            >
              Preview
            </Button>
          </Tooltip>
        }
        actionDropDownsItems={[
          {
            key: 'edit',
            label: 'Edit',
            onClick: openEditView,
          },
          {
            key: 'duplicate',
            label: 'Duplicate',
            onClick: handleDuplicateClick,
            disabled: !isCredentialTemplateValid,
            tooltipText: isCredentialTemplateValid
              ? undefined
              : CREDENTIAL_TEMPLATE_INVALID_MESSAGE,
          },
          {
            key: 'delete',
            label: 'Delete',
            danger: true,
            onClick: handleDeleteClick,
          },
        ]}
      />
      <GenericEditEntityDrawer
        open={isEditViewOpen}
        entity={credentialTemplate}
        entityType="credential_template"
        onEdited={handleEdited}
        onClose={closeEditView}
      />
    </>
  )
}
