import { useQuery } from '@tanstack/react-query'
import { swiftClientWithoutErrorHandling } from '../../data/swiftClient'
import { cacheKeys, longCacheOptions } from '../cacheKeysUtils'

type Params = {
  id: string
  enabled?: boolean
}

export const useReadSinkTemplate = ({ id, enabled }: Params) =>
  useQuery({
    queryKey: [cacheKeys.sink_template, id],
    queryFn: () => swiftClientWithoutErrorHandling.sinkTemplate.at(id).read(),
    enabled,
    ...longCacheOptions,
  })
