import { AxiosError, AxiosResponse, ErrorProps } from '@swiftctrl/api-client'
import { Layout, PreLoader } from '@swiftctrl/swift-component-library'
import { useInfiniteQuery } from '@tanstack/react-query'
import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'
import { InfiniteScrollTrigger } from '../components'
import { EntityType, SWIFT_ROOT_ENTITY_ID } from '../data/models'
import { queryClient } from '../data/queryClient'
import { RestApiParamsKeys } from '../types'
import {
  BrowseEntitySort,
  buildAxiosResponse,
  buildEntityBrowserNextPageParam,
  FieldKey,
  getAllFromBrowseRequest,
  totalIsMultipleOfLimit,
  useGetValidUuidParams,
  useHandleUrlParams,
  validateBrowseSearch,
} from '../utils-hooks'
import { useEventEmitterEffect } from './EventContext'

export const FETCH_ALL_DATA = -1

type BrowseEntitiesContextProviderProps<T> = PropsWithChildren<{
  browse: {
    request: (params: {
      baseId: string
      pageParam: number
      dataPerFetch: number
      search: string
      relatedEntityId?: string
      sort?: BrowseEntitySort
      signal?: AbortSignal
    }) => Promise<AxiosResponse<Partial<T>[], any>>
    /**
     * This key will be used in the react-query key,
     * which will also include the `baseId` and `search`
     */
    cacheKey: string | (string | undefined)[] | undefined
    onDataReceived?: (data: Partial<T>[]) => void
  }
  /**
   * If omitted, defaults to Swift root entity ID
   */
  baseId?: string
  dataKey: FieldKey<T>
  /**
   * If enabled, then the search bar will display
   */
  searchable?: boolean
  entityType: EntityType
  /**
   * If true, this will display if an "add" request is configured for the `entityType`,
   * or if `onAddButtonClick` is set.
   *
   * These configurations live in `src/config/add-entity-types`.
   */
  displayAddButton?: boolean
  addButtonLabel?: string
  /**
   * If defined, sets the initial value(s) for the add data
   */
  addDataTemplate?: object
  /**
   * If defined, bypasses the normal flow for creating entities of this type.
   */
  onAddButtonClick?: () => void
  filters?: {
    /**
     * If set, then the base ID filter will display
     */
    baseIdType?: EntityType
    /**
     * If set, then the related entity filter will display
     */
    relatedEntityType?: EntityType
    relatedEntityLabel?: string
  }
  dataPerFetch: number
  /**
   * Defaults to `false`
   */
  capitalizeTotalLabel?: boolean
  /**
   * If omitted, defaults to the internal top bar component
   */
  topBar?: ReactNode
  enableQuery?: {
    /**
     * Control whether to fetch data or not, based on condition
     */
    condition: (baseId: string) => boolean
    /**
     * Displayed when the query is disabled
     */
    disabledView: ReactNode
  }
}>

export type BrowseEntitiesConfig<T> =
  BrowseEntitiesContextProviderProps<T>['browse']

export type BrowseEntitiesRequestParams<T> = Parameters<
  BrowseEntitiesConfig<T>['request']
>[0]

export type EntitiesBrowserProps = Pick<
  BrowseEntitiesContextProviderProps<any>,
  'baseId' | 'filters' | 'displayAddButton'
>

export type BrowseEntitiesContextType<T> = {
  baseId: string
  baseIdIsPreset: boolean
  relatedEntityId: string
  dataSource: T[] | undefined
  dataSourceCount: number
  dataKey: string
  error: Error | undefined
  isLoading: boolean
  dataPerFetch: number
  invalidateData: () => void
  searchable: boolean
  initialSearch: string
  search: string
  setSearch: (value: string) => void
  entityType: EntityType
  displayAddButton: boolean
  addButtonLabel?: string
  addDataTemplate?: object
  onAddButtonClick?: () => void
  baseIdFilterType?: EntityType
  relatedEntityIdFilterType?: EntityType
  relatedEntityLabel?: string
  handleBaseIdFilterChange: (value: string) => void
  handleRelatedEntityIdFilterChange: (value: string) => void
  baseIdParamKey: RestApiParamsKeys
  relatedEntityIdFilterParamKey: RestApiParamsKeys
  pageIndex: number
  setPageIndex: (value: number) => void
  sort: BrowseEntitySort | undefined
  setSort: (sort: BrowseEntitySort | undefined) => void
  capitalizeTotalLabel: boolean
  topBar?: ReactNode
  queryDisabledView?: ReactNode
}

const BrowseEntitiesContext = createContext<
  BrowseEntitiesContextType<unknown> | undefined
>(undefined)

export const BrowseEntitiesContextProvider = <T,>({
  browse: { request: browseRequest, cacheKey: browseCacheKey, onDataReceived },
  baseId: baseIdProp = SWIFT_ROOT_ENTITY_ID,
  dataKey,
  searchable = false,
  entityType,
  displayAddButton = false,
  addButtonLabel,
  addDataTemplate,
  onAddButtonClick,
  filters,
  children,
  dataPerFetch,
  capitalizeTotalLabel = false,
  topBar,
  enableQuery,
}: BrowseEntitiesContextProviderProps<T>) => {
  const { updateParams } = useHandleUrlParams()

  const baseIdFilterType = filters?.baseIdType

  const relatedEntityIdFilterType = filters?.relatedEntityType

  const baseIdParamKey = buildParamKey(baseIdFilterType)

  const relatedEntityIdFilterParamKey = buildParamKey(relatedEntityIdFilterType)

  const params = useGetValidUuidParams([
    baseIdParamKey,
    relatedEntityIdFilterParamKey,
  ])

  const baseIdParam = params[baseIdParamKey]

  const [baseId, setBaseId] = useState<string>(baseIdParam || baseIdProp)

  const relatedEntityIdParam = params[relatedEntityIdFilterParamKey]

  const [relatedEntityId, setRelatedEntityId] = useState<string>(
    relatedEntityIdParam || '',
  )

  const [searchParams] = useSearchParams()

  const initialSearch = searchParams.get('search') || ''

  const [search, setSearch] = useState<string>(() => {
    const { isValidSearch, validatedSearch } =
      validateBrowseSearch(initialSearch)

    if (isValidSearch) {
      return validatedSearch
    }

    return ''
  })

  const [pageIndex, setPageIndex] = useState<number>(0)

  const resetPageIndex = () => setPageIndex(0)

  const handleSearchChange = (value: string) => {
    setSearch(value)

    resetPageIndex()
  }

  const handleBaseIdFilterChange = (value: string) => {
    setBaseId(value || baseIdProp)

    updateParams({ [baseIdParamKey]: value || '' })

    resetPageIndex()
  }

  const handleRelatedEntityIdFilterChange = (value: string) => {
    setRelatedEntityId(value || '')

    updateParams({ [relatedEntityIdFilterParamKey]: value || '' })

    resetPageIndex()
  }

  const [sort, setSort] = useState<BrowseEntitySort | undefined>()

  const queryKey = buildQueryKey(
    browseCacheKey,
    search,
    relatedEntityId,
    baseId,
    pageIndex,
    sort,
  )

  const { isQueryEnabled, queryDisabledView } = buildQueryEnabledOptions(
    enableQuery,
    baseId,
  )

  const { data, error, isLoading, fetchNextPage, isFetching } =
    useInfiniteQuery({
      queryKey,
      queryFn: async ({ pageParam, signal }) => {
        if (dataPerFetch === FETCH_ALL_DATA) {
          const data = await getAllFromBrowseRequest((offset, limit) =>
            browseRequest({
              baseId,
              pageParam: offset,
              dataPerFetch: limit,
              search,
              relatedEntityId,
              sort,
              signal,
            }),
          )

          const response = buildAxiosResponse(data)

          return response
        } else {
          try {
            const response = await browseRequest({
              baseId,
              pageParam,
              dataPerFetch,
              search,
              relatedEntityId,
              sort,
              signal,
            })

            return response
          } catch (error) {
            if (!totalIsMultipleOfLimit(error as AxiosError<ErrorProps>)) {
              throw error
            }
            return { data: [], config: {} }
          }
        }
      },
      initialPageParam: 0,
      getNextPageParam: ({ data, config }) => {
        if (dataPerFetch === FETCH_ALL_DATA) {
          return undefined
        } else {
          return buildEntityBrowserNextPageParam({ data, config })
        }
      },
      enabled: isQueryEnabled,
    })

  useEffect(() => {
    if (data && onDataReceived) {
      onDataReceived(data.pages.flatMap((page) => page.data))
    }
  }, [data, onDataReceived])

  useEventEmitterEffect('BrowseEntitiesContext.isFetching', {
    entityType,
    isFetching,
  })

  let dataSource = data?.pages.reduce(
    (entity, page) => [...entity, ...page.data],
    [] as Partial<T>[],
  )

  const invalidateData = () => {
    queryClient.invalidateQueries({ queryKey })
  }

  return (
    <BrowseEntitiesContext.Provider
      value={{
        baseId,
        baseIdIsPreset: baseIdProp !== SWIFT_ROOT_ENTITY_ID,
        relatedEntityId,
        dataSource,
        dataSourceCount: dataSource?.length || 0,
        dataKey: String(dataKey),
        error: error || undefined,
        isLoading,
        dataPerFetch,
        invalidateData,
        searchable,
        initialSearch,
        search,
        setSearch: handleSearchChange,
        entityType,
        displayAddButton,
        addButtonLabel,
        addDataTemplate,
        onAddButtonClick,
        baseIdFilterType,
        relatedEntityIdFilterType,
        relatedEntityLabel: filters?.relatedEntityLabel,
        handleBaseIdFilterChange,
        handleRelatedEntityIdFilterChange,
        baseIdParamKey,
        relatedEntityIdFilterParamKey,
        pageIndex,
        setPageIndex,
        sort,
        setSort,
        capitalizeTotalLabel,
        topBar,
        queryDisabledView,
      }}
    >
      {children}
      {dataSource && dataSource.length > 0 && isFetching && (
        <Layout paddingTop="medium" alignItems="center">
          <PreLoader spinProps={{ size: 'small' }} />
        </Layout>
      )}
      {isQueryEnabled && <InfiniteScrollTrigger callback={fetchNextPage} />}
    </BrowseEntitiesContext.Provider>
  )
}

const buildParamKey = (entityType: EntityType | undefined) => {
  const normalizedValue = entityType?.replaceAll('_', '-')

  const paramKey = `${normalizedValue}-id` as RestApiParamsKeys

  return paramKey
}

const buildQueryKey = (
  browseCacheKey: string | (string | undefined)[] | undefined,
  search: string,
  relatedEntityId: string,
  baseId: string,
  pageIndex: number,
  sort: BrowseEntitySort | undefined,
) => {
  const queryKey = []

  if (Array.isArray(browseCacheKey)) {
    queryKey.push(...browseCacheKey)
  } else {
    queryKey.push(browseCacheKey)
  }

  queryKey.push(
    search,
    relatedEntityId,
    baseId,
    pageIndex,
    sort?.columnTitle,
    sort?.order,
  )

  return queryKey
}

const buildQueryEnabledOptions = <T,>(
  enableQuery: BrowseEntitiesContextProviderProps<T>['enableQuery'],
  baseId: string,
) => {
  if (!enableQuery) {
    return {
      isQueryEnabled: true,
      queryDisabledView: null,
    }
  }

  const { condition, disabledView } = enableQuery

  const isQueryEnabled = condition(baseId)

  if (isQueryEnabled) {
    return {
      isQueryEnabled: true,
      queryDisabledView: null,
    }
  }

  return {
    isQueryEnabled: false,
    queryDisabledView: disabledView,
  }
}

export const useBrowseEntitiesContext = <T,>() => {
  const context = useContext(BrowseEntitiesContext)

  if (!context) {
    throw new Error(
      'useBrowseEntitiesContext must be used within BrowseEntitiesContextProvider',
    )
  }

  const { dataSource, ...rest } = context

  return {
    dataSource: dataSource ? (dataSource as T[]) : undefined,
    ...rest,
  } as BrowseEntitiesContextType<T>
}
