import { EntityTypesIds, ProviderType } from '@swiftctrl/api-client'

export const SWIFT_ROOT_ENTITY_ID = '10000000-0000-4000-8000-000000000000'
export const EMPTY_ID = '00000000-0000-0000-0000-000000000000'
export const SWIFT_CORE_ID = '20000000-0000-4000-8000-000000000000'

export const BACKEND_ERROR_MESSAGE_SEPARATOR = ' || '

export const VALID_EMAIL_FORMAT =
  /^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\s*$/i

export const INVALID_EMAIL_FORMAT_MESSAGE =
  'Invalid email format, email must be in the following format "example@example.com"'

export const INVALID_PHONE_FORMAT_MESSAGE = 'Invalid phone number format'

export const VALID_PASSWORD = {
  MIN_LENGTH: 8,
  MAX_LENGTH: 72,
  UPPERCASE: /[A-Z]/,
  LOWERCASE: /[a-z]/,
  NUMBER_OR_SPECIAL_CHARACTER: /[0-9]|[!@#$%^&*()\-_+=`~[{\]}\\|;:'",<.>/?]/,
}

export const INVALID_PASSWORD_MESSAGES = {
  MIN_LENGTH: `Password must be at least ${VALID_PASSWORD.MIN_LENGTH} characters long`,
  MAX_LENGTH: `Password must be at most ${VALID_PASSWORD.MAX_LENGTH} characters long`,
  UPPERCASE: 'Password must contain an uppercase character',
  LOWERCASE: 'Password must contain a lowercase character',
  NUMBER_OR_SPECIAL_CHARACTER:
    'Password must contain a number or special character',
}

export const TABLE_CSS = {
  BORDER: '1px solid rgba(0, 0, 0, 0.06)',
  HEADER_HEIGHT: '54.9px',
  HEADER_HEIGHT_NO_UNIT: 54.9,
}

/**
 * This combines the types that actually exist in the backend
 * with some types that only exist in the frontend.
 *
 * The frontend types exist in order to reuse generic components.
 */
export type EntityType =
  | EntityTypesIds
  | 'access_level_reader_schedule'
  | 'archetype'
  | 'credential_template'
  | 'entity_counter'
  | 'entity_mirror'
  | 'permission'
  | 'person'
  | 'profile_role'

/**
 * This is a value that can safely be sent to the BE for a
 * field that is of type `int64`
 */
export const MAX_SAFE_BIG_INT = BigInt('9223372036854775807')

export const PROVIDER_TYPES_SUPPORTING_PROVIDER_PROFILES: ProviderType[] = [
  'email_password',
  'openid',
  'openid_okta',
  'proxy_login',
  'single_use_token',
]
