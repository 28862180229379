import { trimValues } from '../../utils-hooks'
import { Entry, ValidationRule } from './types'
import { validate } from './validate'

export const handleConfirm = <T>(
  entries: Entry<T>[],
  dataIndexToValidationRules: Map<string, ValidationRule<T>[]>,
  setEntries: (entries: Entry<T>[]) => void,
  setErrors: (errors: Map<string, string[]>) => void,
  onConfirm: (data: T[]) => void,
) => {
  const errors = new Map<string, string[]>()

  entries.forEach((entry) => {
    Object.entries(entry).forEach(([dataIndex, value]) => {
      if (dataIndex === 'key') {
        return
      }

      const rules = dataIndexToValidationRules.get(dataIndex)

      if (!rules) {
        return
      }

      validate(value, rules, errors, entry.key, dataIndex, entries)
    })
  })

  setErrors(errors)

  if (errors.size > 0) {
    return
  }

  const data = new Array<T>()

  const update = new Array<Entry<T>>()

  entries.forEach((entry) => {
    const trimmedEntry = trimValues(entry)

    update.push(trimmedEntry)

    const { key, ...rest } = trimmedEntry

    data.push(rest as T)
  })

  setEntries(update)

  onConfirm(data)
}
