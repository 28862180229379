import { Button, Col, Row, Tooltip } from '@swiftctrl/swift-component-library'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useEventContext } from '../../states'
import { TextEditor } from './TextEditor'
import { UIEditor } from './UIEditor'

type JsonEditorProps = {
  json: Record<string, any>
  confirmButtonLabel: string
  confirmButtonLoading?: boolean
  disableConfirmButtonWhenDataUnchanged?: boolean
  onConfirm: (json: Record<string, any>) => void
  onDirty?: (value: boolean) => void
}

export const JsonEditor = ({
  json,
  confirmButtonLabel,
  confirmButtonLoading,
  disableConfirmButtonWhenDataUnchanged = false,
  onConfirm,
  onDirty,
  ...props
}: JsonEditorProps) => {
  const [data, setData] = useState(json)

  const [isValid, setIsValid] = useState(true)

  const { emitEvent, useEvent } = useEventContext()

  useEvent('JsonEditor.UIEditor.change', setData)

  useEvent('JsonEditor.TextEditor.change', setData)

  useEvent('JsonEditor.TextEditor.valid', setIsValid)

  const confirmButtonDisabledReason = getConfirmButtonDisabledReason(
    json,
    data,
    disableConfirmButtonWhenDataUnchanged,
    isValid,
  )

  const isDirty = !isEqual(json, data)

  useEffect(() => {
    emitEvent('hasUnsavedChanges', isDirty)

    onDirty?.(isDirty)
  }, [emitEvent, isDirty, onDirty])

  return (
    <JsonEditorContainer {...props}>
      <StyledTooltip title={confirmButtonDisabledReason} placement="topRight">
        <Button
          size="middle"
          type="primary"
          disabled={Boolean(confirmButtonDisabledReason)}
          loading={confirmButtonLoading}
          onClick={() => onConfirm(data)}
        >
          {confirmButtonLabel}
        </Button>
      </StyledTooltip>

      <Row gutter={64}>
        <Col span={12}>
          <UIEditor data={json} />
        </Col>
        <Col span={12}>
          <TextEditor data={json} />
        </Col>
      </Row>
    </JsonEditorContainer>
  )
}

const JsonEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`

const StyledTooltip = styled(Tooltip)`
  align-self: flex-end;
`

/**
 * Returns a string indicating why the confirm button is disabled.
 *
 * If it returns a falsy value, it means the confirm button is enabled.
 */
const getConfirmButtonDisabledReason = (
  json: Record<string, any>,
  data: Record<string, any>,
  disableConfirmButtonWhenDataUnchanged: boolean,
  isValid: boolean,
) => {
  if (!isValid) {
    return 'JSON is not valid'
  }

  if (disableConfirmButtonWhenDataUnchanged && isEqual(json, data)) {
    return "JSON hasn't been modified"
  }
}
