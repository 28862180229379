import { Button, Form, Layout } from '@swiftctrl/swift-component-library'

type AddFormButtonGroupProps = {
  onClose: () => void
  isValid: boolean
  isPending: boolean
}

export const AddFormButtonGroup = ({
  onClose,
  isValid,
  isPending,
}: AddFormButtonGroupProps) => {
  return (
    <Layout horizontal gap="small" justifyContent="end">
      <Form.Item>
        <Button type="text" onClick={onClose}>
          Cancel
        </Button>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={!isValid}
          loading={isPending}
        >
          Add
        </Button>
      </Form.Item>
    </Layout>
  )
}
