import { ProfilesTypes } from '@swiftctrl/api-client'
import { PreLoader } from '@swiftctrl/swift-component-library'
import { createContext, PropsWithChildren, useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { swiftClient } from '../data/swiftClient'
import { cacheKeys, goToAccountsApp } from '../utils-hooks'

type Context = {
  user: ProfilesTypes
  signOut: () => void
}

// Short-circuit ahead, so this will never be used
const defaultValue = {} as Context

const UserContext = createContext<Context>(defaultValue)

export const useUserContext = () => useContext(UserContext)

export const UserContextProvider = ({ children }: PropsWithChildren) => {
  const { isLoading, data } = useQuery({
    queryKey: [cacheKeys.selfProfile],
    queryFn: swiftClient.profile.getSelfProfile,
  })

  const user = data?.data

  const signOut = () => goToAccountsApp('signout')

  if (isLoading) {
    return <PreLoader spinProps={{ size: 'large' }} centered />
  }

  if (!user) {
    goToAccountsApp('signin')

    return null
  }

  const context = { user, signOut }

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>
}
