import { Layout, Progress } from '@swiftctrl/swift-component-library'
import { DeleteOrganizationModalContentProps } from './utils'

export const DeletingEntitiesView = ({
  entitiesCount,
  deletedCount,
}: DeleteOrganizationModalContentProps) => {
  return (
    <Layout horizontal gap="small">
      <div>Deleting entities</div>
      <Progress
        percent={(100 * deletedCount) / entitiesCount!}
        format={() => `${deletedCount}/${entitiesCount}`}
      />
    </Layout>
  )
}
