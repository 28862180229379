import {
  CredentialFieldConstraint,
  CredentialFieldDateRange,
} from '@swiftctrl/api-client'
import { Layout } from '@swiftctrl/swift-component-library'
import { getDateRangeStartEnd } from '../../components'
import { trimDateTimeToDate } from '../../utils-hooks'
import { AddConstraintButton } from './AddConstraintButton'
import { DateRangePicker } from './DateRangePicker'
import { RemoveConstraintButton } from './RemoveConstraintButton'
import { TooltipIcon } from './TooltipIcon'
import { CredentialTemplateFieldConstraintViewProps } from './utils'

export const CredentialTemplateFieldTimeConstraintView = ({
  templateConstraint,
  formatConstraint,
  onChange,
}: CredentialTemplateFieldConstraintViewProps) => {
  if (!formatConstraint && !templateConstraint) {
    return (
      <AddConstraintButton
        onClick={() =>
          onChange({
            date_range: { start: '', end: '' },
            valid_values: null,
            integer_range: null,
          })
        }
      />
    )
  }

  const { start: formatStart, end: formatEnd } =
    getConstraints(formatConstraint)

  const defaultValue = getDefaultValue(
    formatStart,
    formatEnd,
    templateConstraint?.date_range?.start,
    templateConstraint?.date_range?.end,
  )

  return (
    <Layout horizontal alignItems="center" gap="medium">
      date_range
      <TooltipIcon message={buildTooltipMessage(formatStart, formatEnd)} />
      <DateRangePicker
        defaultValue={defaultValue}
        min={formatStart}
        max={formatEnd}
        onChange={(value) =>
          onChange({
            date_range: value,
            valid_values: null,
            integer_range: null,
          })
        }
      />
      {!formatConstraint && (
        <RemoveConstraintButton onClick={() => onChange(null)} />
      )}
    </Layout>
  )
}

const getConstraints = (
  formatConstraint: CredentialFieldConstraint | undefined,
) => {
  if (!formatConstraint) {
    return { start: undefined, end: undefined }
  }

  const { type, value } = formatConstraint

  switch (type) {
    case 'date_range':
      return getDateRangeStartEnd(value as CredentialFieldDateRange)

    default:
      return { start: undefined, end: undefined }
  }
}

const getDefaultValue = (
  formatStart: string | undefined,
  formatEnd: string | undefined,
  templateStart: string | undefined,
  templateEnd: string | undefined,
) => {
  if (typeof templateStart === 'string' && typeof templateEnd === 'string') {
    return { start: templateStart, end: templateEnd }
  }

  if (typeof formatStart === 'string' && typeof formatEnd === 'string') {
    return {
      start: formatStart,
      end: formatEnd,
    }
  }

  return undefined
}

const buildTooltipMessage = (
  start: string | undefined,
  end: string | undefined,
) => {
  if (start === undefined || end === undefined) {
    return undefined
  }

  const startLabel = trimDateTimeToDate(start)

  const endLabel = trimDateTimeToDate(end)

  const message = `Due to the format field constraints, the date range min and max are ${startLabel} and ${endLabel}`

  return message
}
