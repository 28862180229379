import { PersonTypes, RolesTypes } from '@swiftctrl/api-client'
import { ColumnsType } from '@swiftctrl/swift-component-library'
import { PropsWithChildren } from 'react'
import { CopyableLink, EntitiesTableBrowser } from '../../../components'
import { ROLES_SCREEN_PATH } from '../../../config/paths'
import { swiftClientWithoutErrorHandling } from '../../../data/swiftClient'
import {
  BrowseEntitiesConfig,
  BrowseEntitiesContextProvider,
  FETCH_ALL_DATA,
} from '../../../states'
import { adaptSDKBrowseRequest, cacheKeys } from '../../../utils-hooks'
import { RoleActionsDropdown } from './RoleActionsDropdown'

type RolesBrowserProps = PropsWithChildren<{
  person: PersonTypes
}>

export const RolesBrowser = ({ person, children }: RolesBrowserProps) => {
  const browse = buildBrowse(person)

  const columns = buildColumns(person)

  return (
    <BrowseEntitiesContextProvider
      dataPerFetch={FETCH_ALL_DATA}
      browse={browse}
      dataKey="role_id"
      entityType="role"
    >
      <EntitiesTableBrowser columns={columns} />
      {children}
    </BrowseEntitiesContextProvider>
  )
}

const buildBrowse = (
  person: PersonTypes,
): BrowseEntitiesConfig<RolesTypes> => ({
  request: adaptSDKBrowseRequest({
    request: swiftClientWithoutErrorHandling.person.at(person.person_id).role
      .browse,
    select: ['role_id', 'entity_name'],
    sort: 'entity_name:asc',
  }),
  cacheKey: [cacheKeys.person_roles, person.person_id],
})

const buildColumns = (
  person: PersonTypes,
): ColumnsType<Partial<RolesTypes>> => [
  {
    title: 'Role',
    render: (_, role) => (
      <CopyableLink
        label={role.entity_name}
        copyableText={role.role_id}
        to={`/${ROLES_SCREEN_PATH}/${role.role_id}`}
      />
    ),
    width: '100%',
  },
  {
    key: 'action',
    render: (_, role) => <RoleActionsDropdown role={role} person={person} />,
  },
]
