import { Button, PreLoader, Result } from '@swiftctrl/swift-component-library'
import { PropsWithChildren } from 'react'
import { buildAccountsAppUrl, useGetStaticData } from '../utils-hooks'
import { AxiosError } from '@swiftctrl/api-client'

type UserAuthorizationStatus = 'authorized' | 'loading' | number

export const UserAuthorizationGuard = ({ children }: PropsWithChildren) => {
  const status = useGetAuthorizationStatus()

  if (status === 'loading') {
    return <PreLoader spinProps={{ size: 'large' }} centered />
  }

  if (typeof status === 'number') {
    return renderErrorStatusResult(status)
  }

  return <>{children}</>
}

const useGetAuthorizationStatus = (): UserAuthorizationStatus => {
  const { isLoading, error } = useGetStaticData()

  if (isLoading) {
    return 'loading'
  }

  if (error) {
    const statusCode = (error as AxiosError).response?.status!

    return statusCode
  }

  return 'authorized'
}

const renderErrorStatusResult = (status: number) => {
  switch (status) {
    case 403:
      return (
        <Result
          status={status}
          title="You don't have access to this resource"
          extra={<SignOutButton />}
        />
      )

    default:
      return (
        <Result
          title={`Unhandled status code: ${status}`}
          subTitle="Please let your friendly ConfigCloud team know about this 🧑‍💻"
          extra={<SignOutButton />}
        />
      )
  }
}

const SignOutButton = () => {
  const url = buildAccountsAppUrl('signout')

  return (
    <a href={url}>
      <Button type="primary">Sign out</Button>
    </a>
  )
}
