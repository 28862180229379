import { EntityTypes, EntityTypesIds } from '@swiftctrl/api-client'
import { Layout, PreLoader } from '@swiftctrl/swift-component-library'
import { Collapse } from 'antd'
import styled from 'styled-components'
import { InfiniteScrollTrigger } from '../../../../components'
import { TABLE_CSS } from '../../../../data/models'
import {
  AddTableAction,
  FILTER_OR_INFO_BAR_HEIGHT,
  RoleSubDataTableHeader,
  ScrollContainer,
} from '../../manage-subdata'
import { RoleTargetView } from '../RoleTargetView'
import { useInfiniteBrowseTargets } from './hooks'
import { TargetChildrenBrowser } from './TargetChildrenBrowser'
import { isTargetInArray } from './utils'

type AllTargetsTableProps = {
  search: string
  entityTypeFilter: EntityTypesIds | undefined
  selectedTargets: Partial<EntityTypes>[]
  addTarget: (target: Partial<EntityTypes>) => void
}

export const AllTargetsTable = ({
  search,
  entityTypeFilter,
  selectedTargets,
  addTarget,
}: AllTargetsTableProps) => {
  const { error, data, fetchNextPage, isFetching } = useInfiniteBrowseTargets(
    search,
    entityTypeFilter,
  )

  if (error) {
    return <p>{String(error)}</p>
  }

  const targets = data?.pages.reduce(
    (targets, page) => [...targets, ...page.data],
    new Array<Partial<EntityTypes>>(),
  )

  return (
    <Container>
      <RoleSubDataTableHeader label="Target List" />
      <ScrollContainer>
        {targets && (
          <StyledCollapse destroyInactivePanel ghost>
            {targets.map((target) => {
              return (
                <Collapse.Panel
                  key={target.entity_id!}
                  header={
                    <Layout
                      horizontal
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <RoleTargetView target={target} />
                      <AddTableAction
                        showAddButton={
                          !isTargetInArray(target, selectedTargets)
                        }
                        onAdd={() => addTarget(target)}
                      />
                    </Layout>
                  }
                >
                  <TargetChildrenBrowser target={target} />
                </Collapse.Panel>
              )
            })}
          </StyledCollapse>
        )}
        {isFetching && (
          <Layout paddingTop="medium" alignItems="center">
            <PreLoader spinProps={{ size: 'small' }} />
          </Layout>
        )}
        <InfiniteScrollTrigger callback={fetchNextPage} />
      </ScrollContainer>
    </Container>
  )
}

const Container = styled.div`
  height: calc(100% - ${FILTER_OR_INFO_BAR_HEIGHT});
  border: ${TABLE_CSS.BORDER};
`

const StyledCollapse = styled(Collapse)`
  .ant-collapse-item > .ant-collapse-header {
    align-items: center;
    border-bottom: ${TABLE_CSS.BORDER};
    padding: 16px;
  }

  .ant-collapse-content-box {
    border-bottom: ${TABLE_CSS.BORDER};
  }
`
