import { ProviderProfileTypes } from '@swiftctrl/api-client'
import { ColumnsType } from '@swiftctrl/swift-component-library'
import { ReactNode } from 'react'
import {
  BrowseEntitiesConfig,
  BrowseEntitiesContextProvider,
} from '../../../../states'
import {
  CopyableCodeDisplay,
  CreatedUpdatedDateDisplay,
} from '../../../data-display'
import {
  EntitiesTableBrowser,
  TABLE_DATA_PER_FETCH,
} from '../../EntitiesTableBrowser'
import { LoginDataDisplay } from './LoginDataDisplay'
import { ProviderProfileActionsDropdown } from './ProviderProfileActionsDropdown'
import { ProviderProfileProfileDisplay } from './ProviderProfileProfileDisplay'
import { ProviderProfileProviderDisplay } from './ProviderProfileProviderDisplay'

type BaseProviderProfilesBrowserProps = {
  browse: BrowseEntitiesConfig<ProviderProfileTypes>
  uniqueValues: UniqueValues
  displayAddButton?: boolean
  addButtonLabel?: string
  onAddButtonClick?: () => void
  addView?: ReactNode
  addDataTemplate?: object | undefined
}

type UniqueValues = 'providers' | 'profiles'

const BaseProviderProfilesBrowser = ({
  browse,
  uniqueValues: mainColumn,
  displayAddButton = true,
  addButtonLabel,
  onAddButtonClick,
  addView,
  addDataTemplate,
}: BaseProviderProfilesBrowserProps) => {
  const columns = buildColumns(mainColumn)

  return (
    <BrowseEntitiesContextProvider
      dataPerFetch={TABLE_DATA_PER_FETCH}
      browse={browse}
      dataKey="provider_profile_id"
      searchable
      entityType="provider_profile"
      displayAddButton={displayAddButton}
      addButtonLabel={addButtonLabel}
      onAddButtonClick={onAddButtonClick}
      addDataTemplate={addDataTemplate}
    >
      <EntitiesTableBrowser columns={columns} />
      {addView}
    </BrowseEntitiesContextProvider>
  )
}

const buildColumns = (
  uniqueValues: UniqueValues,
): ColumnsType<Partial<ProviderProfileTypes>> => [
  {
    title: 'ID',
    render: (_, providerProfile) => {
      const { provider_profile_id } = providerProfile

      return <CopyableCodeDisplay label={provider_profile_id!} />
    },
    width: '25%',
  },
  uniqueValues === 'providers'
    ? {
        title: 'Provider',
        render: (_, providerProfile) => (
          <ProviderProfileProviderDisplay providerProfile={providerProfile} />
        ),
        width: '25%',
      }
    : {
        title: 'Profile',
        render: (_, providerProfile) => (
          <ProviderProfileProfileDisplay providerProfile={providerProfile} />
        ),
        width: '25%',
      },
  {
    title: 'Login Data',
    render: (_, providerProfile) => (
      <LoginDataDisplay providerProfile={providerProfile} />
    ),
    width: '25%',
  },
  {
    title: 'Created / Updated Date',
    render: (_, providerProfile) => {
      const { created_at, updated_at } = providerProfile

      return (
        <CreatedUpdatedDateDisplay
          createdAt={created_at}
          updatedAt={updated_at}
        />
      )
    },
    width: '25%',
  },
  {
    key: 'action',
    render: (_, providerProfile) => (
      <ProviderProfileActionsDropdown providerProfile={providerProfile} />
    ),
  },
]

const commonSelect: (keyof ProviderProfileTypes)[] = [
  'provider_profile_id',
  'login_data',
  'login_attempts',
  'multi_factor_code_expiry',
  'created_at',
  'updated_at',
]

const selectForUniqueProviders: (keyof ProviderProfileTypes)[] = [
  ...commonSelect,
  'overseer_name',
  'overseer_id',
]

const selectForUniqueProfiles: (keyof ProviderProfileTypes)[] = [
  ...commonSelect,
  'display_name',
  'profile_id',
]

BaseProviderProfilesBrowser.selectForUniqueProviders = selectForUniqueProviders

BaseProviderProfilesBrowser.selectForUniqueProfiles = selectForUniqueProfiles

export { BaseProviderProfilesBrowser }
