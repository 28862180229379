import { UseQueryResult } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

export const useQueryResultStatefulError = <ResponseDataType>(
  useQueryResult: UseQueryResult<ResponseDataType, Error>,
) => {
  const { error: resultError, ...result } = useQueryResult

  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    setError(resultError)
  }, [resultError, setError])

  const resetError = () => {
    setError(null)
  }

  return { ...result, error, resetError }
}
