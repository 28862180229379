import {
  CredentialTemplateAddTypes,
  CredentialTemplateTypes,
} from '@swiftctrl/api-client'
import {
  Alert,
  Button,
  Form,
  Input,
  Layout,
  Modal,
  Select,
} from '@swiftctrl/swift-component-library'
import { useMutation } from '@tanstack/react-query'
import { isEqual } from 'lodash'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { CREDENTIAL_TEMPLATES_SCREEN_PATH } from '../../../../config/paths'
import { queryClient } from '../../../../data/queryClient'
import { swiftClientWithoutErrorHandling } from '../../../../data/swiftClient'
import {
  buildCommonFormProps,
  buildCommonModalProps,
  cacheKeys,
  showSuccessNotification,
  trimValues,
  useCheckBeforeClose,
} from '../../../../utils-hooks'
import { ErrorAlert } from '../../../ErrorAlert'

type DuplicateCredentialTemplateViewProps = {
  open: boolean
  credentialTemplate: CredentialTemplateTypes
  hasUnsavedChanges?: boolean
  onClose: () => void
}

type FormData = {
  credentialFormatId?: string
  overseerId?: string
  name?: string
  description?: string
}

export const DuplicateCredentialTemplateView = ({
  open,
  credentialTemplate,
  hasUnsavedChanges,
  onClose,
}: DuplicateCredentialTemplateViewProps) => {
  const {
    credential_format_id,
    credential_format_name,
    overseer_id,
    overseer_name,
    template_name,
    credential_field_configs,
  } = credentialTemplate

  const initialValues = {
    credentialFormatId: credential_format_id,
    overseerId: overseer_id,
    name: `Copy of ${template_name}`,
  }

  const [values, setValues] = useState<FormData>(initialValues)

  const resetValues = () => setValues(initialValues)

  const navigate = useNavigate()

  const { mutate, isPending, error } = useMutation({
    mutationFn: () => {
      const { name, description } = trimValues(values)

      const payload: CredentialTemplateAddTypes = {
        credential_format_id,
        credential_field_configs,
        template_name: name!,
        template_description: description || '',
      }

      return swiftClientWithoutErrorHandling.credentialTemplate.add(payload)
    },
    onSuccess: (response) => {
      showSuccessNotification('Credential template duplicated')

      resetValues()

      queryClient.invalidateQueries({
        queryKey: [cacheKeys.credential_templates],
      })

      const { credential_format_config_id } = response.data

      navigate(
        `/${CREDENTIAL_TEMPLATES_SCREEN_PATH}/${credential_format_config_id}`,
      )
    },
  })

  const { checkBeforeClose } = useCheckBeforeClose({
    isPending,
    isDirty: !isEqual(values, initialValues),
    reset: resetValues,
    close: onClose,
  })

  return (
    <Modal
      {...buildCommonModalProps({
        title: 'Duplicate Credential Template',
        open,
        onCancel: checkBeforeClose,
      })}
    >
      <Form
        {...buildCommonFormProps({
          values,
          setValues,
          isPending,
          onFinish: mutate,
        })}
      >
        <Form.Item
          name="credentialFormatId"
          label="Core Credential Format"
          required
        >
          <Select
            disabled
            options={[
              {
                value: credential_format_id,
                label: credential_format_name,
              },
            ]}
            showLabel={false}
          />
        </Form.Item>
        <Form.Item name="overseerId" label="Overseer" required>
          <Select
            disabled
            options={[
              {
                value: overseer_id,
                label: overseer_name,
              },
            ]}
            showLabel={false}
          />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Name is required',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input.TextArea rows={4} />
        </Form.Item>
        {hasUnsavedChanges && (
          <Form.Item>
            <StyledAlert
              type="info"
              showIcon
              message="There are unsaved changes. These will be included in the duplicated credential template."
            />
          </Form.Item>
        )}
        {error && (
          <Form.Item>
            <ErrorAlert error={error} />
          </Form.Item>
        )}
        <Layout horizontal justifyContent="end" gap="small">
          <Button onClick={checkBeforeClose}>Cancel</Button>
          <Button type="primary" htmlType="submit" loading={isPending}>
            Duplicate
          </Button>
        </Layout>
      </Form>
    </Modal>
  )
}

const StyledAlert = styled(Alert)`
  align-items: flex-start;

  .ant-alert-icon {
    margin-top: 4px;
  }
`
