import { Collapse, Divider } from 'antd'
import { styled } from 'styled-components'
import { FavoriteOrganizationsBrowser } from './FavoriteOrganizationsBrowser'
import {
  getFavoriteOrganizations,
  getFavoriteOrganizationsExpandedState,
  setFavoriteOrganizationsExpandedState,
} from './utils'

const COLLAPSE_PANEL_KEY = 'favorites'

export const FavoriteOrganizationsView = () => {
  const { favoriteOrganizationsExpanded } =
    getFavoriteOrganizationsExpandedState()

  const handleCollapseChange = (key: string | string[]) => {
    const expanded =
      key === COLLAPSE_PANEL_KEY || key.includes(COLLAPSE_PANEL_KEY)

    setFavoriteOrganizationsExpandedState(expanded)
  }

  const favoriteOrganizations = getFavoriteOrganizations()

  return (
    <StyledCollapse
      ghost
      defaultActiveKey={
        favoriteOrganizationsExpanded ? COLLAPSE_PANEL_KEY : undefined
      }
      onChange={handleCollapseChange}
    >
      <Collapse.Panel
        key={COLLAPSE_PANEL_KEY}
        header={`Favorites (${favoriteOrganizations.length})`}
      >
        <FavoriteOrganizationsBrowser />
        <StyledDivider />
      </Collapse.Panel>
    </StyledCollapse>
  )
}

const StyledCollapse = styled(Collapse)`
  .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }
`

const StyledDivider = styled(Divider)`
  margin: 16px 0px;
`
