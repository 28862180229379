import { Col, Row } from '@swiftctrl/swift-component-library'
import { PropsWithChildren, ReactNode } from 'react'
import styled from 'styled-components'
import { useBrowseEntitiesContext } from '../../states'
import { FieldKey } from '../../utils-hooks'
import { EmptyPlaceholder } from './EmptyPlaceholder'
import { EntitiesBrowser } from './EntitiesBrowser'

type EntitiesCardsBrowserProps<T> = {
  render: (data: T) => ReactNode
}

export const CARDS_DATA_PER_FETCH = 48

const EntitiesCardsBrowser = <T,>({ render }: EntitiesCardsBrowserProps<T>) => {
  const { entityType } = useBrowseEntitiesContext()

  return (
    <EntitiesBrowser
      render={(dataSource: T[], dataKey: FieldKey<T>) => (
        <GutteredRow>
          {dataSource.length === 0 ? (
            <EmptyPlaceholder entityType={entityType} />
          ) : (
            dataSource.map((data) => (
              <StyledCol key={String(data[dataKey])}>{render(data)}</StyledCol>
            ))
          )}
        </GutteredRow>
      )}
    />
  )
}

const GutteredRow = ({ children }: PropsWithChildren) => (
  <Row gutter={[16, 16]}>{children}</Row>
)

const DynamicCol = ({ children, ...props }: PropsWithChildren) => (
  <Col xs={24} xl={8} md={12} {...props}>
    {children}
  </Col>
)

const StyledCol = styled(DynamicCol)`
  display: flex;
`

EntitiesCardsBrowser.Row = GutteredRow

EntitiesCardsBrowser.Col = StyledCol

export { EntitiesCardsBrowser }
