import { SelectProps } from '@swiftctrl/swift-component-library'

/**
 * Checks whether the `option` should be included or not based on
 * `search`.
 */
export const commonFilterOption: SelectProps['filterOption'] = (
  search,
  option,
) => {
  if (!option) {
    return false
  }

  const { value, label } = option

  const formattedSearch = search.trim().toLocaleLowerCase()

  return (
    String(value).toLocaleLowerCase().includes(formattedSearch) ||
    String(label).toLocaleLowerCase().includes(formattedSearch)
  )
}
