import { CommonEntityTypes, EntityTypes } from '@swiftctrl/api-client'
import { FetchQueryOptions, useQuery } from '@tanstack/react-query'
import { queryClient } from '../data/queryClient'
import {
  swiftClient,
  swiftClientWithoutErrorHandling,
} from '../data/swiftClient'
import { cacheKeys, longCacheOptions } from './cacheKeysUtils'
import { getEntityId } from './getEntityId'

type Params = {
  entity: CommonEntityTypes
}

export const useGetOverseerOrganization = ({ entity }: Params) =>
  useQuery(buildOptions(getEntityId(entity, entity.entity_type_id), true))

const buildOptions = (
  entityId: string,
  showErrorNotification: boolean,
): FetchQueryOptions<EntityTypes> => {
  const client = showErrorNotification
    ? swiftClient
    : swiftClientWithoutErrorHandling

  return {
    queryKey: [cacheKeys.OverseerOrganization, entityId],
    queryFn: async ({ signal }) => {
      let overseer = { overseer_id: entityId } as EntityTypes

      do {
        let response = await client.entity
          .at(overseer.overseer_id)
          .read({ signal })

        overseer = response.data
      } while (overseer?.entity_type_id !== 'organization')

      return overseer
    },
    ...longCacheOptions,
  }
}

export const getOverseerOrganization = (entityId: string) =>
  queryClient.fetchQuery(buildOptions(entityId, false))
