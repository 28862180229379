import { CredentialTemplateFieldValidValue } from '@swiftctrl/api-client'
import { Button, Layout } from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'
import { useBoolean } from '../../../utils-hooks'
import { AddConstraintButton } from '../AddConstraintButton'
import { RemoveConstraintButton } from '../RemoveConstraintButton'
import { CredentialTemplateFieldConstraintViewProps } from '../utils'
import {
  CustomValidValuesEditorModal,
  CustomValidValuesEntryType,
  CustomValidValuesValidator,
} from './CustomValidValuesEditorModal'

type CustomValidValuesViewProps = {
  templateValidValues: CredentialTemplateFieldValidValue[]
  customValidValuesValidator?: CustomValidValuesValidator
  customValidValuesEntryType: CustomValidValuesEntryType
  onChange: CredentialTemplateFieldConstraintViewProps['onChange']
}

export const CustomValidValuesView = ({
  templateValidValues,
  customValidValuesValidator,
  customValidValuesEntryType,
  onChange,
}: CustomValidValuesViewProps) => {
  const [isModalOpen, openModal, closeModal] = useBoolean()

  const handleSave = (values: CredentialTemplateFieldValidValue[]) => {
    if (values.length > 0) {
      onChange({ valid_values: values, date_range: null, integer_range: null })
    } else {
      onChange(null)
    }

    closeModal()
  }

  const handleRemoveConstraint = () => {
    onChange(null)
  }

  return (
    <Layout horizontal alignItems="center" gap="medium">
      {templateValidValues.length === 0 ? (
        <AddConstraintButton onClick={openModal} />
      ) : (
        <>
          valid_values
          <StyledButton type="link" size="small" onClick={openModal}>
            {getValidValuesLabel(templateValidValues.length)}
          </StyledButton>
          <RemoveConstraintButton onClick={handleRemoveConstraint} />
        </>
      )}
      <CustomValidValuesEditorModal
        key={JSON.stringify(templateValidValues)}
        open={isModalOpen}
        validValues={templateValidValues}
        validator={customValidValuesValidator}
        type={customValidValuesEntryType}
        onClose={closeModal}
        onSave={handleSave}
      />
    </Layout>
  )
}

const StyledButton = styled(Button)`
  padding: 0px;

  > span {
    text-decoration: underline;

    text-underline-offset: 3px;
  }
`

const getValidValuesLabel = (validValuesCount: number) => {
  const label = validValuesCount === 1 ? 'value' : 'values'

  const validValuesLabel = `${validValuesCount} ${label}`

  return validValuesLabel
}
