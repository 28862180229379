import {
  EntityBrowseTypes,
  EntityTypes,
  EntityTypesIds,
} from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { Layout, PreLoader } from '@swiftctrl/swift-component-library'
import { useInfiniteQuery } from '@tanstack/react-query'
import { Collapse } from 'antd'
import { styled } from 'styled-components'
import {
  buildEntityBrowserNextPageParam,
  buildEntityTypeFilter,
  cacheKeys,
  flattenInfiniteQueryData,
} from '../../utils-hooks'
import { ErrorAlert } from '../ErrorAlert'
import { InfiniteScrollTrigger } from '../InfiniteScrollTrigger'
import { ReadScreenLink } from '../navigation'
import { EntityDisplay } from './EntityDisplay'
import { browseChildren } from './useBrowseChildren'

type EntityChildrenListProps = {
  isFetching: boolean
  error: Error | null
  data: Partial<EntityTypes>[] | undefined
  fetchNextPage: () => void
}

const childrenTypes: EntityTypesIds[] = [
  'access_level',
  'access_level_credential',
  'acs_hardware',
  'building',
  'controller',
  'entity',
  'epi',
  'organization',
  'provider',
  'reader',
  'role',
  'room',
  'system',
]

export const EntityChildrenList = ({
  isFetching,
  error,
  data,
  fetchNextPage,
  ...props
}: EntityChildrenListProps) => {
  if (error) {
    return <ErrorAlert error={error} {...props} />
  }

  if (data?.length === 0) {
    return <div {...props}>No children to show</div>
  }

  return (
    <div {...props}>
      {data && (
        <StyledCollapse ghost>
          {data.map((child) => {
            const { entity_id, entity_type_id } = child

            return (
              <Collapse.Panel
                key={entity_id!}
                header={
                  <StyledLayout horizontal gap="xSmall">
                    <EntityDisplay entity={child} />
                    <StyledReadScreenLink
                      entityType={entity_type_id!}
                      entityId={entity_id!}
                    />
                  </StyledLayout>
                }
                collapsible="icon"
              >
                <ChildChildrenView entityId={entity_id!} />
              </Collapse.Panel>
            )
          })}
        </StyledCollapse>
      )}
      {isFetching && (
        <Layout horizontal justifyContent="center" grow>
          <PreLoader spinProps={{ size: 'small' }} />
        </Layout>
      )}
      <InfiniteScrollTrigger callback={fetchNextPage} />
    </div>
  )
}

const StyledCollapse = styled(Collapse)`
  > div {
    padding-bottom: ${({ theme }) => theme.spacing.medium};

    &:last-of-type {
      padding-bottom: 0px;
    }

    &.ant-collapse-item {
      .ant-collapse-header {
        padding: 0px;
      }

      .ant-collapse-content > .ant-collapse-content-box {
        padding-bottom: 0px;
      }
    }
  }
`

const StyledLayout = styled(Layout)``

const StyledReadScreenLink = styled(ReadScreenLink)`
  display: none;

  ${StyledLayout}:hover & {
    display: block;
  }
`

const ChildChildrenView = ({ entityId }: { entityId: string }) => {
  const { isFetching, error, data, fetchNextPage } = useBrowseChildren(entityId)

  const flattenedData = flattenInfiniteQueryData(data)

  return (
    <EntityChildrenList
      isFetching={isFetching}
      error={error}
      data={flattenedData}
      fetchNextPage={fetchNextPage}
    />
  )
}

const useBrowseChildren = (entityId: string) =>
  useInfiniteQuery({
    queryKey: [cacheKeys.EntityChildrenList_children, entityId],
    queryFn: ({ pageParam, signal }) => {
      const q = getQueryHandler<EntityBrowseTypes>()

      const filters = [
        buildEntityTypeFilter(...childrenTypes),
        q('AND', 'overseer_id', 'EQ', entityId),
      ]

      return browseChildren(entityId, filters, pageParam, signal)
    },
    initialPageParam: 0,
    getNextPageParam: buildEntityBrowserNextPageParam,
  })
