import {
  ProfilesTypes,
  ProviderProfileBrowseTypes,
  ProviderProfileTypes,
} from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { BaseProviderProfilesBrowser } from '../../components'
import { AddProviderProfileView } from '../../components/browse-entities/entity-types/provider-profile/AddProviderProfileView'
import { swiftClientWithoutErrorHandling } from '../../data/swiftClient'
import { BrowseEntitiesConfig } from '../../states'
import { adaptSDKBrowseRequest, cacheKeys, useBoolean } from '../../utils-hooks'

type ProfileProviderProfilesBrowserProps = {
  organizationId: string
  profile: ProfilesTypes
}

export const ProfileProviderProfilesBrowser = ({
  organizationId,
  profile,
}: ProfileProviderProfilesBrowserProps) => {
  const browse = buildBrowse(profile.profile_id)

  const [isAddViewOpen, openAddView, closeAddView] = useBoolean()

  return (
    <BaseProviderProfilesBrowser
      browse={browse}
      uniqueValues="providers"
      onAddButtonClick={openAddView}
      addView={
        <AddProviderProfileView
          organizationId={organizationId}
          profile={profile}
          open={isAddViewOpen}
          onClose={closeAddView}
        />
      }
    />
  )
}

const buildBrowse = (
  profileId: string,
): BrowseEntitiesConfig<ProviderProfileTypes> => ({
  request: adaptSDKBrowseRequest({
    request: swiftClientWithoutErrorHandling.providerProfile.browse,
    select: BaseProviderProfilesBrowser.selectForUniqueProviders,
    getSearch: (search) => search,
    getFilters: () => {
      const q = getQueryHandler<ProviderProfileBrowseTypes>()

      return [q('WHERE', 'profile_id', 'EQ', profileId)]
    },
  }),
  cacheKey: [cacheKeys.provider_profiles, profileId],
})
