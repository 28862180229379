import {
  ProfilesTypes,
  ProviderProfileAddTypes,
  ProviderType,
} from '@swiftctrl/api-client'
import { useMutation } from '@tanstack/react-query'
import { formatRFC3339 } from 'date-fns'
import { isEqual } from 'lodash'
import { useState } from 'react'
import { queryClient } from '../../../../data/queryClient'
import {
  BackendError,
  swiftClientWithoutErrorHandling,
} from '../../../../data/swiftClient'
import {
  buildProviderProfileName,
  trimValues,
  cacheKeys,
  showSuccessNotification,
  useCheckBeforeClose,
} from '../../../../utils-hooks'
import { AddProviderProfileFormData } from './utils'

export const useAddProviderProfileLogic = (
  profile: ProfilesTypes,
  onClose: () => void,
) => {
  const initialValues: AddProviderProfileFormData = {}

  const [values, setValues] =
    useState<AddProviderProfileFormData>(initialValues)

  const [error, setError] = useState<BackendError | undefined>()

  const reset = () => {
    setValues(initialValues)

    setError(undefined)
  }

  const { mutate, isPending } = useMutation({
    mutationFn: (data: AddProviderProfileFormData) => {
      const { provider } = data

      const trimmedValues = trimValues(values)

      const payload = {
        entity_name: buildProviderProfileName(providerType!, trimmedValues),
        overseer_id: provider!.provider_id,
        profile_id: profile.profile_id,
      } as ProviderProfileAddTypes

      switch (providerType) {
        case 'email_password':
          payload.login_data = {
            email_password: {
              email: trimmedValues['login_data.email_password.email']!,
              password: trimmedValues['login_data.email_password.password']!,
              verified: true,
            },
          }

          break

        case 'openid':
        case 'openid_okta':
          payload.login_data = {
            openid: {
              sub: trimmedValues['login_data.openid.sub']!,
            },
          }

          break

        case 'single_use_token':
          const expiry = trimmedValues['login_data.single_use_token.expiry']

          payload.login_data = {
            single_use_token: {
              token: trimmedValues['login_data.single_use_token.token']!,
              expiry: expiry ? formatRFC3339(expiry) : '',
            },
          }

          break

        case 'proxy_login':
          payload.login_data = {
            proxy_login: {
              identifier: trimmedValues['login_data.proxy_login.identifier']!,
            },
          }

          break
      }

      return swiftClientWithoutErrorHandling.providerProfile.add(payload)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [cacheKeys.provider_profiles],
      })

      queryClient.invalidateQueries({
        queryKey: [cacheKeys.personProviderProfiles],
      })

      queryClient.invalidateQueries({
        queryKey: [cacheKeys.person_emails],
      })

      showSuccessNotification('Provider profile added')

      reset()

      onClose()
    },
    onError: setError,
  })

  const isDirty = !isEqual(values, initialValues)

  const { checkBeforeClose } = useCheckBeforeClose({
    isPending,
    isDirty,
    close: onClose,
    reset,
  })

  const handleFinish = (values: AddProviderProfileFormData) => {
    setError(undefined)

    mutate(values)
  }

  const providerType = values.provider?.provider_type.name

  const isValid = validate(values, providerType)

  return {
    values,
    setValues,
    error,
    checkBeforeClose,
    handleFinish,
    isPending,
    isValid,
    providerType,
    profileEmail: profile.email,
  }
}

const validate = (
  values: AddProviderProfileFormData,
  providerType: ProviderType | undefined,
) => {
  const trimmedValues = trimValues(values)

  const { provider } = trimmedValues

  if (!provider) {
    return false
  }

  switch (providerType) {
    case 'email_password':
      if (
        !trimmedValues['login_data.email_password.email'] ||
        !trimmedValues['login_data.email_password.password']
      ) {
        return false
      }

      break

    case 'openid':
    case 'openid_okta':
      if (!trimmedValues['login_data.openid.sub']) {
        return false
      }

      break

    case 'single_use_token':
      if (!trimmedValues['login_data.single_use_token.expiry']) {
        return false
      }

      break

    case 'proxy_login':
      if (!trimmedValues['login_data.proxy_login.identifier']) {
        return false
      }
  }

  return true
}
