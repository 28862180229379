import {
  CredentialFormatsBrowseTypes,
  CredentialFormatsTypes,
} from '@swiftctrl/api-client'
import { FilterParams, getQueryHandler } from '@swiftctrl/api-helpers'
import { Layout, Tooltip, Typography } from '@swiftctrl/swift-component-library'
import { useState } from 'react'
import { swiftClient } from '../../../../data/swiftClient'
import { isValidUuid, mapDataToSelectOptions } from '../../../../utils-hooks'
import { BaseLiveSearch } from '../../../add-entities'

type CredentialFormatSelectProps = {
  baseId: string
  onChange?: (value: string) => void
}

export const CredentialFormatSelect = ({
  baseId,
  onChange,
}: CredentialFormatSelectProps) => {
  const [value, setValue] = useState('')

  const handleChange = (value: string) => {
    setValue(value)

    onChange?.(value)
  }

  const fetchCredentialFormats = async (search: string) => {
    const filters = new Array<FilterParams<CredentialFormatsBrowseTypes>>()

    const q = getQueryHandler<CredentialFormatsBrowseTypes>()

    const trimmedSearch = search.trim()

    if (isValidUuid(trimmedSearch)) {
      filters.push(
        q('WHERE', 'credential_format_id', 'EQ', trimmedSearch),
        q('OR', 'overseer_id', 'EQ', trimmedSearch),
      )
    } else {
      filters.push(q('WHERE', 'entity_name', 'EQ_IC', trimmedSearch))
    }

    const { data } = await swiftClient.credential.formats.browse({
      baseId,
      select: ['credential_format_id', 'entity_name', 'overseer_name'],
      filters,
    })

    const options = mapDataToSelectOptions(
      data,
      'credential_format_id',
      buildLabel,
      true,
    )

    return options
  }

  return (
    <BaseLiveSearch
      value={value}
      onChange={handleChange}
      fetchOptions={fetchCredentialFormats}
      fetchDependencies={[baseId]}
      size="large"
    />
  )
}

const buildLabel = (format: Partial<CredentialFormatsTypes>) => {
  const { entity_name, overseer_name } = format

  return (
    <Tooltip
      title={
        <Layout gap="small">
          <div>{entity_name}</div>
          <div>Overseer: {overseer_name}</div>
        </Layout>
      }
      placement="left"
    >
      {entity_name}
      <Typography.Text type="secondary">{` (Overseer: ${overseer_name})`}</Typography.Text>
    </Tooltip>
  )
}
