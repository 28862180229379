import {
  AxiosResponse,
  LandlordAddTypes,
  OrganizationTypes,
} from '@swiftctrl/api-client'
import { useMutation } from '@tanstack/react-query'
import { swiftClientWithoutErrorHandling } from '../../data/swiftClient'
import { trimValues } from '../../utils-hooks'
import { AddOrganizationFormData } from './models'

export const useAddOrganization = ({
  onSuccess,
}: {
  onSuccess: (response: AxiosResponse<OrganizationTypes>) => void
}) =>
  useMutation({
    mutationFn: (values: AddOrganizationFormData) => {
      const { name, short_name, tag } = trimValues(values)

      const payload: LandlordAddTypes = {
        organization_name: name!,
        organization_short_name: short_name!.toLocaleUpperCase(),
        organization_tag: tag!,
      }

      return swiftClientWithoutErrorHandling.landlord.add(payload)
    },
    onSuccess,
  })
