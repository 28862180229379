import { Layout, Modal, Typography } from '@swiftctrl/swift-component-library'
import { ReactNode } from 'react'
import { styled } from 'styled-components'

const MODAL_HEADER_HEIGHT = '55px'

const MODAL_FOOTER_HEIGHT = '61px'

const DESCRIPTION_HEIGHT = '52px'

interface RoleSubDataManagerModalProps {
  open: boolean
  onClose: () => void
  title: string
  description: string
  filtersBar: ReactNode
  candidatesSubDataTable: ReactNode
  infoBar: ReactNode
  selectedSubDataTable: ReactNode
  saveButtonState: {
    disabled: boolean
    loading: boolean
  }
  onSave: () => void
}

export const RoleSubDataManagerModal = ({
  open,
  onClose,
  title,
  description,
  filtersBar,
  candidatesSubDataTable,
  infoBar,
  selectedSubDataTable,
  saveButtonState,
  onSave,
}: RoleSubDataManagerModalProps) => {
  return (
    <StyledModal
      title={title}
      destroyOnClose
      open={open}
      onCancel={onClose}
      okText="Save"
      maskClosable
      width="calc(100% - 3em)"
      centered
      okButtonProps={{
        size: 'middle',
        disabled: saveButtonState.disabled,
        loading: saveButtonState.loading,
      }}
      onOk={onSave}
      cancelButtonProps={{
        size: 'middle',
      }}
    >
      <Layout gap="medium" grow>
        <Typography.Paragraph type="secondary">
          {description}
        </Typography.Paragraph>
        <StyledLayout gap="xxLarge" horizontal grow>
          <Layout gap="medium" grow>
            {filtersBar}
            {candidatesSubDataTable}
          </Layout>
          <Layout gap="medium" grow>
            {infoBar}
            {selectedSubDataTable}
          </Layout>
        </StyledLayout>
      </Layout>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    height: calc(100vh - 3em);

    .ant-modal-body {
      height: calc(100% - ${MODAL_HEADER_HEIGHT} - ${MODAL_FOOTER_HEIGHT});
    }
  }
`

const StyledLayout = styled(Layout)`
  height: calc(100% - ${DESCRIPTION_HEIGHT});
`
