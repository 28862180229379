import { EntityTypes } from '@swiftctrl/api-client'
import { DownOutlined } from '@swiftctrl/swift-component-library'
import { Tree } from 'antd'
import { DataNode } from 'antd/lib/tree'
import { arrayToTree } from 'performant-array-to-tree'
import { useEffect, useRef, useState } from 'react'
import { SWIFT_ROOT_ENTITY_ID } from '../../../data/models'
import {
  GET_HEIGHT_DEBOUNCE_TIME,
  useGetHeightProps,
  useGetValidUuidParam,
  useHandleUrlParams,
} from '../../../utils-hooks'
import { EntityTreeNodeDisplay } from './EntityTreeNodeDisplay'
import { findParentKeysPath } from './findParentKeysPath'
import { getEntitiesWithChildren } from './getEntitiesWithChildren'
import { getInitialExpandedKeys } from './getInitialExpandedKeys'

type TreeKey = string | number | bigint

type EntityTreeProps = {
  entities: EntityTypes[]
  onNumChildrenChange: (numChildren: number) => void
}

type EntityTreeNode = {
  data: EntityTypes
  children: EntityTreeNode[]
}

export const EntityTree = ({
  entities,
  onNumChildrenChange,
  ...props
}: EntityTreeProps) => {
  const { currentParams, updateParams, cleanParams } = useHandleUrlParams(false)

  const selectedEntityId = useGetValidUuidParam({
    paramKey: 'entity-id',
    validator: (param) => validateSelectedEntityId(param, entities),
    validatorErrorNotificationType: 'noEntity',
  })

  const search = currentParams.search?.trim() || ''

  const treeRef = useRef<any>(null)

  const [entityTree, setEntityTree] = useState(
    transformEntityArrayToTree(entities, search),
  )

  const [expandedKeys, setExpandedKeys] = useState<TreeKey[]>(
    getInitialExpandedKeys(entityTree, selectedEntityId, search),
  )

  const { treeProps } = useGetHeightProps()

  const setSelectedEntity = (
    selectedKeys: TreeKey[],
    {
      selectedNodes,
    }: {
      selectedNodes: DataNode[]
    },
  ) => {
    if (selectedKeys.length === 0) {
      cleanParams(['entity-id'])

      return
    }

    const selectedEntityId = selectedKeys[0] as string

    updateParams({ 'entity-id': selectedEntityId })

    const selectedEntityNumChildren = selectedNodes[0].children?.length || 0

    onNumChildrenChange(selectedEntityNumChildren)
  }

  useEffect(() => {
    const newEntityTree = transformEntityArrayToTree(entities, search)

    setEntityTree(newEntityTree)

    if (search) {
      setExpandedKeys(getEntitiesWithChildren(newEntityTree))
    } else {
      const selectedKeyPath = findParentKeysPath(
        newEntityTree,
        selectedEntityId,
      )

      setExpandedKeys(
        selectedKeyPath ||
          getInitialExpandedKeys(newEntityTree, selectedEntityId, search),
      )
    }

    if (!selectedEntityId) {
      return
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     * If `selectedEntityId` is included in the dependencies, then the entity
     * collapses when it's deselected, which is undesired behavior.
     */
  }, [entities, search])

  useEffect(() => {
    if (selectedEntityId) {
      setTimeout(() => {
        treeRef.current?.scrollTo({
          key: selectedEntityId,
          align: 'top',
          offset: 50,
        })
      }, GET_HEIGHT_DEBOUNCE_TIME)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     * If `selectedEntityId` is included in the dependencies, then the scroll
     * occurs anytime an entity is selected, which is undesired behavior.
     */
  }, [entities, search])

  if (entityTree.length === 0) {
    return <span>No entities found</span>
  }

  return (
    <Tree
      ref={treeRef}
      treeData={entityTree}
      expandedKeys={expandedKeys}
      onSelect={setSelectedEntity}
      onExpand={setExpandedKeys}
      showLine
      switcherIcon={<DownOutlined />}
      selectedKeys={[selectedEntityId]}
      {...treeProps}
      {...props}
    />
  )
}

const filterNodesByCriteria = (
  treeNodes: EntityTreeNode[],
  searchCriteria: string,
): EntityTreeNode[] => {
  if (!searchCriteria) {
    return treeNodes
  }
  return treeNodes
    .map((item) => {
      const data: EntityTypes = { ...item.data }

      const children: EntityTreeNode[] = item.children
        ? filterNodesByCriteria(item.children, searchCriteria)
        : []

      const lowerCaseCriteria = searchCriteria.toLowerCase()

      const lowerCaseEntityName = data.entity_name.toLowerCase()
      if (
        lowerCaseEntityName.includes(lowerCaseCriteria) ||
        children.length > 0
      ) {
        return { data, children }
      } else {
        return null
      }
    })
    .filter((item) => item !== null) as EntityTreeNode[]
}

const transformEntityArrayToTree = (
  entities: EntityTypes[],
  search: string,
) => {
  let rootEntity: EntityTypes | undefined = undefined

  const descendantEntities = new Array<EntityTypes>()

  entities.forEach((entity) => {
    if (entity.entity_id === SWIFT_ROOT_ENTITY_ID) {
      rootEntity = entity
    } else {
      descendantEntities.push(entity)
    }
  })

  const descendantTree = arrayToTree(descendantEntities, {
    id: 'entity_id',
    parentId: 'overseer_id',
    rootParentIds: { [SWIFT_ROOT_ENTITY_ID]: true },
  }) as EntityTreeNode[]

  const tree: EntityTreeNode[] = [
    {
      data: rootEntity!,
      children: descendantTree,
    },
  ]

  const filteredTree = filterNodesByCriteria(tree, search)

  if (filteredTree.length === 0) {
    return []
  }

  const orgData = transformNodeToChartData(filteredTree[0])

  return [orgData]
}

const transformNodeToChartData = ({
  data,
  children,
}: EntityTreeNode): DataNode => ({
  title: <EntityTreeNodeDisplay entity={data} numChildren={children.length} />,
  key: data.entity_id,
  children: children.map(transformNodeToChartData),
})

const validateSelectedEntityId = (
  selectedEntityIdParam: string | undefined,
  entities: EntityTypes[],
) => {
  if (!selectedEntityIdParam) {
    return ''
  }

  const entity = entities.find(
    (entity) => entity.entity_id === selectedEntityIdParam,
  )

  if (!entity) {
    return ''
  }

  return selectedEntityIdParam
}
