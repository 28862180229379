import { EntityTypes } from '@swiftctrl/api-client'
import {
  Empty,
  PreLoader,
  Typography,
} from '@swiftctrl/swift-component-library'
import styled from 'styled-components'
import { EntityTypeTag } from '../../../../components'
import { useGetTargetChildren } from './hooks'

type TargetChildrenBrowserProps = {
  target: Partial<EntityTypes>
}

export const TargetChildrenBrowser = ({
  target,
}: TargetChildrenBrowserProps) => {
  const { data, error } = useGetTargetChildren(target)

  if (error) {
    return <p>{String(error)}</p>
  }

  if (!data) {
    return <PreLoader spinProps={{ size: 'small' }} centered />
  }

  const { data: children } = data

  if (children.length === 0) {
    return <Empty description="No children" />
  }

  return (
    <>
      {children.map((child) => {
        const { entity_type_id, entity_name, entity_id } = child

        return (
          <StyledChildItem key={entity_id}>
            <EntityTypeTag entityType={entity_type_id!} />
            {entity_name ? (
              entity_name
            ) : (
              <Typography.Text code>{entity_id}</Typography.Text>
            )}
          </StyledChildItem>
        )
      })}
    </>
  )
}

const StyledChildItem = styled.div`
  margin-bottom: 8px;
  margin-left: 3rem;
`
