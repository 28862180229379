import {
  DownCircleOutlined,
  Dropdown,
  MenuProps,
  MinusSquareOutlined,
  PlusSquareOutlined,
} from '@swiftctrl/swift-component-library'
import { Key, StrictMode, useEffect, useRef } from 'react'
import { createRoot, Root } from 'react-dom/client'
import { styled } from 'styled-components'
import { AntColors } from '../../../../theme'

export const useExpandCollapseMenuEffect = (
  allKeys: Key[],
  setExpandedRowKeys: (keys: Key[]) => void,
) => {
  const rootRef = useRef<Root>()

  useEffect(() => {
    if (rootRef.current) {
      return
    }

    const rootElement = document.querySelector(
      'th.ant-table-row-expand-icon-cell',
    )!

    rootRef.current = createRoot(rootElement)

    const items: MenuProps['items'] = [
      {
        key: 'expand',
        label: 'Expand all',
        icon: <StyledExpandIcon />,
        onClick: () => setExpandedRowKeys(allKeys),
      },
      {
        key: 'collapse',
        label: 'Collapse all',
        icon: <StyledCollapseIcon />,
        onClick: () => setExpandedRowKeys([]),
      },
    ]

    rootRef.current.render(
      <StrictMode>
        <Dropdown menu={{ items }} trigger={['click']}>
          <StyledDiv>
            <StyledIcon />
          </StyledDiv>
        </Dropdown>
      </StrictMode>,
    )
  }, [allKeys, setExpandedRowKeys])
}

const StyledExpandIcon = styled(PlusSquareOutlined)`
  path:nth-of-type(2) {
    color: ${AntColors['gray-4']};
  }
`

const StyledCollapseIcon = styled(MinusSquareOutlined)`
  path:nth-of-type(2) {
    color: ${AntColors['gray-4']};
  }
`

/**
 * Using a `div` instead of `Layout` here in order to prevent a
 * React warning: "findDOMNode is deprecated in StrictMode"
 */
const StyledDiv = styled.div`
  display: flex;

  justify-content: center;
`

const StyledIcon = styled(DownCircleOutlined)`
  color: ${AntColors['gray-7']};

  cursor: pointer;
`
