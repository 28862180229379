import { EntityBrowseTypes, EntityTypes } from '@swiftctrl/api-client'
import { FilterParams, getQueryHandler } from '@swiftctrl/api-helpers'
import { SelectProps, Typography } from '@swiftctrl/swift-component-library'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { EntityType, SWIFT_ROOT_ENTITY_ID } from '../../data/models'
import {
  BackendError,
  swiftClient,
  swiftClientWithoutErrorHandling,
} from '../../data/swiftClient'
import {
  buildEntityTypeFilter,
  cacheKeys,
  isValidUuid,
  mapDataToSelectOptions,
} from '../../utils-hooks'
import { EntityTypeTag } from '../read-entity'
import { BaseLiveSearch } from './BaseLiveSearch'

type EntitySelectProps = {
  baseId?: string
  entityType?: EntityType | EntityType[]
  defaultEntityId?: string
  disabled?: boolean
  onChange?: (entity: EntityTypes | undefined) => void
  placeholder?: string
  size?: SelectProps['size']
  displayEntityType?: boolean
  onDefaultEntityError?: (error: BackendError) => void
  /**
   * If `entityType` is or includes `organization`, this flag
   * causes the Swift root to be excluded from the results
   */
  excludeSwiftRoot?: boolean
  /**
   * This component normally selects `entity_id`, `entity_name`, and `entity_type_id`.
   *
   * If more fields are required, use this prop to add more fields to the `select` param.
   */
  extraSelect?: (keyof EntityTypes)[]
}

export const EntitySelect = ({
  baseId = SWIFT_ROOT_ENTITY_ID,
  entityType,
  defaultEntityId = '',
  disabled,
  onChange,
  placeholder,
  size,
  displayEntityType = false,
  onDefaultEntityError,
  excludeSwiftRoot = false,
  extraSelect = [],
}: EntitySelectProps) => {
  const normalizedEntityTypes = normalizeEntityTypes(entityType)

  const [entityId, setEntityId] = useState(defaultEntityId)

  const { data: defaultEntity } = useReadEntity(
    defaultEntityId,
    Boolean(defaultEntityId),
    onDefaultEntityError,
  )

  const updateEntity = (_: string, option: any) => {
    const entity = option?.data as EntityTypes | undefined

    setEntityId(entity?.entity_id || '')

    onChange?.(entity)
  }

  const fetchEntities = async (search: string) => {
    if (defaultEntityId && !defaultEntity) {
      return []
    }

    const filters = buildFilters({
      normalizedEntityTypes,
      search,
      excludeSwiftRoot,
    })

    let { data } = await swiftClient.entity.browse({
      baseId,
      select: ['entity_id', 'entity_name', 'entity_type_id', ...extraSelect],
      filters,
    })

    if (
      shouldAddDefaultEntity(entityId, defaultEntityId, defaultEntity, data)
    ) {
      data.push(defaultEntity!)
    }

    return mapDataToSelectOptions(
      data,
      'entity_id',
      displayEntityType ? renderEntityWithType : 'entity_name',
      true,
    )
  }

  return (
    <BaseLiveSearch
      value={entityId}
      onChange={updateEntity}
      disabled={disabled}
      fetchOptions={fetchEntities}
      fetchDependencies={[baseId, normalizedEntityTypes, defaultEntity]}
      placeholder={placeholder}
      size={size}
    />
  )
}

const buildFilters = ({
  normalizedEntityTypes,
  search,
  excludeSwiftRoot,
}: {
  normalizedEntityTypes: EntityType[]
  search: string
  excludeSwiftRoot: boolean
}) => {
  const filters = new Array<FilterParams<EntityBrowseTypes>>()

  if (normalizedEntityTypes.length > 0) {
    filters.push(buildEntityTypeFilter(...normalizedEntityTypes))
  }

  const q = getQueryHandler<EntityBrowseTypes>()

  const trimmedSearch = search.trim()

  if (trimmedSearch) {
    if (isValidUuid(trimmedSearch)) {
      filters.push(
        q('AND', [
          q('WHERE', 'entity_id', 'EQ', trimmedSearch),
          q('OR', 'overseer_id', 'EQ', trimmedSearch),
        ]),
      )
    } else {
      filters.push(q('AND', 'entity_name', 'EQ_IC', trimmedSearch))
    }
  }

  if (excludeSwiftRoot && normalizedEntityTypes.includes('organization')) {
    filters.push(q('AND', 'entity_id', 'N_EQ', SWIFT_ROOT_ENTITY_ID))
  }

  if (filters.length > 0) {
    filters[0].joiner = 'WHERE'
  }

  return filters
}

const shouldAddDefaultEntity = (
  entityId: string,
  defaultEntityId: string | undefined,
  defaultEntity: EntityTypes | undefined,
  entities: Partial<EntityTypes>[],
) => {
  if (!defaultEntityId || !defaultEntity) {
    return false
  }

  if (entityId !== defaultEntityId) {
    return false
  }

  const responseIncludesInitialEntityId = entities.some(
    (entity) => entity.entity_id === defaultEntityId,
  )

  return !responseIncludesInitialEntityId
}

const renderEntityWithType = (entity: Partial<EntityTypes>) => {
  const { entity_id, entity_type_id, entity_name } = entity

  return (
    <>
      <EntityTypeTag entityType={entity_type_id!} />
      {entity_name || <Typography.Text code>{entity_id}</Typography.Text>}
    </>
  )
}

const useReadEntity = (
  entityId: string,
  enabled = true,
  onDefaultEntityError: ((error: BackendError) => void) | undefined,
) =>
  useQuery({
    queryKey: [cacheKeys.EntitySelect_default_entity, entityId],
    queryFn: async ({ signal }): Promise<EntityTypes> => {
      if (!onDefaultEntityError) {
        const { data } = await swiftClient.entity.at(entityId).read({ signal })

        return data
      }

      try {
        const { data } = await swiftClientWithoutErrorHandling.entity
          .at(entityId)
          .read({ signal })

        return data
      } catch (error) {
        onDefaultEntityError(error as BackendError)
      }

      return Promise.reject<EntityTypes>()
    },
    enabled,
  })

const normalizeEntityTypes = (
  entityType: EntityType | EntityType[] | undefined,
) => {
  if (!entityType) {
    return []
  }

  if (Array.isArray(entityType)) {
    return entityType
  }

  return [entityType]
}
