import { OrganizationTypes } from '@swiftctrl/api-client'
import { TabsProps } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'
import {
  EntityAncestryView,
  EntityTimestampsDisplay,
  FavoriteOrganizationButton,
  GenericEditEntityDrawer,
} from '../components'
import {
  EpisBrowser,
  ProfilesBrowser,
  ProvidersBrowser,
  RolesBrowser,
  StyledEntitiesBrowserTopBar,
} from '../components/browse-entities'
import {
  HeaderContent,
  HeaderContentProps,
  invalidateReadScreenQuery,
  ReadScreen,
} from '../components/read-screen'
import {
  ORGANIZATIONS_SCREEN_PATH,
  ORGANIZATION_SCREEN_ANCESTRY_TAB,
  ORGANIZATION_SCREEN_BUILDINGS_TAB,
  ORGANIZATION_SCREEN_CONFIG_TAB,
  ORGANIZATION_SCREEN_EPIS_TAB,
  ORGANIZATION_SCREEN_PROFILES_TAB,
  ORGANIZATION_SCREEN_PROVIDERS_TAB,
  ORGANIZATION_SCREEN_ROLES_TAB,
  ORGANIZATION_SCREEN_SOURCES_TAB,
} from '../config/paths'
import { swiftClientWithoutErrorHandling } from '../data/swiftClient'
import {
  BuildingsTabView,
  DeleteOrganizationModal,
  OrganizationConfigView,
  SourcesTabView,
} from '../screen-components/organization-screen'
import {
  adaptSDKRead,
  cacheKeys,
  getEnvironmentLocalStorageSwitch,
  useBoolean,
} from '../utils-hooks'

export const organizationScreenTabs = [
  ORGANIZATION_SCREEN_SOURCES_TAB,
  ORGANIZATION_SCREEN_BUILDINGS_TAB,
  ORGANIZATION_SCREEN_ROLES_TAB,
  ORGANIZATION_SCREEN_EPIS_TAB,
  ORGANIZATION_SCREEN_PROVIDERS_TAB,
  ORGANIZATION_SCREEN_PROFILES_TAB,
  ORGANIZATION_SCREEN_CONFIG_TAB,
  ORGANIZATION_SCREEN_ANCESTRY_TAB,
] as const

type OrganizationScreenTab = (typeof organizationScreenTabs)[number]

type OrganizationScreenProps = {
  tab?: OrganizationScreenTab
}

const OrganizationHeader = ({
  organization,
}: {
  organization: OrganizationTypes
}) => {
  const {
    organization_id,
    entity_name,
    entity_description,
    overseer_id,
    overseer_name,
    extra_json,
  } = organization

  const [isEditViewOpen, openEditView, closeEditView] = useBoolean()

  const handleEdited = () => {
    invalidateReadScreenQuery(cacheKeys.organization, organization_id)

    closeEditView()
  }

  const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] = useBoolean()

  const actionDropDownsItems: HeaderContentProps['actionDropDownsItems'] = [
    {
      key: 'edit',
      label: 'Edit',
      onClick: openEditView,
    },
  ]

  const deleteOrganizationEnabled = getEnvironmentLocalStorageSwitch({
    environments: ['local', 'dev', 'qa'],
    localStorageKey: 'deleteOrganizationEnabled',
  })

  if (deleteOrganizationEnabled) {
    actionDropDownsItems.push({
      key: 'delete',
      label: '🧑‍💻 Delete',
      danger: true,
      onClick: openDeleteModal,
    })
  }

  const headerContentProps: HeaderContentProps = {
    entityName: entity_name,
    entityId: organization_id,
    breadCrumbPath: `/${ORGANIZATIONS_SCREEN_PATH}`,
    entityType: 'organization',
    entityDescription: entity_description,
    infoItemsLeft: [
      {
        title: 'Overseer',
        value: overseer_name,
        copyableText: overseer_id,
      },
    ],
    actionDropDownsItems,
    extraJson: extra_json,
    postActionsDropdownButton: (
      <FavoriteOrganizationButton organization={organization} />
    ),
  }

  return (
    <>
      <HeaderContent {...headerContentProps} />
      <GenericEditEntityDrawer
        open={isEditViewOpen}
        entity={organization}
        entityType="organization"
        onEdited={handleEdited}
        onClose={closeEditView}
      />
      <DeleteOrganizationModal
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        organization={organization}
      />
    </>
  )
}

export const OrganizationScreen = ({ tab }: OrganizationScreenProps) => (
  <StyledReadScreen<OrganizationTypes>
    paramUuidProps={{
      paramKey: 'organizationId',
      entityType: 'organization',
      redirectPath: `/${ORGANIZATIONS_SCREEN_PATH}`,
    }}
    readRequest={adaptSDKRead(swiftClientWithoutErrorHandling.organization)}
    cacheKey={cacheKeys.organization}
    entityType="organization"
    headerContentFactory={(organization) => (
      <OrganizationHeader organization={organization} />
    )}
    contentFactory={{
      tab,
      tabs: organizationScreenTabs,
      tabPanesFactory,
      tabBarExtraContent: (organization) => (
        <EntityTimestampsDisplay
          created_at={organization.created_at!}
          updated_at={organization.updated_at!}
        />
      ),
    }}
  />
)

const StyledReadScreen = styled(ReadScreen)`
  .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-tabpane {
    padding-top: 0;
    padding-bottom: 0;
  }

  ${StyledEntitiesBrowserTopBar} {
    padding-top: 1em;
    border-bottom: solid 1px var(--light-gray-background-color);
  }
` as typeof ReadScreen

const tabPanesFactory = (organization: OrganizationTypes) => {
  const { organization_id } = organization

  const tabs: TabsProps['items'] = [
    {
      label: 'Sources',
      key: ORGANIZATION_SCREEN_SOURCES_TAB,
      children: <SourcesTabView organization={organization} />,
    },
    {
      label: 'Buildings',
      key: ORGANIZATION_SCREEN_BUILDINGS_TAB,
      children: <BuildingsTabView organization={organization} />,
    },
    {
      label: 'EPIs',
      key: ORGANIZATION_SCREEN_EPIS_TAB,
      children: <EpisBrowser organizationId={organization_id} />,
    },
    {
      label: 'Roles',
      key: ORGANIZATION_SCREEN_ROLES_TAB,
      children: <RolesBrowser baseId={organization_id} displayAddButton />,
    },
    {
      label: 'Providers',
      key: ORGANIZATION_SCREEN_PROVIDERS_TAB,
      children: <ProvidersBrowser baseId={organization_id} displayAddButton />,
    },
    {
      label: 'Profiles',
      key: ORGANIZATION_SCREEN_PROFILES_TAB,
      children: <ProfilesBrowser organization={organization} />,
    },
    {
      label: 'Config',
      key: ORGANIZATION_SCREEN_CONFIG_TAB,
      children: <OrganizationConfigView organization={organization} />,
    },
    {
      label: 'Ancestry',
      key: ORGANIZATION_SCREEN_ANCESTRY_TAB,
      children: (
        <EntityAncestryView
          entity={{ ...organization, entity_id: organization_id }}
        />
      ),
    },
  ]

  return tabs
}
