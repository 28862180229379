import { ProviderProfileTypes } from '@swiftctrl/api-client'
import { CopyableDisplay } from '../../../data-display'

type ProviderProfileProfileDisplayProps = {
  providerProfile: Partial<ProviderProfileTypes>
}

export const ProviderProfileProfileDisplay = ({
  providerProfile,
}: ProviderProfileProfileDisplayProps) => {
  const { display_name, profile_id } = providerProfile

  return <CopyableDisplay label={display_name} copyableText={profile_id} />
}
