import { OrganizationTypes } from '@swiftctrl/api-client'
import { Layout, Modal, Spin } from '@swiftctrl/swift-component-library'
import { ReactNode } from 'react'
import { AwaitingConfirmationView } from './AwaitingConfirmationView'
import { DeletingEntitiesView } from './DeletingEntitiesView'
import { ErrorOccurredView } from './ErrorOccurredView'
import { InvalidView } from './InvalidView'
import { State, useDeleteOrganizationLogic } from './useDeleteOrganizationLogic'
import { DeleteOrganizationModalContentProps } from './utils'

type DeleteOrganizationModalProps = {
  open: boolean
  onClose: () => void
  organization: Partial<OrganizationTypes>
}

type Config = {
  buttonLabel: string
  buttonDisabled: boolean
  closable: boolean
  contentFactory: (props: DeleteOrganizationModalContentProps) => ReactNode
}

const stateToConfigMap: Record<State, Config> = {
  None: {
    buttonLabel: '',
    buttonDisabled: false,
    closable: true,
    contentFactory: () => null,
  },
  FetchingDescendants: {
    buttonLabel: 'Please wait',
    buttonDisabled: true,
    closable: false,
    contentFactory: () => (
      <Layout horizontal gap="small">
        <Spin />
        <span>Fetching organization and descendants</span>
      </Layout>
    ),
  },
  CannotBeDeleted: {
    buttonLabel: 'Delete',
    buttonDisabled: true,
    closable: true,
    contentFactory: (props) => <InvalidView {...props} />,
  },
  AwaitingConfirmation: {
    buttonLabel: 'Delete',
    buttonDisabled: false,
    closable: true,
    contentFactory: (props) => <AwaitingConfirmationView {...props} />,
  },
  DeletingEntities: {
    buttonLabel: 'Please wait',
    buttonDisabled: true,
    closable: false,
    contentFactory: (props) => <DeletingEntitiesView {...props} />,
  },
  ErrorOccurred: {
    buttonLabel: 'Refetch',
    buttonDisabled: false,
    closable: true,
    contentFactory: (props) => <ErrorOccurredView {...props} />,
  },
}

export const DeleteOrganizationModal = ({
  open,
  onClose,
  organization,
}: DeleteOrganizationModalProps) => {
  const { entity_name } = organization

  const {
    state,
    entityTypeToEntitiesArray,
    entitiesCount,
    startDeleting,
    deletedCount,
    error,
    restartFlow,
    checkBeforeClose,
  } = useDeleteOrganizationLogic({
    open,
    onClose,
    organization,
  })

  const { buttonLabel, buttonDisabled, closable, contentFactory } =
    stateToConfigMap[state]

  return (
    <Modal
      open={open}
      onCancel={checkBeforeClose}
      title={`Delete "${entity_name}"`}
      okText={buttonLabel}
      okButtonProps={{
        disabled: buttonDisabled,
        danger: true,
      }}
      onOk={state === 'ErrorOccurred' ? restartFlow : startDeleting}
      centered
      destroyOnClose
      closable={closable}
      maskClosable={closable}
      cancelButtonProps={{
        disabled: !closable,
      }}
    >
      {contentFactory({
        entityTypeToEntitiesArray,
        entitiesCount,
        deletedCount,
        error,
      })}
    </Modal>
  )
}
