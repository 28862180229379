import { Alert, Layout } from '@swiftctrl/swift-component-library'
import { ErrorAlert } from '../../../components'
import { DeleteOrganizationModalContentProps } from './utils'

export const ErrorOccurredView = ({
  error,
}: DeleteOrganizationModalContentProps) => {
  return (
    <Layout gap="small" grow>
      <ErrorAlert error={error!} />
      <div>
        An error occurred. Please cancel, or start from the fetching step again.
      </div>
      <Alert
        type="warning"
        showIcon
        description="Entities that were deleted before the error cannot be rolled back."
      />
    </Layout>
  )
}
