import { PersonTypes, RolesTypes } from '@swiftctrl/api-client'
import { ActionsDropdown } from '../../../components'
import { swiftClientWithoutErrorHandling } from '../../../data/swiftClient'
import { useHandleDeleteClick } from '../../../utils-hooks'
import { personRoleQueryKeys } from './invalidatePersonRoleQueries'

type RoleActionsDropdownProps = {
  role: Partial<RolesTypes>
  person: PersonTypes
}

export const RoleActionsDropdown = ({
  role,
  person,
}: RoleActionsDropdownProps) => {
  const { handleDeleteClick } = useHandleDeleteClick({
    entity: role,
    entityType: 'role',
    action: 'remove',
    mutationFn: () =>
      swiftClientWithoutErrorHandling.person
        .at(person.person_id)
        .role.at(role.role_id!)
        .unassign(),
    invalidateQueryKeys: personRoleQueryKeys,
  })

  return (
    <ActionsDropdown
      items={[
        {
          key: 'remove',
          label: 'Remove',
          danger: true,
          onClick: handleDeleteClick,
        },
      ]}
    />
  )
}
