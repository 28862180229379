import {
  ProviderProfileBrowseTypes,
  ProviderProfileTypes,
  ProviderTypes,
} from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { PreLoader } from '@swiftctrl/swift-component-library'
import {
  BaseProviderProfilesBrowser,
  StyledEntitiesBrowserScreen,
} from '../../components'
import { PROVIDER_TYPES_SUPPORTING_PROVIDER_PROFILES } from '../../data/models'
import { swiftClientWithoutErrorHandling } from '../../data/swiftClient'
import { BrowseEntitiesConfig } from '../../states'
import {
  adaptSDKBrowseRequest,
  cacheKeys,
  useGetOverseerOrganization,
} from '../../utils-hooks'

interface ProviderDetailsContentProps {
  provider: ProviderTypes
  onCanDeleteProvider: () => void
}

export const ProviderDetailsContent = ({
  provider,
  onCanDeleteProvider,
}: ProviderDetailsContentProps) => {
  const { provider_id, provider_type } = provider

  const browse = buildBrowse(provider_id, onCanDeleteProvider)

  const { data: overseerOrganization, isPending } = useGetOverseerOrganization({
    entity: provider,
  })

  if (isPending) {
    return <PreLoader centered />
  }

  return (
    <StyledEntitiesBrowserScreen>
      <BaseProviderProfilesBrowser
        browse={browse}
        uniqueValues="profiles"
        displayAddButton={PROVIDER_TYPES_SUPPORTING_PROVIDER_PROFILES.includes(
          provider_type.name!,
        )}
        addDataTemplate={{
          preset_organization_id: overseerOrganization!.entity_id,
          preset_provider: provider,
        }}
      />
    </StyledEntitiesBrowserScreen>
  )
}

const buildBrowse = (
  providerId: string,
  onCanDeleteProvider: () => void,
): BrowseEntitiesConfig<ProviderProfileTypes> => ({
  request: adaptSDKBrowseRequest({
    request: swiftClientWithoutErrorHandling.providerProfile.browse,
    select: BaseProviderProfilesBrowser.selectForUniqueProfiles,
    getSearch: (search) => search,
    getFilters: () => {
      const q = getQueryHandler<ProviderProfileBrowseTypes>()

      return [q('WHERE', 'overseer_id', 'EQ', providerId)]
    },
  }),
  onDataReceived: (data) => {
    if (data.length === 0) {
      onCanDeleteProvider()
    }
  },
  cacheKey: [cacheKeys.provider_profiles, providerId],
})
