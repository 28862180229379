import {
  AxiosResponse,
  SinkTemplateAddTypes,
  SinkTemplateTypes,
} from '@swiftctrl/api-client'
import { useMutation } from '@tanstack/react-query'
import { isEqual } from 'lodash'
import { useState } from 'react'
import { queryClient } from '../../../../../data/queryClient'
import { swiftClientWithoutErrorHandling } from '../../../../../data/swiftClient'
import {
  cacheKeys,
  showSuccessNotification,
  useCheckBeforeClose,
  useQueryResultStatefulError,
  useReadSinkTemplate,
} from '../../../../../utils-hooks'
import { SINK_TEMPLATE_ID_FIELD_NAME } from './addSinkUtils'

type Params = {
  sourceId: string
  onClose: () => void
}

type FormData = {
  [key: string]: string | string[] | undefined
}

export const useAddSinkLogic = ({ sourceId, onClose }: Params) => {
  const initialValues = {
    [SINK_TEMPLATE_ID_FIELD_NAME]: undefined,
  }

  const [values, setValues] = useState<FormData>(initialValues)

  const isDirty = !isEqual(values, initialValues)

  const sinkTemplateId = values[SINK_TEMPLATE_ID_FIELD_NAME] as
    | string
    | undefined

  const {
    data: readSinkTemplateResponse,
    isLoading: isReadingSinkTemplate,
    error: readSinkTemplateError,
    resetError: resetSinkTemplateError,
  } = useQueryResultStatefulError(
    useReadSinkTemplate({
      id: sinkTemplateId!,
      enabled: Boolean(sinkTemplateId),
    }),
  )

  const isValid = validate(
    values,
    readSinkTemplateResponse,
    isReadingSinkTemplate,
  )

  const {
    mutate: addSink,
    isPending: isAddingSink,
    error: addSinkError,
    reset: resetAddSink,
  } = useMutation({
    mutationFn: (values: FormData) => {
      const variables: SinkTemplateAddTypes['variables'] = []

      Object.entries(values).forEach(([key, value]) => {
        if (key === SINK_TEMPLATE_ID_FIELD_NAME) {
          return
        }

        variables.push({
          name: key,
          value: String(value).trim(),
        })
      })

      return swiftClientWithoutErrorHandling.sinkTemplate
        .at(sinkTemplateId!)
        .publish({ variables })
    },
    onSuccess: () => {
      showSuccessNotification('Sink added')

      queryClient.invalidateQueries({ queryKey: [cacheKeys.sinks, sourceId] })

      reset()

      onClose()
    },
  })

  const reset = () => {
    setValues(initialValues)

    resetSinkTemplateError()

    resetAddSink()
  }

  const { checkBeforeClose } = useCheckBeforeClose({
    isPending: isAddingSink,
    isDirty,
    reset,
    close: onClose,
  })

  return {
    values,
    setValues,
    isValid,
    checkBeforeClose,
    isReadingSinkTemplate,
    sinkTemplateVariables: readSinkTemplateResponse?.data.variables,
    readSinkTemplateError,
    addSink,
    isAddingSink,
    addSinkError,
  }
}

const validate = (
  values: FormData,
  readSinkTemplateResponse: AxiosResponse<SinkTemplateTypes, any> | undefined,
  isReadingSinkTemplate: boolean,
) => {
  if (isReadingSinkTemplate || !readSinkTemplateResponse) {
    return false
  }

  if (!values[SINK_TEMPLATE_ID_FIELD_NAME]) {
    return false
  }

  const { variables } = readSinkTemplateResponse.data

  for (let i = 0; i < variables.length; i++) {
    const { type, name } = variables[i]

    const value = values[name]

    switch (type) {
      case 'Array':
        if (!value) {
          return false
        }

        const valueArray = value as Array<string>

        if (valueArray.length === 0) {
          return false
        }

        for (let j = 0; j < valueArray.length; j++) {
          const entry = valueArray[j]

          if (!entry) {
            return false
          }

          if (entry.trim() === '') {
            return false
          }
        }

        break

      case 'Integer':
        if (value === undefined || value === null) {
          return false
        }

        break

      case 'String':
      case 'Uuid':
        if (!value) {
          return false
        }
    }
  }

  return true
}
