import {
  OrganizationTypes,
  ProfileBrowseTypes,
  ProfilesTypes,
} from '@swiftctrl/api-client'
import { FilterParams, getQueryHandler } from '@swiftctrl/api-helpers'
import { ColumnsType } from '@swiftctrl/swift-component-library'
import {
  PEOPLE_SCREEN_PATH,
  PERSON_SCREEN_PROFILES_TAB,
} from '../../../../config/paths'
import { swiftClient } from '../../../../data/swiftClient'
import {
  BrowseEntitiesConfig,
  BrowseEntitiesContextProvider,
  EntitiesBrowserProps,
} from '../../../../states'
import { adaptSDKBrowseRequest, cacheKeys } from '../../../../utils-hooks'
import {
  ContactInfo,
  CopyableDisplay,
  CopyableLink,
  CreatedUpdatedDateDisplay,
} from '../../../data-display'
import {
  EntitiesTableBrowser,
  TABLE_DATA_PER_FETCH,
} from '../../EntitiesTableBrowser'
import { ProfilesBrowserTopBar } from './ProfilesBrowserTopBar'
import { useSearchProfilesLogic } from './useSearchProfilesLogic'
import { AdvancedSearchParams } from './utils'

type ProfilesBrowserProps = Omit<EntitiesBrowserProps, 'baseId'> & {
  organization: OrganizationTypes
}

/**
 * This one is not generic. It is used in the organization details page's "Profiles" tab.
 *
 * The profile list screen uses a separate profiles browser.
 */
export const ProfilesBrowser = ({
  organization,
  ...props
}: ProfilesBrowserProps) => {
  const { organization_id } = organization

  const {
    basicSearch,
    debouncedBasicSearch,
    setBasicSearch,
    advancedSearchParams,
    debouncedAdvancedSearchParams,
    setAdvancedSearchParam,
    isAdvancedSearch,
    setIsAdvancedSearch,
  } = useSearchProfilesLogic()

  const browse = buildBrowse(
    debouncedBasicSearch,
    debouncedAdvancedSearchParams,
  )

  return (
    <BrowseEntitiesContextProvider
      baseId={organization_id}
      dataPerFetch={TABLE_DATA_PER_FETCH}
      browse={browse}
      dataKey="profile_id"
      entityType="profile"
      topBar={
        <ProfilesBrowserTopBar
          organization={organization}
          basicSearch={basicSearch}
          setBasicSearch={setBasicSearch}
          advancedSearchParams={advancedSearchParams}
          setAdvancedSearchParam={setAdvancedSearchParam}
          isAdvancedSearch={isAdvancedSearch}
          setIsAdvancedSearch={setIsAdvancedSearch}
        />
      }
      {...props}
    >
      <EntitiesTableBrowser columns={columns} />
    </BrowseEntitiesContextProvider>
  )
}

const buildBrowse = (
  basicSearch: string,
  advancedSearchParams: AdvancedSearchParams,
): BrowseEntitiesConfig<ProfilesTypes> => ({
  request: adaptSDKBrowseRequest({
    request: swiftClient.profile.browse,
    select: [
      'display_name',
      'first_name',
      'last_name',
      'profile_id',
      'email',
      'phone_primary',
      'overseer_name',
      'overseer_id',
      'created_at',
      'updated_at',
      'employee_number',
      'person_id',
    ],
    getSearch: () => basicSearch,
    getFilters: () => buildFilters(advancedSearchParams),
  }),
  cacheKey: [
    cacheKeys.organization_profiles,
    basicSearch,
    advancedSearchParams['first-name'],
    advancedSearchParams['last-name'],
    advancedSearchParams['employee-number'],
    advancedSearchParams.email,
  ],
})

const buildFilters = (advancedSearchParams: AdvancedSearchParams) => {
  const firstName = advancedSearchParams['first-name']

  const lastName = advancedSearchParams['last-name']

  const employeeNumber = advancedSearchParams['employee-number']

  const email = advancedSearchParams.email

  if (!firstName && !lastName && !employeeNumber && !email) {
    return undefined
  }

  const q = getQueryHandler<ProfileBrowseTypes>()

  const filters = new Array<FilterParams<keyof ProfilesTypes>>()

  if (firstName) {
    filters.push(q('WHERE', 'first_name', 'EQ_IC', firstName))
  }

  if (lastName) {
    filters.push(q('AND', 'last_name', 'EQ_IC', lastName))
  }

  if (employeeNumber) {
    filters.push(q('AND', 'employee_number', 'EQ_IC', employeeNumber))
  }

  if (email) {
    filters.push(q('AND', 'email', 'EQ_IC', email))
  }

  filters[0].joiner = 'WHERE'

  return filters
}

const columns: ColumnsType<ProfilesTypes> = [
  {
    title: 'Name',
    render: ({ person_id, profile_id, display_name }: ProfilesTypes) => (
      <CopyableLink
        to={`/${PEOPLE_SCREEN_PATH}/${person_id}/${PERSON_SCREEN_PROFILES_TAB}/${profile_id}`}
        label={display_name || profile_id}
        copyableText={profile_id}
      />
    ),
    width: '20%',
  },
  {
    title: 'Overseer',
    render: ({ overseer_id, overseer_name }: ProfilesTypes) => (
      <CopyableDisplay label={overseer_name} copyableText={overseer_id} />
    ),
    width: '20%',
  },
  {
    title: 'Employee number',
    render: ({ employee_number }: ProfilesTypes) =>
      employee_number ? <CopyableDisplay label={employee_number} /> : '-',
    width: '20%',
  },
  {
    title: 'Contact',
    render: ({ email, phone_primary }: ProfilesTypes) => (
      <ContactInfo email={email} phone={phone_primary} />
    ),
    width: '20%',
  },
  {
    title: 'Created / Updated Date',
    render: ({ created_at, updated_at }: ProfilesTypes) => (
      <CreatedUpdatedDateDisplay
        createdAt={created_at}
        updatedAt={updated_at}
      />
    ),
    width: '20%',
  },
]
