import {
  PersonTypes,
  ProviderProfileBrowseTypes,
  ProviderProfileTypes,
} from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import {
  AddProviderProfileView,
  BaseProviderProfilesBrowser,
} from '../../components'
import { swiftClientWithoutErrorHandling } from '../../data/swiftClient'
import { BrowseEntitiesConfig, useEventContext } from '../../states'
import { adaptSDKBrowseRequest, cacheKeys, useBoolean } from '../../utils-hooks'

type PersonProviderProfilesBrowserProps = {
  person: PersonTypes
}

const ADD_LABEL = 'Add Auth Method'

export const PersonProviderProfilesBrowser = ({
  person,
}: PersonProviderProfilesBrowserProps) => {
  const { primary_profile } = person

  const { organization_id } = primary_profile

  const browse = buildBrowse(person)

  const [isAddViewOpen, openAddView, closeAddView] = useBoolean()

  const { useEvent } = useEventContext()

  useEvent(
    'PersonHasNoEmailPasswordProviderProfile.CreateNewButton.click',
    openAddView,
  )

  return (
    <BaseProviderProfilesBrowser
      browse={browse}
      uniqueValues="providers"
      addButtonLabel={ADD_LABEL}
      onAddButtonClick={openAddView}
      addView={
        <AddProviderProfileView
          organizationId={organization_id}
          profile={primary_profile}
          title={ADD_LABEL}
          open={isAddViewOpen}
          onClose={closeAddView}
        />
      }
    />
  )
}

const buildBrowse = (
  person: PersonTypes,
): BrowseEntitiesConfig<ProviderProfileTypes> => ({
  request: adaptSDKBrowseRequest({
    request: swiftClientWithoutErrorHandling.providerProfile.browse,
    select: BaseProviderProfilesBrowser.selectForUniqueProviders,
    getSearch: (search) => search,
    getFilters: () => {
      const q = getQueryHandler<ProviderProfileBrowseTypes>()

      return [q('WHERE', 'profile_id', 'ANY', person.profile_ids)]
    },
  }),
  cacheKey: [cacheKeys.personProviderProfiles, person.person_id],
})
