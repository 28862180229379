import { EntityTypes } from '@swiftctrl/api-client'
import { Layout, PreLoader } from '@swiftctrl/swift-component-library'
import { Timeline } from 'antd'
import { styled } from 'styled-components'
import { useGetAncestry } from '../../utils-hooks'
import { ReadScreenLink } from '../navigation'
import { EntityChildrenView } from './EntityChildrenView'
import { EntityDisplay } from './EntityDisplay'

type EntityAncestryViewProps = {
  entity: EntityTypes
}

export const EntityAncestryView = ({
  entity,
  ...props
}: EntityAncestryViewProps) => {
  const { ancestry, isPending } = useGetAncestry({
    entity,
  })

  return (
    <StyledLayout
      paddingVertical="xMedium"
      paddingHorizontal="medium"
      grow
      {...props}
    >
      <Timeline>
        {ancestry.map((entity) => (
          <Timeline.Item key={entity.entity_id} color="gray">
            <StyledAncestorLayout horizontal gap="xSmall">
              <EntityDisplay entity={entity} />
              <StyledReadScreenLink
                entityType={entity.entity_type_id}
                entityId={entity.entity_id}
              />
            </StyledAncestorLayout>
          </Timeline.Item>
        ))}
        <Timeline.Item>
          <EntityDisplay entity={entity} />
        </Timeline.Item>
        <StyledTimelineItem color="gray">
          <EntityChildrenView entity={entity} />
        </StyledTimelineItem>
      </Timeline>
      {isPending && (
        <LoadingOverlay>
          <PreLoader spinProps={{ size: 'small' }} centered />
        </LoadingOverlay>
      )}
    </StyledLayout>
  )
}

const StyledLayout = styled(Layout)`
  position: relative;
`

const LoadingOverlay = styled.div`
  position: absolute;

  background-color: #ffffff88;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const StyledTimelineItem = styled(Timeline.Item)`
  padding-bottom: 0;

  .ant-timeline-item-tail {
    display: block;
  }
`

const StyledAncestorLayout = styled(Layout)``

const StyledReadScreenLink = styled(ReadScreenLink)`
  display: none;

  ${StyledAncestorLayout}:hover & {
    display: block;
  }
`
