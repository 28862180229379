import { FormProps } from '@swiftctrl/swift-component-library'
import { FormItemProps } from 'antd'

type FormFields = NonNullable<FormProps['fields']>

type buildCommonFormPropsParams<T> = Omit<
  FormProps,
  | 'layout'
  | 'preserve'
  | 'fields'
  | 'onFieldsChange'
  | 'disabled'
  | 'requiredMark'
  | 'validateTrigger'
  | 'initialValues'
> & {
  values: T
  setValues: (values: T) => void
  isPending?: boolean
}

const transformValuesToFields = <T extends object>(values: T) => {
  const fields = Object.entries(values).reduce((fields, [key, value]) => {
    fields.push({ name: key, value })

    return fields
  }, [] as FormFields)

  return fields
}

const transformFieldsToValues = <T extends object>(fields: FormFields): T => {
  const values = {} as T

  fields.forEach((field) => {
    const { name, value } = field

    values[name as keyof T] = value
  })

  return values
}

/**
 * Sets up common behavior we want for forms
 */
export const buildCommonFormProps = <T extends object>({
  values,
  setValues,
  isPending,
  ...props
}: buildCommonFormPropsParams<T>): FormProps => {
  const fields = transformValuesToFields(values)

  const onFieldsChange = (_: unknown, fields: FormFields) => {
    const values = transformFieldsToValues<T>(fields)

    setValues(values)
  }

  return {
    layout: 'vertical',
    preserve: false,
    fields,
    onFieldsChange,
    disabled: isPending,
    requiredMark: 'optional',
    validateTrigger: 'onBlur',
    initialValues: values, // Ensures the initial setup of `values` works as expected
    ...props,
  }
}

export const buildCommonFormItemProps = ({
  rules: rulesProp,
  ...props
}: FormItemProps): FormItemProps => {
  const { required, label } = props

  const rules = rulesProp ? [...rulesProp] : []

  if (required) {
    rules.unshift({
      validator: (_, value) => {
        if (!value || (typeof value === 'string' && value.trim() === '')) {
          return Promise.reject(`${label || 'This field'} is required.`)
        }

        return Promise.resolve()
      },
    })
  }

  return {
    rules: rules.length > 0 ? rules : undefined,
    ...props,
  }
}
