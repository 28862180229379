import {
  PersonTypes,
  ProviderBrowseTypes,
  ProviderProfileBrowseTypes,
  providerTypesToProviderTypeIds,
} from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import {
  Layout,
  Modal,
  Select,
  SelectProps,
} from '@swiftctrl/swift-component-library'
import { useRef } from 'react'
import { SWIFT_ROOT_ENTITY_ID } from '../../data/models'
import { queryClient } from '../../data/queryClient'
import {
  swiftClient,
  swiftClientWithoutErrorHandling,
} from '../../data/swiftClient'
import { useEventContext } from '../../states'
import { AppThemeProvider } from '../../theme'
import {
  cacheKeys,
  getAllFromBrowseRequest,
  longCacheOptions,
  showErrorNotification,
  showSuccessNotification,
} from '../../utils-hooks'

type Params = {
  organization: { id: string; name: string }
  person: PersonTypes
}

export const useResetPasswordFlow = ({ organization, person }: Params) => {
  const { showPersonHasNoEmailPasswordProviderProfilesModal } =
    useShowPersonHasNoEmailPasswordProviderProfilesModal()

  const { showResetPasswordMultipleEmailsModal } =
    useShowResetPasswordMultipleEmailsModal()

  const handleResetPasswordClick = async () => {
    const organizationHasAnEmailPasswordProvider =
      await getOrganizationHasAnEmailPasswordProvider(organization.id)

    if (!organizationHasAnEmailPasswordProvider) {
      showOrganizationHasNoEmailPasswordProvidersModal(organization.name)

      return
    }

    const emails = await getAllEmails(person)

    switch (emails.length) {
      case 0:
        showPersonHasNoEmailPasswordProviderProfilesModal()

        break

      case 1:
        showResetPasswordSingleEmailModal(emails[0])

        break

      default:
        showResetPasswordMultipleEmailsModal(emails)

        break
    }
  }

  return { handleResetPasswordClick }
}

const getOrganizationHasAnEmailPasswordProvider = (organizationId: string) =>
  queryClient.fetchQuery({
    queryKey: [
      cacheKeys.organization_has_email_password_providers,
      organizationId,
    ],
    queryFn: async ({ signal }) => {
      const q = getQueryHandler<ProviderBrowseTypes>()

      const { data } = await swiftClient.provider.browse(
        {
          baseId: organizationId,
          filters: [
            q(
              'WHERE',
              'provider_type_id',
              'EQ',
              providerTypesToProviderTypeIds['email_password'],
            ),
          ],
          limit: 1,
          select: ['provider_id'],
        },
        { signal },
      )

      return data.length > 0
    },
    ...longCacheOptions,
  })

const getAllEmails = (person: PersonTypes) =>
  queryClient.fetchQuery({
    queryKey: [cacheKeys.person_emails, person.person_id],
    queryFn: async ({ signal }) => {
      const q = getQueryHandler<ProviderProfileBrowseTypes>()

      const filters = [q('WHERE', 'profile_id', 'ANY', person.profile_ids)]

      const providerProfiles = await getAllFromBrowseRequest((offset, limit) =>
        swiftClientWithoutErrorHandling.providerProfile.browse(
          {
            baseId: SWIFT_ROOT_ENTITY_ID,
            limit,
            offset,
            select: ['login_data'],
            filters,
          },
          {
            signal,
          },
        ),
      )

      const emailsSet = providerProfiles.reduce((emails, providerProfile) => {
        const email = providerProfile.login_data?.email_password?.email

        if (email) {
          emails.add(email)
        }

        return emails
      }, new Set<string>())

      const emailsArray = Array.from(emailsSet)

      emailsArray.sort()

      return emailsArray
    },
    ...longCacheOptions,
  })

const showOrganizationHasNoEmailPasswordProvidersModal = (
  organizationName: string,
) => {
  Modal.confirm({
    centered: true,
    title: 'Password reset unavailable',
    content: `${organizationName} doesn't have any Provider of type Email/Password`,
    okText: 'Create New',
    okButtonProps: {
      disabled: true,
    },
  })
}

const useShowPersonHasNoEmailPasswordProviderProfilesModal = () => {
  const { emitEvent } = useEventContext()

  const showPersonHasNoEmailPasswordProviderProfilesModal = () => {
    Modal.confirm({
      centered: true,
      title: 'Password reset unavailable',
      content: 'This user does not have Email/Password authentication enabled.',
      okText: 'Create New',
      onOk: () =>
        emitEvent(
          'PersonHasNoEmailPasswordProviderProfile.CreateNewButton.click',
        ),
    })
  }

  return { showPersonHasNoEmailPasswordProviderProfilesModal }
}

const showResetPasswordSingleEmailModal = (email: string) => {
  Modal.confirm({
    centered: true,
    title: 'Reset Password?',
    content: (
      <Layout gap="medium">
        <div>
          The user will receive password reset instructions at the following
          email:
        </div>
        <div>{email}</div>
      </Layout>
    ),
    okText: 'Send Email',
    onOk: () => requestPasswordReset(email),
  })
}

const requestPasswordReset = async (email: string) => {
  const { status, statusText } = await swiftClient.auth.resetPasswordRequest(
    email,
  )

  if (status === 204) {
    showSuccessNotification('Password reset requested')
  } else {
    showErrorNotification('Error while requesting password reset', statusText)
  }
}

const useShowResetPasswordMultipleEmailsModal = () => {
  const emailRef = useRef('')

  const showResetPasswordMultipleEmailsModal = (emails: string[]) => {
    const options: SelectProps['options'] = emails.map((email) => ({
      label: email,
      value: email,
    }))

    emailRef.current = emails[0]

    Modal.confirm({
      centered: true,
      title: 'Reset Password?',
      content: (
        <AppThemeProvider>
          <Layout gap="medium">
            <div>
              The user will receive password reset instructions at the following
              email:
            </div>
            <Select
              options={options}
              defaultValue={emailRef.current}
              onChange={(value) => (emailRef.current = value)}
            />
          </Layout>
        </AppThemeProvider>
      ),
      okText: 'Send Email',
      onOk: () => requestPasswordReset(emailRef.current),
    })
  }

  return { showResetPasswordMultipleEmailsModal }
}
