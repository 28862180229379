import { EntityTypes, EntityTypesIds } from '@swiftctrl/api-client'
import {
  Layout,
  Select,
  SelectProps,
  Typography,
} from '@swiftctrl/swift-component-library'
import { useState } from 'react'
import { styled } from 'styled-components'
import { commonFilterOption, flattenInfiniteQueryData } from '../../utils-hooks'
import { EntityTypeTag } from '../read-entity'
import { EntityChildrenList } from './EntityChildrenList'
import { useBrowseChildren } from './useBrowseChildren'

type EntityChildrenViewProps = {
  entity: EntityTypes
}

const supportedEntityTypes: EntityTypesIds[] = [
  'access_level',
  'access_level_credential',
  'acs_hardware',
  'acs_transaction',
  'booking',
  'building',
  'campus',
  'controller',
  'credential',
  'credential_format',
  'department',
  'device',
  'division',
  'entity',
  'epi',
  'floor',
  'image',
  'meeting',
  'meeting_invitation',
  'opening_hours',
  'organization',
  'pass',
  'payment',
  'portfolio',
  'profile',
  'provider',
  'provider_profile',
  'reader',
  'report',
  'role',
  'room',
  'room_price',
  'schedule',
  'session',
  'sink',
  'source',
  'system',
  'zone',
]

const entityTypeOptions: SelectProps['options'] = supportedEntityTypes.map(
  (entityType) => ({
    value: entityType,
    label: <EntityTypeTag entityType={entityType} />,
  }),
)

export const EntityChildrenView = ({ entity }: EntityChildrenViewProps) => {
  const { entity_id } = entity

  const [entityType, setEntityType] = useState<EntityTypesIds>()

  const { data, isFetching, fetchNextPage, error } = useBrowseChildren(
    entity_id,
    entityType,
  )

  const flattenedData = flattenInfiniteQueryData(data)

  return (
    <>
      <Layout horizontal alignItems="center" gap="medium">
        <Typography.Text strong>Children of this entity</Typography.Text>
        <StyledSelect
          options={entityTypeOptions}
          size="middle"
          onChange={setEntityType}
          showLabel={false}
          placeholder="Select Entity Type"
          showSearch
          filterOption={commonFilterOption}
        />
      </Layout>
      {entityType && (
        <StyledEntityChildrenList
          isFetching={isFetching}
          error={error}
          data={flattenedData}
          fetchNextPage={fetchNextPage}
        />
      )}
    </>
  )
}

const StyledSelect = styled(Select)`
  width: 172px;
`

const StyledEntityChildrenList = styled(EntityChildrenList)`
  margin-top: ${({ theme }) => theme.spacing.medium};
`
