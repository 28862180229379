import { ProfilesTypes } from '@swiftctrl/api-client'
import {
  InfoCircleOutlined,
  Layout,
  Tooltip,
  Typography,
} from '@swiftctrl/swift-component-library'
import { JSX } from 'react'
import styled from 'styled-components'
import { CopyableDisplay } from '../../../../components'

type RoleProfileViewProps = {
  profile: Partial<ProfilesTypes>
  actionContainer: React.ReactNode
  roleId?: string
  showMoreInfo?: boolean
}

export const RoleProfileView = ({
  profile,
  actionContainer,
  showMoreInfo,
  roleId,
}: RoleProfileViewProps) => {
  const {
    display_name,
    overseer_name,
    overseer_id,
    email,
    phone_primary,
    profile_id,
  } = profile

  const contactElements = buildContactElements(email, phone_primary)

  const showInfoIcon = showMoreInfo && roleId && overseer_id === roleId

  return (
    <StyledLayout horizontal justifyContent="space-between" alignItems="center">
      <Layout grow>
        <CopyableDisplay label={display_name} copyableText={profile_id} />

        <div>
          <Typography.Text type="secondary">Overseer: </Typography.Text>
          <CopyableDisplay
            label={overseer_name}
            copyableText={overseer_id}
            secondary
          />
          {showInfoIcon && (
            <Tooltip title="Role is the overseer of this profile. If you want to remove this profile, you must delete it.">
              <StyledInfoIcon />
            </Tooltip>
          )}
        </div>
        {contactElements && (
          <Layout horizontal grow gap="small">
            {contactElements}
          </Layout>
        )}
      </Layout>
      {actionContainer}
    </StyledLayout>
  )
}

const buildContactElements = (
  email: string | undefined,
  phone: string | undefined,
) => {
  if (!email && !phone) {
    return null
  }

  const contactElements = new Array<JSX.Element>()

  if (email) {
    contactElements.push(
      <Typography.Text key="email" type="secondary">
        {email}
      </Typography.Text>,
    )
  }

  if (email && phone) {
    contactElements.push(
      <Typography.Text key="separator" type="secondary">
        •
      </Typography.Text>,
    )
  }

  if (phone) {
    contactElements.push(
      <Typography.Text key="phone" type="secondary">
        {phone}
      </Typography.Text>,
    )
  }

  return contactElements
}

const StyledLayout = styled(Layout)`
  flex: 1;
`

const StyledInfoIcon = styled(InfoCircleOutlined)`
  color: #fa8c16;
  margin-left: 8px;
`
