import { AxiosResponse, OrganizationTypes } from '@swiftctrl/api-client'
import { Drawer, Form, Input } from '@swiftctrl/swift-component-library'
import { useState } from 'react'
import { styled } from 'styled-components'
import { AddFormButtonGroup, ErrorAlert } from '../../components'
import { ORGANIZATIONS_SCREEN_PATH } from '../../config/paths'
import { queryClient } from '../../data/queryClient'
import {
  buildCommonDrawerProps,
  buildCommonFormItemProps,
  buildCommonFormProps,
  cacheKeys,
  hasSomeValue,
  showSuccessNotification,
  trimValues,
  useCheckBeforeClose,
  useDelayedCacheInvalidationAfterNavigation,
} from '../../utils-hooks'
import { AddOrganizationFormData } from './models'
import { useAddOrganization } from './useAddOrganization'

type AddOrganizationViewProps = {
  open: boolean
  onClose: () => void
}

const SHORT_NAME_VALIDATION = {
  MAX_LENGTH: 8,
  ALPHABETICAL: /^[a-zA-Z]+$/,
}

export const AddOrganizationView = ({
  open,
  onClose,
}: AddOrganizationViewProps) => {
  const { delayedCacheInvalidationAfterNavigation } =
    useDelayedCacheInvalidationAfterNavigation()

  const { mutate, isPending, error, reset } = useAddOrganization({
    onSuccess: (response: AxiosResponse<OrganizationTypes>) => {
      showSuccessNotification('Organization added')

      delayedCacheInvalidationAfterNavigation({
        navigatePath: `/${ORGANIZATIONS_SCREEN_PATH}/${response.data.organization_id}`,
        invalidateCache: () => {
          queryClient.resetQueries({ queryKey: [cacheKeys.organizations] })
        },
      })
    },
  })

  const [values, setValues] = useState<AddOrganizationFormData>({})

  const resetValues = () => {
    setValues({})

    reset()
  }

  const isDirty = hasSomeValue(values)

  const { checkBeforeClose } = useCheckBeforeClose({
    isPending,
    isDirty,
    reset: resetValues,
    close: onClose,
  })

  const isValid = validate(values)

  return (
    <Drawer
      {...buildCommonDrawerProps({
        open,
        title: 'Add organization',
        onClose: checkBeforeClose,
      })}
    >
      <Form
        {...buildCommonFormProps({
          values,
          setValues,
          isPending,
          onFinish: mutate,
        })}
      >
        <Form.Item
          {...buildCommonFormItemProps({
            name: 'name',
            label: 'Organization Name',
            required: true,
          })}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          {...buildCommonFormItemProps({
            name: 'short_name',
            label: 'Short Name',
            required: true,
            tooltip:
              'Short names are used to prefix sub-entities to make them easier to find.',
            rules: [
              {
                validator: (_, value) => {
                  if (typeof value !== 'string') {
                    return Promise.resolve()
                  }

                  const trimmedValue = value.trim()

                  if (trimmedValue === '') {
                    return Promise.resolve()
                  }

                  if (!SHORT_NAME_VALIDATION.ALPHABETICAL.test(trimmedValue)) {
                    return Promise.reject(
                      'Short Name may only contain alphabetical characters.',
                    )
                  }

                  return Promise.resolve()
                },
              },
              {
                validator: (_, value) => {
                  if (typeof value !== 'string') {
                    return Promise.resolve()
                  }

                  const trimmedValue = value.trim()

                  if (trimmedValue === '') {
                    return Promise.resolve()
                  }

                  if (trimmedValue.length > SHORT_NAME_VALIDATION.MAX_LENGTH) {
                    return Promise.reject(
                      `Short Name must be at most ${SHORT_NAME_VALIDATION.MAX_LENGTH} characters long.`,
                    )
                  }

                  return Promise.resolve()
                },
              },
            ],
          })}
        >
          <StyledInput size="large" />
        </Form.Item>
        <Form.Item
          {...buildCommonFormItemProps({
            name: 'tag',
            label: 'Organization Tag',
            required: true,
            tooltip:
              "Value matching the access control system's organization field, to insert imported cardholder under the proper organization.",
          })}
        >
          <Input size="large" />
        </Form.Item>
        {error && (
          <Form.Item>
            <ErrorAlert error={error} />
          </Form.Item>
        )}
        <AddFormButtonGroup
          onClose={checkBeforeClose}
          isValid={isValid}
          isPending={isPending}
        />
      </Form>
    </Drawer>
  )
}

const validate = (values: AddOrganizationFormData) => {
  const { name, short_name, tag } = trimValues(values)

  if (!name || !short_name || !tag) {
    return false
  }

  if (!SHORT_NAME_VALIDATION.ALPHABETICAL.test(short_name)) {
    return false
  }

  if (short_name.length > SHORT_NAME_VALIDATION.MAX_LENGTH) {
    return false
  }

  return true
}

const StyledInput = styled(Input)`
  text-transform: uppercase;
`
