import { Drawer, Form, Input } from '@swiftctrl/swift-component-library'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CREDENTIAL_TEMPLATES_SCREEN_PATH } from '../../../../config/paths'
import { SWIFT_ROOT_ENTITY_ID } from '../../../../data/models'
import { useBrowseEntitiesContext } from '../../../../states'
import {
  buildCommonDrawerProps,
  buildCommonFormItemProps,
  buildCommonFormProps,
  hasSomeValue,
  showSuccessNotification,
  useCheckBeforeClose,
} from '../../../../utils-hooks'
import { EntitySelect } from '../../../add-entities'
import { AddFormButtonGroup } from '../../../AddFormButtonGroup'
import { ErrorAlert } from '../../../ErrorAlert'
import { CredentialFormatSelect } from './CredentialFormatSelect'
import { useAddCredentialTemplate } from './useAddCredentialTemplate'
import { AddCredentialTemplateFormData } from './utils'

type AddCredentialTemplateViewProps = {
  open: boolean
  onClose: () => void
}

export const AddCredentialTemplateView = ({
  open,
  onClose,
}: AddCredentialTemplateViewProps) => {
  const [values, setValues] = useState<AddCredentialTemplateFormData>({})

  const [error, setError] = useState<Error | undefined>()

  const reset = () => {
    setValues({})

    setError(undefined)
  }

  const isDirty = hasSomeValue(values)

  const { invalidateData } = useBrowseEntitiesContext()

  const navigate = useNavigate()

  const { mutate, isPending } = useAddCredentialTemplate({
    onSuccess: (response) => {
      showSuccessNotification('Credential template added')

      reset()

      invalidateData()

      navigate(
        `/${CREDENTIAL_TEMPLATES_SCREEN_PATH}/${response.data.credential_format_config_id}`,
      )
    },
    onError: setError,
  })

  const { checkBeforeClose } = useCheckBeforeClose({
    isPending,
    isDirty,
    reset,
    close: onClose,
  })

  const isValid = validate(values)

  const handleFinish = () => {
    setError(undefined)

    mutate(values)
  }

  const { organization } = values

  return (
    <Drawer
      {...buildCommonDrawerProps({
        open,
        title: 'Add Credential Template',
        onClose: checkBeforeClose,
      })}
    >
      <Form
        {...buildCommonFormProps({
          values,
          setValues,
          isPending,
          onFinish: handleFinish,
        })}
      >
        <Form.Item
          {...buildCommonFormItemProps({
            name: 'organization',
            label: 'Organization',
            required: true,
          })}
        >
          <EntitySelect
            baseId={SWIFT_ROOT_ENTITY_ID}
            entityType="organization"
            size="large"
          />
        </Form.Item>
        {organization && (
          <>
            <Form.Item
              {...buildCommonFormItemProps({
                name: 'credentialFormatId',
                label: 'Core Credential Format',
                required: true,
              })}
            >
              <CredentialFormatSelect
                key={organization.entity_id}
                baseId={organization.entity_id!}
              />
            </Form.Item>
            <Form.Item
              {...buildCommonFormItemProps({
                name: 'templateName',
                label: 'Name',
                required: true,
              })}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              {...buildCommonFormItemProps({
                name: 'templateDescription',
                label: 'Description',
              })}
            >
              <Input.TextArea size="large" rows={4} />
            </Form.Item>
          </>
        )}
        {error && (
          <Form.Item>
            <ErrorAlert error={error} />
          </Form.Item>
        )}
        <AddFormButtonGroup
          onClose={checkBeforeClose}
          isValid={isValid}
          isPending={isPending}
        />
      </Form>
    </Drawer>
  )
}

const validate = (values: AddCredentialTemplateFormData) => {
  const { organization, credentialFormatId, templateName } = values

  if (!organization || !credentialFormatId || !templateName) {
    return false
  }

  return true
}
