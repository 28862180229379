import { OperationPrefix, RoleOperationTypes } from '@swiftctrl/api-client'
import { ColumnsType, Table, Tag } from '@swiftctrl/swift-component-library'
import { Key, useEffect, useRef, useState } from 'react'
import { EmptyPlaceholder } from '../../../../components'
import { useEventContext } from '../../../../states'
import { getIsAccessCloudOperationPrefix, RoleOperationsGroup } from '../models'
import { AccessCloudRoleOperationsTable } from './AccessCloudRoleOperationsTable'
import { RoleOperationsTable } from './RoleOperationsTable'
import { useExpandCollapseMenuEffect } from './useExpandCollapseMenuEffect'

type RoleOperationsGroupTableProps = {
  dataSource: RoleOperationsGroup[]
  onRemoveRoleOperation: (roleOperation: RoleOperationTypes) => void
}

const EXPAND_TIME = 400

export const RoleOperationsGroupTable = ({
  dataSource,
  onRemoveRoleOperation,
}: RoleOperationsGroupTableProps) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState(new Array<Key>())

  const [addedOperationsToPrefix, setAddedOperationsToPrefix] =
    useState<OperationPrefix>()

  const { useEvent } = useEventContext()

  useEvent('role.permission.added', (prefix: OperationPrefix) => {
    setExpandedRowKeys((previous) => [...previous, prefix])

    setAddedOperationsToPrefix(prefix)
  })

  const roleOperationsTableRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!addedOperationsToPrefix) {
      return
    }

    setTimeout(() => {
      roleOperationsTableRef.current?.scrollIntoView({
        behavior: 'smooth',
      })
    }, EXPAND_TIME)
  }, [addedOperationsToPrefix])

  const allKeys = dataSource.map((entry) => entry.prefix)

  useExpandCollapseMenuEffect(allKeys, setExpandedRowKeys)

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      rowKey="prefix"
      pagination={false}
      expandable={{
        expandedRowRender: (operationsGroup) => {
          const { prefix, roleOperations } = operationsGroup

          return (
            <>
              {prefix === addedOperationsToPrefix && (
                <div ref={roleOperationsTableRef} />
              )}
              {getIsAccessCloudOperationPrefix(prefix) ? (
                <AccessCloudRoleOperationsTable
                  dataSource={roleOperations}
                  onRemoveRoleOperation={onRemoveRoleOperation}
                />
              ) : (
                <RoleOperationsTable
                  dataSource={roleOperations}
                  onRemoveOperation={onRemoveRoleOperation}
                />
              )}
            </>
          )
        },
        expandedRowKeys,
        onExpandedRowsChange: (keys) => setExpandedRowKeys([...keys]),
      }}
      locale={{
        emptyText: <EmptyPlaceholder entityType="permission" />,
      }}
    />
  )
}

const columns: ColumnsType<RoleOperationsGroup> = [
  {
    title: 'Object',
    dataIndex: 'prefix',
    width: '50%',
  },
  {
    title: 'Operation(s)',
    render: (_, operationsGroup) => {
      return (
        <>
          {operationsGroup.actions.map((action) => (
            <Tag key={action}>{action}</Tag>
          ))}
        </>
      )
    },
    width: '50%',
  },
]
