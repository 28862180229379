import { Layout, Typography } from '@swiftctrl/swift-component-library'
import { formatDateTime, formatTimeAgo } from '../../../utils-hooks'

interface DateDisplayProps {
  label: string
  date: string | null | undefined
  appendTimeAgo?: boolean
}

export const DateDisplay = ({
  label,
  date,
  appendTimeAgo = false,
}: DateDisplayProps) => {
  return (
    <Layout horizontal>
      <Typography.Text type="secondary">{label}:&nbsp;</Typography.Text>
      {date ? buildLabel(date, appendTimeAgo) : '-'}
    </Layout>
  )
}

const buildLabel = (date: string, appendTimeAgo: boolean) => {
  let label = formatDateTime(date)

  if (appendTimeAgo) {
    label += ` (${formatTimeAgo(date)})`
  }

  return label
}
