import { InfoCircleOutlined, Tooltip } from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'
import { AntColors } from '../../theme'

type TooltipIconProps = {
  message: string | undefined
}

export const TooltipIcon = ({ message }: TooltipIconProps) => {
  if (!message) {
    return null
  }

  return (
    <Tooltip title={message}>
      <StyledInfoIcon />
    </Tooltip>
  )
}

const StyledInfoIcon = styled(InfoCircleOutlined)`
  cursor: help;

  color: ${AntColors['gray-7']};
`
