import { OrganizationTypes } from '@swiftctrl/api-client'
import {
  Button,
  Input,
  Layout,
  PlusOutlined,
  Switch,
  Text,
} from '@swiftctrl/swift-component-library'
import { ChangeEvent } from 'react'
import { styled } from 'styled-components'
import { useBoolean } from '../../../../utils-hooks'
import {
  EntitiesBrowserTopBarSearchAndFilterGroup,
  StyledEntitiesBrowserTopBar,
} from '../../EntitiesBrowserTopBar'
import { AddProfileView } from './AddProfileView'
import { AdvancedSearchParams } from './utils'

type ProfilesBrowserTopBarProps = {
  organization: OrganizationTypes
  basicSearch: string | undefined
  setBasicSearch: (value: string) => void
  advancedSearchParams: AdvancedSearchParams
  setAdvancedSearchParam: (
    key: keyof AdvancedSearchParams,
    value: string,
  ) => void
  isAdvancedSearch: boolean
  setIsAdvancedSearch: (value: boolean) => void
}

export const ProfilesBrowserTopBar = ({
  organization,
  basicSearch,
  setBasicSearch,
  advancedSearchParams,
  setAdvancedSearchParam,
  isAdvancedSearch,
  setIsAdvancedSearch,
}: ProfilesBrowserTopBarProps) => {
  const [isAddViewOpen, openAddView, closeAddView] = useBoolean()

  const handleBasicSearchInputChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setBasicSearch(event.target.value)
  }

  const handleAdvancedSearchInputChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target

    const key = name as keyof AdvancedSearchParams

    setAdvancedSearchParam(key, value)
  }

  return (
    <StyledEntitiesBrowserTopBar $background>
      <StyledLayout gap="xSmall">
        <EntitiesBrowserTopBarSearchAndFilterGroup>
          {!isAdvancedSearch ? (
            <StyledSearchInput
              value={basicSearch}
              onChange={handleBasicSearchInputChange}
              placeholder="Search"
              size="middle"
              allowClear
            />
          ) : (
            <>
              <Input
                name="first-name"
                placeholder="First name"
                size="middle"
                allowClear
                value={advancedSearchParams['first-name']}
                onChange={handleAdvancedSearchInputChange}
              />
              <Input
                name="last-name"
                placeholder="Last name"
                size="middle"
                allowClear
                value={advancedSearchParams['last-name']}
                onChange={handleAdvancedSearchInputChange}
              />
              <Input
                name="employee-number"
                placeholder="Employee number"
                size="middle"
                allowClear
                value={advancedSearchParams['employee-number']}
                onChange={handleAdvancedSearchInputChange}
              />
              <Input
                name="email"
                placeholder="Email"
                size="middle"
                allowClear
                value={advancedSearchParams.email}
                onChange={handleAdvancedSearchInputChange}
              />
            </>
          )}
        </EntitiesBrowserTopBarSearchAndFilterGroup>
        <Layout gap="small" horizontal alignItems="center">
          <Text size="12px">Advanced</Text>
          <Switch
            size="small"
            checked={isAdvancedSearch}
            onChange={setIsAdvancedSearch}
          />
        </Layout>
      </StyledLayout>
      <StyledButton
        type="primary"
        icon={<PlusOutlined />}
        size="middle"
        onClick={openAddView}
      >
        Add profile
      </StyledButton>
      <AddProfileView
        organization={organization}
        open={isAddViewOpen}
        onClose={closeAddView}
      />
    </StyledEntitiesBrowserTopBar>
  )
}

const StyledLayout = styled(Layout)`
  flex: none;
`

const StyledButton = styled(Button)`
  align-self: flex-start;
`

const StyledSearchInput = styled(Input.Search)`
  width: 330px;
`
