import { ProfilesTypes } from '@swiftctrl/api-client'
import {
  LiveSearchProps,
  SelectProps,
} from '@swiftctrl/swift-component-library'
import { useState } from 'react'
import { swiftClient } from '../../data/swiftClient'
import { useEventContext } from '../../states'
import { mapDataToSelectOptions, useReadProfile } from '../../utils-hooks'
import { BaseLiveSearch } from './BaseLiveSearch'

type ProfileSelectProps = {
  baseId: string
  value?: string
  disabled?: boolean
  size?: SelectProps['size']
  onProfileIdSelected: (profileId: string) => void
}

export const ProfileSelect = ({
  baseId,
  value: initialProfileId = '',
  disabled,
  size,
  onProfileIdSelected,
}: ProfileSelectProps) => {
  const [profileId, setProfileId] = useState(initialProfileId)

  const { data: initialProfile } = useReadProfile(
    initialProfileId,
    Boolean(initialProfileId),
  )

  const { emitEvent } = useEventContext()

  const updateProfileId: LiveSearchProps<string>['onChange'] = (
    value,
    option,
  ) => {
    setProfileId(value)

    onProfileIdSelected(value)

    if (Array.isArray(option)) {
      // no-op because `ProfileSelect` is not a multi-select
      return
    }

    const profile = option?.data as ProfilesTypes | undefined

    emitEvent('ProfileSelect.change', profile)
  }

  const fetchProfiles = async (search: string) => {
    if (initialProfileId && !initialProfile) {
      return []
    }

    const { data } = await swiftClient.profile.browse({
      baseId,
      select: ['profile_id', 'display_name', 'email', 'employee_number'],
      search,
    })

    if (
      shouldAddInitialProfile(profileId, initialProfileId, initialProfile, data)
    ) {
      data.push(initialProfile!)
    }

    return mapDataToSelectOptions(data, 'profile_id', 'display_name', true)
  }

  return (
    <BaseLiveSearch
      value={profileId}
      onChange={updateProfileId}
      disabled={disabled}
      fetchOptions={fetchProfiles}
      fetchDependencies={[baseId, initialProfile]}
      size={size}
    />
  )
}

const shouldAddInitialProfile = (
  profileId: string,
  initialProfileId: string,
  initialProfile: ProfilesTypes | undefined,
  data: Partial<ProfilesTypes>[],
) => {
  if (!initialProfileId || !initialProfile) {
    return false
  }

  if (profileId !== initialProfileId) {
    return false
  }

  const dataIncludesInitialProfileId = data.some(
    (profile) => profile.profile_id === initialProfileId,
  )

  return !dataIncludesInitialProfileId
}
