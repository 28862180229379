import { debounce } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import {
  hasSomeValue,
  useHandleUrlParams,
  validateBrowseSearch,
} from '../../../../utils-hooks'
import { useLocalStorageProfileSearchState } from './useLocalStorageProfileSearchState'
import {
  AdvancedSearchParams,
  EmptyAdvancedSearchParams as emptyAdvancedSearchParams,
} from './utils'

export const useSearchProfilesLogic = () => {
  const {
    basicSearch,
    debouncedBasicSearch,
    setBasicSearch,
    resetBasicSearch,
  } = useBasicSearchState()

  const {
    advancedSearchParams,
    debouncedAdvancedSearchParams,
    setAdvancedSearchParam,
    resetAdvancedSearchParams,
  } = useAdvancedSearchParamsState()

  const {
    isLocalStorageAdvancedProfileSearch,
    setIsLocalStorageAdvancedProfileSearch,
  } = useLocalStorageProfileSearchState()

  const [isAdvancedSearch, innerSetIsAdvancedSearch] = useState(
    getIsAdvancedSearch(
      basicSearch,
      advancedSearchParams,
      isLocalStorageAdvancedProfileSearch,
    ),
  )

  const setIsAdvancedSearch = (value: boolean) => {
    innerSetIsAdvancedSearch(value)

    setIsLocalStorageAdvancedProfileSearch(value)

    if (value) {
      resetBasicSearch()
    } else {
      resetAdvancedSearchParams()
    }
  }

  useEffect(() => {
    if (basicSearch && hasSomeValue(advancedSearchParams)) {
      resetBasicSearch()
    }
  }, [basicSearch, advancedSearchParams, resetBasicSearch])

  return {
    basicSearch: isAdvancedSearch ? '' : basicSearch,
    debouncedBasicSearch: isAdvancedSearch ? '' : debouncedBasicSearch,
    setBasicSearch,
    advancedSearchParams: isAdvancedSearch
      ? advancedSearchParams
      : emptyAdvancedSearchParams,
    debouncedAdvancedSearchParams: isAdvancedSearch
      ? debouncedAdvancedSearchParams
      : emptyAdvancedSearchParams,
    setAdvancedSearchParam,
    isAdvancedSearch,
    setIsAdvancedSearch,
  }
}

const getIsAdvancedSearch = (
  basicSearch: string | undefined,
  advancedSearchParams: AdvancedSearchParams,
  isLocalStorageProfileSearchAdvanced: boolean,
) => {
  if (hasSomeValue(advancedSearchParams)) {
    return true
  }

  if (basicSearch) {
    return false
  }

  return isLocalStorageProfileSearchAdvanced
}

const useBasicSearchState = () => {
  const { currentParams, updateParams, cleanParams } = useHandleUrlParams()

  const [basicSearch, innerSetBasicSearch] = useState(
    currentParams.search || '',
  )

  const [debouncedBasicSearch, innerSetDebouncedBasicSearch] =
    useState(basicSearch)

  const setDebouncedBasicSearch = useRef(
    debounce(innerSetDebouncedBasicSearch, 500),
  )

  const setBasicSearch = (value: string) => {
    const { isValidSearch, validatedSearch } = validateBrowseSearch(value)

    innerSetBasicSearch(value)

    updateParams({ search: value })

    if (!isValidSearch) {
      return
    }

    setDebouncedBasicSearch.current.cancel()

    setDebouncedBasicSearch.current(validatedSearch)
  }

  const resetBasicSearch = () => {
    innerSetBasicSearch('')

    setDebouncedBasicSearch.current.cancel()

    innerSetDebouncedBasicSearch('')

    cleanParams(['search'])
  }

  return {
    basicSearch,
    debouncedBasicSearch,
    setBasicSearch,
    resetBasicSearch,
  }
}

const useAdvancedSearchParamsState = () => {
  const { currentParams, updateParams, cleanParams } = useHandleUrlParams()

  const [advancedSearchParams, innerSetAdvancedSearchParams] =
    useState<AdvancedSearchParams>({
      'first-name': currentParams['first-name'] || '',
      'last-name': currentParams['last-name'] || '',
      'employee-number': currentParams['employee-number'] || '',
      email: currentParams.email || '',
    })

  const [debouncedAdvancedSearchParams, innerSetDebouncedAdvancedSearchParams] =
    useState(advancedSearchParams)

  const setDebouncedAdvancedSearchParams = useRef(
    debounce(innerSetDebouncedAdvancedSearchParams, 500),
  )

  const setAdvancedSearchParam = (
    key: keyof AdvancedSearchParams,
    value: string,
  ) => {
    innerSetAdvancedSearchParams((previous) => {
      updateParams({ [key]: value })

      const update = { ...previous, [key]: value }

      setDebouncedAdvancedSearchParams.current.cancel()

      setDebouncedAdvancedSearchParams.current(update)

      return update
    })
  }

  const resetAdvancedSearchParams = () => {
    innerSetAdvancedSearchParams(emptyAdvancedSearchParams)

    setDebouncedAdvancedSearchParams.current.cancel()

    innerSetDebouncedAdvancedSearchParams(emptyAdvancedSearchParams)

    cleanParams(['first-name', 'last-name', 'employee-number', 'email'])
  }

  return {
    advancedSearchParams,
    debouncedAdvancedSearchParams,
    setAdvancedSearchParam,
    resetAdvancedSearchParams,
  }
}
