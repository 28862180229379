import { EntityTypes } from '@swiftctrl/api-client'
import {
  Layout,
  SisternodeOutlined,
  Text,
  Typography,
} from '@swiftctrl/swift-component-library'
import styled from 'styled-components'
import { EntityTypeTag } from '../../../components'
import { ReadScreenLink } from '../../../components/navigation'

type EntityTreeNodeDisplayProps = {
  entity: EntityTypes
  numChildren: number
}

export const EntityTreeNodeDisplay = ({
  entity,
  numChildren,
}: EntityTreeNodeDisplayProps) => {
  const { entity_type_id, entity_id, entity_name } = entity

  return (
    <StyledLayout horizontal alignItems="center" gap="small">
      <EntityTypeTag entityType={entity.entity_type_id} />
      <Typography.Text code={!entity_name}>
        {entity_name || entity_id}
      </Typography.Text>
      {numChildren > 0 && (
        <Text size="14px">
          {numChildren}
          <SisternodeOutlined />
        </Text>
      )}
      <StyledReadScreenLink entityType={entity_type_id} entityId={entity_id} />
    </StyledLayout>
  )
}

const StyledLayout = styled(Layout)`
  .ant-tag {
    margin-right: 0;
  }
`

const StyledReadScreenLink = styled(ReadScreenLink)`
  display: none;

  ${StyledLayout}:hover & {
    display: block;
  }
`
