import { Alert, PreLoader } from '@swiftctrl/swift-component-library'
import { ReactNode } from 'react'
import styled from 'styled-components'
import { useBrowseEntitiesContext } from '../../states'
import { FieldKey, getErrorMessage } from '../../utils-hooks'
import { EntitiesBrowserTopBar } from './EntitiesBrowserTopBar'

export interface EntitiesBrowserStyles {
  background?: boolean
  nested?: boolean
}

type EntitiesBrowserProps<T> = {
  render: (dataSource: T[], dataKey: FieldKey<T>) => ReactNode
} & EntitiesBrowserStyles

export const EntitiesBrowser = <T,>({
  render,
  background = true,
  nested,
}: EntitiesBrowserProps<T>) => {
  const { error, isLoading, dataSource, dataKey, topBar, queryDisabledView } =
    useBrowseEntitiesContext<T>()

  return (
    <Container>
      {topBar || (
        <EntitiesBrowserTopBar nested={nested} background={background} />
      )}
      {error ? (
        <Alert
          type="error"
          message={String(error)}
          description={getErrorMessage(error)}
        />
      ) : isLoading ? (
        <PreLoader centered />
      ) : (
        queryDisabledView || <>{render(dataSource!, dataKey as FieldKey<T>)}</>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding-bottom: 1em;
`
