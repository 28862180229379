import {
  FileSearchOutlined,
  PreviewOutlined,
  Spin,
  Tooltip,
} from '@swiftctrl/swift-component-library'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ENTITIES_SCREEN_PATH } from '../../config/paths'
import { EntityType } from '../../data/models'
import {
  buildReadEntityScreenPath,
  hasReadEntityScreenPath,
} from '../../utils-hooks'

type ReadScreenLinkProps = {
  entityType: EntityType
  entityId: string
}

export const ReadScreenLink = ({
  entityType,
  entityId,
  ...props
}: ReadScreenLinkProps) => {
  const hasDetailsScreen = hasReadEntityScreenPath(entityType)

  const [path, setPath] = useState('')

  useEffect(() => {
    if (hasDetailsScreen) {
      buildReadEntityScreenPath(entityId, entityType).then(setPath)
    } else {
      setPath(`/${ENTITIES_SCREEN_PATH}/${entityId}`)
    }
  }, [entityId, entityType, hasDetailsScreen])

  return (
    <Tooltip title="View details" {...props}>
      {!path ? (
        <Spin />
      ) : (
        <Link to={path} target="_blank">
          {hasDetailsScreen ? <FileSearchOutlined /> : <PreviewOutlined />}
        </Link>
      )}
    </Tooltip>
  )
}
