import { EntityTypes } from '@swiftctrl/api-client'
import { FetchQueryOptions, useQuery } from '@tanstack/react-query'
import { queryClient } from '../../data/queryClient'
import {
  swiftClient,
  swiftClientWithoutErrorHandling,
} from '../../data/swiftClient'
import { adaptSDKReadRequest } from '../adaptSDKReadRequest'
import { cacheKeys } from '../cacheKeysUtils'

export const useReadEntity = (entityId: string, enabled = true) =>
  useQuery({
    ...buildOptions(entityId, true),
    enabled,
  })

const buildOptions = (
  entityId: string,
  showErrorNotification: boolean,
): FetchQueryOptions<EntityTypes> => {
  const client = showErrorNotification
    ? swiftClient
    : swiftClientWithoutErrorHandling

  return {
    queryKey: [cacheKeys.entity, entityId],
    queryFn: adaptSDKReadRequest(client.entity, entityId),
  }
}

export const readEntity = (entityId: string) =>
  queryClient.fetchQuery(buildOptions(entityId, false))
