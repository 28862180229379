import { EntityBrowseTypes, EntityTypes } from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { useState } from 'react'
import { SWIFT_ROOT_ENTITY_ID } from '../../data/models'
import { swiftClient } from '../../data/swiftClient'
import {
  alphabetizeEntities,
  buildEntityTypeFilter,
  mapDataToSelectOptions,
  useReadEntity,
} from '../../utils-hooks'
import { BaseLiveSearch } from './BaseLiveSearch'

type OrganizationSelectProps = {
  baseId?: string
  disabled?: boolean
  initialValue?: string
  onChange: (organizationId: string) => void
  placeholder?: string
  size?: SizeType
}

export const OrganizationSelect = ({
  baseId = SWIFT_ROOT_ENTITY_ID,
  disabled,
  initialValue: propsInitialValue = '',
  onChange,
  placeholder,
  size,
  ...props
}: OrganizationSelectProps) => {
  /**
   * This unifies the different usages of this component:
   *   - `propsInitialValue` is when an initial value is manually passed in.
   *   - `(props as any).value` is when the component's Form has `initialValues` passed in.
   *     The type of `props` here is `{}`, because we can't anticipate how the component will be used.
   *     That's why we have to cast it to `any` for it to compile.
   */
  const initialValue = propsInitialValue || (props as any).value

  const [entityId, setEntityId] = useState<string>(initialValue)

  const { data: rootData } = useReadEntity(SWIFT_ROOT_ENTITY_ID)

  const updateEntityId = (entityId: string) => {
    setEntityId(entityId)

    onChange(entityId)
  }

  const { data: initialOrganization } = useReadEntity(
    initialValue,
    Boolean(initialValue),
  )

  const fetchOrganizations = async (search: string) => {
    const filters = [buildEntityTypeFilter('organization')]

    if (!rootData) {
      return []
    }

    if (initialValue && !initialOrganization) {
      return []
    }

    const trimmedSearch = search.trim()

    if (trimmedSearch) {
      const q = getQueryHandler<EntityBrowseTypes>()

      filters.push(q('AND', 'entity_name', 'EQ_IC', trimmedSearch))
    }

    const response = await swiftClient.entity.browse({
      baseId,
      select: ['entity_id', 'entity_name'],
      filters,
      sort: 'entity_name:asc',
    })

    const entitiesData = [...response.data]

    if (rootData.entity_name.includes(trimmedSearch)) {
      entitiesData.push(rootData)
    }

    if (shouldAddInitialOrganization(entitiesData, initialOrganization)) {
      entitiesData.push(initialOrganization!)
    }

    entitiesData.sort(alphabetizeEntities)

    return mapDataToSelectOptions(entitiesData, 'entity_id', 'entity_name')
  }

  return (
    <BaseLiveSearch
      value={entityId}
      onChange={updateEntityId}
      disabled={disabled}
      fetchOptions={fetchOrganizations}
      fetchDependencies={[baseId, rootData]}
      placeholder={placeholder}
      size={size}
    />
  )
}

const shouldAddInitialOrganization = (
  entities: Partial<EntityTypes>[],
  initialOrganization: EntityTypes | undefined,
) => {
  if (!initialOrganization) {
    return false
  }

  const includesInitialOrganization = entities.some(
    (entity) => entity.entity_id === initialOrganization.entity_id,
  )

  return !includesInitialOrganization
}
