import { useState } from 'react'

const KEY = 'isAdvancedProfileSearch'

const getState = () => {
  const value = localStorage.getItem(KEY)

  if (!value) {
    return false
  }

  if (!['0', '1'].includes(value)) {
    return false
  }

  return value === '1'
}

const setState = (isAdvanced: boolean) => {
  localStorage.setItem(KEY, isAdvanced ? '1' : '0')
}

export const useLocalStorageProfileSearchState = () => {
  const [isLocalStorageAdvancedProfileSearch, innerSet] = useState(getState())

  const handleChange = (value: boolean) => {
    setState(value)

    innerSet(value)
  }

  return {
    isLocalStorageAdvancedProfileSearch,
    setIsLocalStorageAdvancedProfileSearch: handleChange,
  }
}
