import { KeyboardEvent } from 'react'
import { ENTITIES_SCREEN_PATH } from '../../../config/paths'
import { EntityType } from '../../../data/models'
import {
  buildReadEntityScreenPath,
  hasReadEntityScreenPath,
  isValidUuid,
  readCredentialTemplate,
  readEntity,
  useBoolean,
} from '../../../utils-hooks'

type Params = {
  onSearchNotValidUuid: () => void
  onEntityNotFound: () => void
  onError: (error: Error) => void
  onEntityScreenOpenedInNewTab: () => void
}

/**
 * Ordered list of endpoints to hit when trying to determine the
 * type for the entity with the given ID.
 *
 * The generic `readEntity` request is first because it's most
 * likely that the entity is an actual entity in Core.
 *
 * If that one doesn't work, we run through the other endpoints.
 */
const entityTypeDeterminers: ((id: string) => Promise<EntityType>)[] = [
  async (id) => {
    const entity = await readEntity(id)

    return entity.entity_type_id
  },
  async (id) => {
    await readCredentialTemplate(id)

    return 'credential_template'
  },
]

export const useEntitySearchLogic = ({
  onSearchNotValidUuid,
  onEntityNotFound,
  onError,
  onEntityScreenOpenedInNewTab,
}: Params) => {
  const [isSearching, setSearchingTrue, setSearchingFalse] = useBoolean()

  const search = async (event: KeyboardEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget

    const trimmedValue = value.trim()

    if (!isValidUuid(trimmedValue)) {
      onSearchNotValidUuid()

      return
    }

    setSearchingTrue()

    for (let i = 0; i < entityTypeDeterminers.length; i++) {
      const entityTypeDeterminer = entityTypeDeterminers[i]

      try {
        const entityType = await entityTypeDeterminer(trimmedValue)

        const url = new URL(window.location.href)

        url.pathname = await buildPath(entityType, trimmedValue)

        url.search = ''

        setSearchingFalse()

        onEntityScreenOpenedInNewTab()

        window.open(url, '_blank')

        return
      } catch (error: any) {
        if (error?.response?.status === 404) {
          continue
        }

        onError(error)

        setSearchingFalse()

        return
      }
    }

    setSearchingFalse()

    onEntityNotFound()
  }

  return { search, isSearching }
}

const buildPath = async (entityType: EntityType, entityId: string) => {
  const hasDetailsScreen = hasReadEntityScreenPath(entityType)

  if (!hasDetailsScreen) {
    const path = `/${ENTITIES_SCREEN_PATH}/${entityId}`

    return path
  }

  const path = await buildReadEntityScreenPath(entityId, entityType)

  return path
}
