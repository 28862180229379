import { SinkRule } from '@swiftctrl/api-client'
import { Layout, Tooltip, Typography } from '@swiftctrl/swift-component-library'
import { RefObject, useEffect, useRef, useState } from 'react'
import { styled } from 'styled-components'
import { CopyableDisplay, Status } from '../../components'

interface RulesPanelHeaderProps {
  rule: SinkRule
}

export const RulesPanelHeader = ({ rule }: RulesPanelHeaderProps) => {
  const {
    rule_name,
    rule_description,
    rule_id,
    stream_id,
    rule_order,
    on_add,
    on_delete,
    on_edit,
    preprocess,
  } = rule

  const titleRef = useRef<HTMLElement>(null)

  const [titleOverflow, setTitleOverflow] = useState(false)

  const checkOverflow = (ref: RefObject<HTMLElement>) => {
    const textElement = ref.current
    if (textElement) {
      return textElement.scrollWidth > textElement.clientWidth
    }
    return false
  }

  useEffect(() => {
    setTitleOverflow(checkOverflow(titleRef))
  }, [titleRef])

  return (
    <HeaderContainer grow>
      <Layout grow horizontal>
        <TitleContainer>
          <Tooltip title={titleOverflow ? rule_name : ''}>
            <Typography.Title level={5} ellipsis ref={titleRef}>
              {rule_name}
            </Typography.Title>
          </Tooltip>
        </TitleContainer>
        <Layout
          alignItems="center"
          justifyContent="space-between"
          grow
          horizontal
        >
          <CopyableDisplay label="Rule ID" copyableText={rule_id} />

          <Typography.Paragraph>
            Stream ID:
            <Typography.Text code>{stream_id}</Typography.Text>
          </Typography.Paragraph>

          <Typography.Paragraph>Rule order: {rule_order}</Typography.Paragraph>
          <Status status={getStatus(preprocess)}>Preprocess</Status>
          <Status status={getStatus(on_add)}>On add</Status>
          <Status status={getStatus(on_edit)}>On edit</Status>
          <Status status={getStatus(on_delete)}>On delete</Status>
        </Layout>
      </Layout>
      {rule_description && (
        <StyledText type="secondary">{rule_description}</StyledText>
      )}
    </HeaderContainer>
  )
}

const getStatus = (status: boolean) => (status ? 'Completed' : 'Required')

const HeaderContainer = styled(Layout)`
  .ant-typography {
    margin-bottom: 0px;
  }
`

const TitleContainer = styled.div`
  width: 400px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledText = styled(Typography.Text)`
  word-break: break-all;
`
