import { Button, Layout } from '@swiftctrl/swift-component-library'
import { Link } from 'react-router-dom'
import { ORGANIZATIONS_SCREEN_PATH } from '../../config/paths'
import { EntityCard } from '../browse-entities'
import { CopyableDisplay } from '../data-display'
import { FavoriteOrganizationButton } from './FavoriteOrganizationButton'
import { FavoriteOrganization } from './utils'

type FavoriteOrganizationCardProps = {
  organization: FavoriteOrganization
}

export const FavoriteOrganizationCard = ({
  organization,
}: FavoriteOrganizationCardProps) => {
  return (
    <EntityCard.StyledCard>
      <Layout justifyContent="space-between" grow>
        <Layout horizontal justifyContent="space-between">
          <CopyableDisplay
            label={organization.entity_name}
            copyableText={organization.organization_id}
            title
          />
          <FavoriteOrganizationButton organization={organization} />
        </Layout>
        <Link
          to={`/${ORGANIZATIONS_SCREEN_PATH}/${organization.organization_id}`}
        >
          <Button type="primary" ghost size="middle">
            Details
          </Button>
        </Link>
      </Layout>
    </EntityCard.StyledCard>
  )
}
