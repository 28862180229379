import { Input, notification } from '@swiftctrl/swift-component-library'
import { InputRef } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { styled } from 'styled-components'
import { AntColors } from '../../../theme'
import { getErrorMessage, getPlatform, Platform } from '../../../utils-hooks'
import { useEntitySearchLogic } from './useEntitySearchLogic'

const BACKGROUND_COLOR = '#120338'

const BORDER_COLOR = AntColors['gray-7']

const TEXT_COLOR = '#fff'

const PLACEHOLDER_COLOR = '#BFBFBF'

const DISABLED_BACKGROUND_COLOR = '#8c8c8c26'

export const EntitySearchInput = () => {
  const platform = getPlatform()

  switch (platform) {
    case Platform.Windows:
    case Platform.Linux:
      return (
        <Content
          hotKeyLabel="Ctrl+K"
          hotKeyPredicate={(event) => event.ctrlKey && event.key === 'k'}
        />
      )

    case Platform.Mac:
    default:
      return (
        <Content
          hotKeyLabel="⌘K"
          hotKeyPredicate={(event) => event.metaKey && event.key === 'k'}
        />
      )
  }
}

const Content = ({
  hotKeyLabel,
  hotKeyPredicate,
}: {
  hotKeyLabel: string
  hotKeyPredicate: (event: KeyboardEvent) => boolean
}) => {
  const ref = useRef<InputRef>(null)

  const [value, setValue] = useState('')

  useEffect(() => {
    const checkHotKey = (event: KeyboardEvent) => {
      if (hotKeyPredicate(event)) {
        ref.current?.focus({ cursor: 'all' })
      }
    }

    document.addEventListener('keydown', checkHotKey)

    return () => document.removeEventListener('keydown', checkHotKey)
  }, [hotKeyPredicate])

  const { search, isSearching } = useEntitySearchLogic({
    onSearchNotValidUuid: () =>
      showWarningNotification('Entered text is not a UUID'),
    onEntityNotFound: () => showWarningNotification('No entity with that ID'),
    onError: (error) => showWarningNotification(getErrorMessage(error)),
    onEntityScreenOpenedInNewTab: () => setValue(''),
  })

  return (
    <StyledInput
      placeholder="Enter an Entity ID"
      suffix={hotKeyLabel}
      size="middle"
      ref={ref}
      onPressEnter={search}
      disabled={isSearching}
      value={value}
      onChange={(event) => setValue(event.target.value)}
    />
  )
}

const StyledInput = styled(Input)`
  width: 400px;

  border-color: ${BORDER_COLOR};

  --ant-primary-5: ${BORDER_COLOR};

  background-color: ${BACKGROUND_COLOR};

  .ant-input-suffix {
    color: ${PLACEHOLDER_COLOR};
  }

  .ant-input {
    color: ${TEXT_COLOR};

    background-color: unset;

    &::placeholder {
      color: ${PLACEHOLDER_COLOR};
    }

    &[disabled] {
      color: ${AntColors['gray-7']};
    }
  }

  &.ant-input-affix-wrapper-disabled {
    background-color: ${DISABLED_BACKGROUND_COLOR};
  }
`

const showWarningNotification = (message: string) =>
  notification.warning({
    message,
    placement: 'top',
    top: 56,
  })
