import {
  ProviderBrowseTypes,
  ProviderTypes,
  providerTypesToProviderTypeIds,
} from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { PROVIDER_TYPES_SUPPORTING_PROVIDER_PROFILES } from '../../../../data/models'
import { swiftClient } from '../../../../data/swiftClient'
import { isValidUuid, mapDataToSelectOptions } from '../../../../utils-hooks'
import { BaseLiveSearch } from '../../../add-entities'

type OrganizationProviderSelectProps = {
  organizationId: string
  value?: ProviderTypes
  disabled?: boolean
  onChange?: (value: ProviderTypes | undefined) => void
}

const validProviderTypes = PROVIDER_TYPES_SUPPORTING_PROVIDER_PROFILES.map(
  (providerType) => providerTypesToProviderTypeIds[providerType],
)

export const OrganizationProviderSelect = ({
  organizationId,
  value,
  disabled,
  onChange,
}: OrganizationProviderSelectProps) => {
  const fetchProviders = async (search: string) => {
    const q = getQueryHandler<ProviderBrowseTypes>()

    const filters = [q('WHERE', 'provider_type_id', 'ANY', validProviderTypes)]

    const trimmedSearch = search.trim()

    if (trimmedSearch) {
      if (isValidUuid(trimmedSearch)) {
        filters.push(
          q('AND', 'provider_id', 'EQ', trimmedSearch),
          q('OR', 'overseer_id', 'EQ', trimmedSearch),
        )
      } else {
        filters.push(
          q('AND', 'entity_name', 'EQ_IC', trimmedSearch),
          q('OR', 'entity_description', 'EQ_IC', trimmedSearch),
        )
      }
    }

    const response = await swiftClient.provider.browse({
      baseId: organizationId,
      select: ['provider_id', 'provider_type', 'entity_name'],
      sort: 'entity_name:asc',
      filters,
    })

    const providers = [...response.data]

    return mapDataToSelectOptions(providers, 'provider_id', 'entity_name', true)
  }

  return (
    <BaseLiveSearch
      value={value?.provider_id || ''}
      onChange={(_, option) => {
        if (!onChange) {
          return
        }

        if (Array.isArray(option)) {
          // Not a multi-select
          return
        }

        onChange(option?.data)
      }}
      disabled={disabled}
      fetchOptions={fetchProviders}
      fetchDependencies={[organizationId]}
      size="large"
    />
  )
}
