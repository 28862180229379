import { gold, grey } from '@ant-design/colors'
import { OrganizationTypes } from '@swiftctrl/api-client'
import {
  StarFilled,
  StarOutlined,
  Tooltip,
} from '@swiftctrl/swift-component-library'
import { ReactNode, useState } from 'react'
import { styled } from 'styled-components'
import {
  addFavoriteOrganization,
  isFavoriteOrganization,
  removeFavoriteOrganization,
} from './utils'

type FavoriteOrganizationButtonProps = {
  organization: Partial<OrganizationTypes>
}

export const FavoriteOrganizationButton = ({
  organization,
  ...props
}: FavoriteOrganizationButtonProps) => {
  const { organization_id, entity_name } = organization

  const [isFavorite, setIsFavorite] = useState(
    isFavoriteOrganization(organization_id!),
  )

  const handleAddClick = () => {
    addFavoriteOrganization({
      organization_id: organization_id!,
      entity_name: entity_name!,
    })

    setIsFavorite(true)
  }

  const handleRemoveClick = () => {
    removeFavoriteOrganization(organization_id!)

    setIsFavorite(false)
  }

  if (isFavorite) {
    return (
      <Content
        icon={<StyledFilledIcon />}
        tooltipTitle="Remove from favorites"
        onClick={handleRemoveClick}
        {...props}
      />
    )
  }

  return (
    <Content
      icon={<StyledOutlinedIcon />}
      tooltipTitle="Add to favorites"
      onClick={handleAddClick}
      {...props}
    />
  )
}

const Content = ({
  icon,
  tooltipTitle,
  onClick,
  ...props
}: {
  icon: ReactNode
  tooltipTitle: string
  onClick: () => void
}) => {
  return (
    <Tooltip title={tooltipTitle} placement="topRight" arrowPointAtCenter>
      <StyledButton onClick={onClick} {...props}>
        {icon}
      </StyledButton>
    </Tooltip>
  )
}

const StyledButton = styled.button`
  padding: 0px;
  border: none;
  background: none;
`

const StyledFilledIcon = styled(StarFilled)`
  font-size: ${({ theme }) => theme.icon.sizes.medium};

  color: ${gold[3]};
`

const StyledOutlinedIcon = styled(StarOutlined)`
  font-size: ${({ theme }) => theme.icon.sizes.medium};

  color: ${grey[1]};
`
