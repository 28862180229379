import { Button, Form, Layout } from '@swiftctrl/swift-component-library'
import { SWIFT_ROOT_ENTITY_ID } from '../../data/models'
import { FieldConfig } from '../../utils-hooks'
import { FieldInput } from '../add-entities'

type EditEntityFormProps<FormData> = {
  values: FormData
  setValues: (values: FormData) => void
  fields: FieldConfig<FormData>[]
  fieldsAreValid: boolean
  save: () => void
  isSaving: boolean
  isDirty: boolean
  checkBeforeClose: () => void
}

export const EditEntityForm = <FormData,>({
  values: valuesProp,
  setValues,
  fields,
  fieldsAreValid,
  save,
  isSaving,
  isDirty,
  checkBeforeClose,
}: EditEntityFormProps<FormData>) => {
  const [form] = Form.useForm<FormData>()

  const handleFormChange = (key: keyof FormData, value: any) => {
    const update = { ...valuesProp, [key]: value }

    setValues(update)
  }

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={valuesProp!}
      preserve={false}
      onFinish={save}
      disabled={isSaving}
    >
      {fields.map((field) => {
        const { entitySelectBaseIdKey, key, editable = true } = field

        const selectBaseId = entitySelectBaseIdKey
          ? String(valuesProp[entitySelectBaseIdKey])
          : SWIFT_ROOT_ENTITY_ID

        const handleFieldChange = (value: any) => handleFormChange(key, value)

        return (
          <FieldInput
            key={String(key)}
            field={field}
            onChange={handleFieldChange}
            selectBaseId={selectBaseId}
            size="large"
            disabled={!editable}
            operation="edit"
          />
        )
      })}
      <Layout horizontal gap="small" justifyContent="end">
        <Form.Item>
          <Button type="text" onClick={checkBeforeClose}>
            Cancel
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isSaving}
            disabled={!isDirty || !fieldsAreValid}
          >
            Save
          </Button>
        </Form.Item>
      </Layout>
    </Form>
  )
}
