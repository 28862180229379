import { EpiSubtypes, EpiSubtypesBrowseTypes } from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { SelectProps } from '@swiftctrl/swift-component-library'
import { useState } from 'react'
import { swiftClient } from '../../data/swiftClient'
import {
  buildEpiSubtypeLabel,
  isValidUuid,
  mapDataToSelectOptions,
} from '../../utils-hooks'
import { BaseLiveSearch } from './BaseLiveSearch'

type EpiSubtypeSelectProps = {
  value?: string
  disabled?: boolean
  onChange: (epiSubtypeId: string) => void
  size?: SelectProps['size']
}

export const EpiSubtypeSelect = ({
  value,
  disabled,
  onChange,
  size,
}: EpiSubtypeSelectProps) => {
  const [epiSubtypeId, setEpiSubtypeId] = useState<string>(value || '')

  const updateEpiSubtypeId = (value: string) => {
    setEpiSubtypeId(value)

    onChange(value)
  }

  return (
    <BaseLiveSearch
      value={epiSubtypeId}
      onChange={updateEpiSubtypeId}
      disabled={disabled}
      fetchOptions={fetchEpiSubtypes}
      fetchDependencies={[]}
      size={size}
    />
  )
}

const fetchEpiSubtypes = async (search: string) => {
  const q = getQueryHandler<EpiSubtypesBrowseTypes>()

  const trimmedSearch = search.trim()

  const { data } = await swiftClient.epiSubtypes.browse({
    select: ['epi_subtype_id', 'vendor', 'product_name'],
    filters: isValidUuid(trimmedSearch)
      ? [q('WHERE', 'epi_subtype_id', 'EQ', trimmedSearch)]
      : [
          q('WHERE', 'vendor', 'EQ_IC', trimmedSearch),
          q('OR', 'product_name', 'EQ_IC', trimmedSearch),
        ],
  })

  return mapDataToSelectOptions(
    data as EpiSubtypes[],
    'epi_subtype_id',
    buildEpiSubtypeLabel,
  )
}
