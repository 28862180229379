import {
  ProviderType,
  ProviderTypeId,
  providerTypeIdsToProviderTypes,
  ProviderTypes,
} from '@swiftctrl/api-client'
import {
  Button,
  Layout,
  ReloadOutlined,
  Typography,
} from '@swiftctrl/swift-component-library'
import { providerFieldLabels } from '../../../../config/labels'
import { PROVIDERS_SCREEN_PATH } from '../../../../config/paths'
import { useBoolean, useBrowseProviderProfile } from '../../../../utils-hooks'
import { EntityCard } from '../../../browse-entities'
import { LabelDisplay } from '../../../data-display'

type ProviderCardProps = {
  provider: ProviderTypes
}

export const ProviderCard = ({ provider }: ProviderCardProps) => {
  const { provider_id } = provider

  const [trigger, enableTrigger] = useBoolean()

  const {
    data,
    refetch: refetchProvider,
    isFetching,
    isSuccess,
  } = useBrowseProviderProfile({
    providerId: provider.provider_id,
    enabled: trigger,
    limit: 1,
    totalCountInHeader: true,
  })

  const totalCount = data?.headers?.['x-total-count'] || 0

  const path = `/${PROVIDERS_SCREEN_PATH}/${provider_id}`
  return (
    <EntityCard entity={provider} detailsNavigatePath={path}>
      <Typography.Paragraph>
        <Layout
          paddingBottom="xSmall"
          paddingTop="xSmall"
          horizontal
          gap="xSmall"
        >
          <LabelDisplay label="Provider type" />
          <Layout horizontal grow>
            {getProviderTypeLabel(provider.provider_type)}
          </Layout>
        </Layout>
        <Layout alignItems="center" gap="xSmall" horizontal>
          <LabelDisplay label="Provider profile count" />
          {trigger && isSuccess ? (
            <>
              <Typography>{totalCount}</Typography>
              <Button
                size="small"
                icon={<ReloadOutlined />}
                type="primary"
                onClick={() => refetchProvider()}
                loading={isFetching}
              />
            </>
          ) : (
            <Button
              loading={isFetching}
              size="small"
              type="primary"
              onClick={enableTrigger}
            >
              load count
            </Button>
          )}
        </Layout>
      </Typography.Paragraph>
    </EntityCard>
  )
}

const getProviderTypeLabel = (providerType: ProviderTypes['provider_type']) => {
  const { name, provider_type_id } = providerType

  if (name) {
    return providerFieldLabels.providerTypeLabels[name]
  }

  const derivedProviderType = providerTypeIdsToProviderTypes[
    provider_type_id as ProviderTypeId
  ] as ProviderType

  return providerFieldLabels.providerTypeLabels[derivedProviderType]
}
