import { Empty, Layout, StarOutlined } from '@swiftctrl/swift-component-library'
import { useState } from 'react'
import { useBrowseEntitiesContext } from '../../states'
import { isValidUuid } from '../../utils-hooks'
import { EntitiesCardsBrowser } from '../browse-entities'
import { FavoriteOrganizationCard } from './FavoriteOrganizationCard'
import { FavoriteOrganization, getFavoriteOrganizations } from './utils'

export const FavoriteOrganizationsBrowser = () => {
  const [favoriteOrganizations] = useState(() => {
    const favoriteOrganizations = getFavoriteOrganizations()

    favoriteOrganizations.sort((a, b) =>
      a.entity_name.localeCompare(b.entity_name),
    )

    return favoriteOrganizations
  })

  const { search } = useBrowseEntitiesContext()

  const results = applySearch(favoriteOrganizations, search)

  if (results.length === 0) {
    return (
      <Layout grow>
        <Empty
          description={
            search ? (
              'No favorites'
            ) : (
              <>
                <p>No favorites yet!</p>
                <p>
                  Click the star <StarOutlined /> to add organizations to your
                  favorites.
                </p>
                <p>
                  They will then appear here the next time you visit this page.
                </p>
              </>
            )
          }
        />
      </Layout>
    )
  }

  return (
    <EntitiesCardsBrowser.Row>
      {results.map((organization) => (
        <EntitiesCardsBrowser.Col key={organization.organization_id}>
          <FavoriteOrganizationCard organization={organization} />
        </EntitiesCardsBrowser.Col>
      ))}
    </EntitiesCardsBrowser.Row>
  )
}

const applySearch = (
  favoriteOrganizations: FavoriteOrganization[],
  search: string,
) => {
  if (!search) {
    return favoriteOrganizations
  }

  const lowercaseSearch = search.toLocaleLowerCase()

  const results = favoriteOrganizations.filter((entry) => {
    if (isValidUuid(lowercaseSearch)) {
      return entry.organization_id.toLocaleLowerCase() === lowercaseSearch
    }

    return entry.entity_name.toLocaleLowerCase().includes(lowercaseSearch)
  })

  return results
}
